import { Injectable,  OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription, Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { CoinService } from './coin.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PollService implements OnDestroy {
  statusMessage: string = '';
  private statusSubscription: Subscription | null = null;

  constructor(
    private globalService: GlobalService,
    private coinService: CoinService
    ) {}

  startChecking() {
    this.statusMessage = 'Checking status...';

    this.statusSubscription = interval(5000) // Poll every 5 seconds
      .pipe(
        switchMap(() => this.globalService.checkStatus())
      )
      .subscribe(response => {
        if (response.status === 'end') {
          this.statusMessage = 'Process completed.';
          this.coinService.loadPortfolioTaxInfoIncompleted();
          this.stopChecking();
        } else {
          console.log('RESPONCE', response.status);
          this.coinService.loadPortfolioTaxInfoIncompleted();
        }
      });
  }

  stopChecking() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
      this.statusSubscription = null;
    }
  }

  ngOnDestroy() {
    this.stopChecking();
  }
}
