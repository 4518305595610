
import { Injectable } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { CoinService } from '../coin.service';
import { finalize, share, tap } from 'rxjs/operators';

@Injectable()
export class QbService {

	private portfolioId = null;

	constructor(private global: GlobalService, private coinService: CoinService) {
		//this.subject = new Subject<any>();
		this.coinService.onChangePortfolio$.subscribe( res=>{
			if(res !== null && this.portfolioId !== res){
				this.portfolioId = res;
				this.coinService.setQbGlAccounts(null);
				this.coinService.setQbDepartments(null);
				this.coinService.setQbGlClasses(null)
			}
		})
	}

	qboClassBulkAssign(portfolioId, txIds, selectAll: any = false, filters, classId, className) {
		//add_bulk_qbo_class_map_tx
		let body = {
			portfolioId,
			txIds,
			selectAll,
			filters,
			classId,
			className
		};
		return this.global.httpPOST('add_bulk_qbo_class_map_tx', body);
	}

	qboDepartmentBulkAssign(portfolioId, txIds, selectAll: any = false, filters, departmentId, departmentName) {
		//add_bulk_qbo_class_map_tx
		let body = {
			portfolioId,
			txIds,
			selectAll,
			filters,
			departmentId,
			departmentName
		};
		return this.global.httpPOST('add_bulk_qbo_department_map_tx', body);
	}

	addTxAccountMap(portfolioId, txId, creditAccountId, debitAccountId, creditAmount, debitAmount, classId, className = null, departmentId, departmentName) {

		let body = {
			gatewayeId: 1,
			portfolioId,
			txId
		};

		if (creditAccountId !== null) {
			body['creditAccountId'] = creditAccountId;
		}

		if (debitAccountId !== null) {
			body['debitAccountId'] = debitAccountId;
			body['debitAmount'] = debitAmount;
		}
		if (creditAccountId !== null) {
			body['creditAccountId'] = creditAccountId;
			body['creditAmount'] = creditAmount;
		}

		if (classId !== null) {
			body['classId'] = classId;
		}

		if (className !== null) {
			body['className'] = className;
		}

		if (departmentId !== null) {
			body['departmentId'] = departmentId;
		}

		if (departmentName !== null) {
			body['departmentName'] = departmentName;
		}

		return this.global.httpPOST('add_tx_account_map', body);
	}

	private cachedAccountObservable: Observable<any>;
	private accountRes = null;

	getQbAccounts(portfolioId, connectionId): Observable<any> {

		// glAccount variable move to coin.service (globle)
		let observable: Observable<any>;
		this.accountRes = this.coinService.getQbGlAccounts();
		if (this.accountRes !== null) {
			observable = of(this.accountRes);
		} else if (this.cachedAccountObservable) {
			observable = this.cachedAccountObservable;
		} else {
			this.cachedAccountObservable = this.global.httpGET('qb_gl_accounts', {
				gatewayConnectionId: connectionId,
				portfolioId: portfolioId,
			})
				.pipe(
					tap(res => this.coinService.setQbGlAccounts(res)),
					share(),
					finalize(() => this.cachedAccountObservable = null)
				);

			observable = this.cachedAccountObservable;
		}


		return observable;
	}

	getTxAccountMap(portfolioId, txId) {
		return this.global.httpGET('get_tx_account_map', {
			gatewayeId: 1,
			portfolioId,
			txId
		});
	}

	private cachedDepObservable: Observable<any>;
	private departmentRes = null;
	public getQbDepartments(gatewayId): Observable<any> {

		let observable: Observable<any>;
		this.departmentRes = this.coinService.getQbDepartments();
		if (this.departmentRes !== null) {
			observable = of(this.departmentRes);
		} else if (this.cachedDepObservable) {
			observable = this.cachedDepObservable;
		} else {
			this.cachedDepObservable = this.global.httpGET('qb_gl_departments', { portfolioId: this.global.currentPortfolioId, gatewayId })
				.pipe(
					tap(res => this.coinService.setQbDepartments(res)),
					share(),
					finalize(() => this.cachedDepObservable = null)
				);

			observable = this.cachedDepObservable;
		}


		return observable;
	}

	private cachedClassObservable: Observable<any>;
	private requestWaitingTime = 10; // 5 seconds
	private timer;
	private isreqwait = false;
	public getQbClasses(portfolioId, connectionId): Observable<any> {

		let observable: Observable<any>;
		let qbClassses = this.coinService.getQbGlClasses();

		if (qbClassses !== null && qbClassses.data && qbClassses.data !== null && qbClassses.data.length > 0) {
			observable = of(qbClassses);
		} else if (this.cachedClassObservable) {
			observable = this.cachedClassObservable;
		} else if (this.isreqwait) {
			observable = of(qbClassses);
		} else {
			// to avoid multiple request simultainously happen
			this.isreqwait = true;

			this.timer = setTimeout(() => {
				this.isreqwait = false;
				clearTimeout(this.timer)
			}, this.requestWaitingTime * 1000);

			this.cachedClassObservable = this.global.httpGET('qb_gl_classes', {
				portfolioId,
				connectionId
			})
				.pipe(
					tap(res => this.coinService.setQbGlClasses(res)),
					share(),
					finalize(() => this.cachedClassObservable = null)
				);

			observable = this.cachedClassObservable;
		}


		return observable;

	}

	public resetQboCache(){
		this.coinService.setQbGlClasses(null);
		this.departmentRes = null;
		this.accountRes = null;
	}
}

