import { Injectable} from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { CoinService } from '../coin.service';

@Injectable()
export class OracleNetSuiteService {

    private code:string;
    private accountId:string;
    public users = [];

    constructor(public global: GlobalService, private coinService: CoinService){
    
    }

    //get_ons_departments
    //get_ons_classifications
    private cachedDepObservable: Observable<any>;
	private departmentRes = null;

    public getDepartments(portfolioId, page=1){
        
        let observable: Observable<any>;
        this.departmentRes = this.coinService.getOnsDepartments();
        if (this.departmentRes !== null) {
			observable = of(this.departmentRes);
		} else if (this.cachedDepObservable) {
			observable = this.cachedDepObservable;
		} else {
			this.cachedDepObservable = this.global.httpPOST('get_ons_departments',{
                'portfolio_id': portfolioId
            })
				.pipe(
					tap(res => this.coinService.setOnsDepartments(res)),
					share(),
					finalize(() => this.cachedDepObservable = null)
				);

			observable = this.cachedDepObservable;
		}


		return observable;
    }

    public getClassess(portfolioId, page=1){
        return this.global.httpPOST('get_ons_classifications',{
            'portfolio_id': portfolioId
        })
    }

    public getAccounts(gatewayConnectionId,portfolioId){
        return this.global.httpGET('get_ons_accounts',{
            gatewayConnectionId,
            portfolioId
        });
    }

    addTxAccountMap(portfolioId, txId, creditAccountId, debitAccountId, creditAmount, debitAmount, gainAccountId, lossAccountId, classId=null, className=null, departmentId=null,departmentName=null){

        let body ={
            gatewayeId:3,
            portfolioId,
            txId
        };

        if(creditAccountId !== null){
            body['creditAccountId'] = creditAccountId;
            body['creditAmount'] = creditAmount;
        }

        if(debitAccountId !== null){
            body['debitAccountId'] = debitAccountId;
            body['debitAmount'] = debitAmount;
        }

        if(gainAccountId !== null){
            body['gainAccountId'] = gainAccountId;
        }

        
        if(lossAccountId !== null){
            body['lossAccountId'] = lossAccountId;
        }

        if (classId !== null) {
			body['classId'] = classId;
		}

		if (className !== null) {
			body['className'] = className;
		}

		if (departmentId !== null) {
			body['departmentId'] = departmentId;
		}

		if (departmentName !== null) {
			body['departmentName'] = departmentName;
		}

        return this.global.httpPOST('add_tx_account_map',body);
    }

    getTxAccountMap(portfolioId, txId):Observable<any>{
        return this.global.httpGET('get_tx_account_map',{
            gatewayeId:3,
            portfolioId,
            txId
        });
    }

    public getFilterOnsAccounts(subsidaryId, accounts=[])
    :Array<{
        FullyQualifiedName:string
        Id: string
        SubSidiaries:Array<number>
    }>{
        let arr = [];
       
        
        if(subsidaryId !== null){
            let id = parseInt(subsidaryId);
            arr = accounts.filter( (item,index)=>{
                return item.SubSidiaries.indexOf(id) > -1
            });
        }
        return arr;
    }

    private cachedClassObservable: Observable<any>;
	private requestWaitingTime = 10; // 5 seconds
	private timer;
	private isreqwait = false;
	public getOnsClasses(portfolioId, page=1): Observable<any> {

		let observable: Observable<any>;
		let qbClassses = this.coinService.getOnsClasses();

		if (qbClassses !== null && qbClassses.data && qbClassses.data !== null && qbClassses.data.length > 0) {
			observable = of(qbClassses);
		} else if (this.cachedClassObservable) {
			observable = this.cachedClassObservable;
		} else if (this.isreqwait) {
			observable = of(qbClassses);
		} else {
			// to avoid multiple request simultainously happen
			this.isreqwait = true;

			this.timer = setTimeout(() => {
				this.isreqwait = false;
				clearTimeout(this.timer)
			}, this.requestWaitingTime * 1000);

			this.cachedClassObservable = this.global.httpPOST('get_ons_classifications', {
                'portfolio_id': portfolioId
			})
				.pipe(
					tap(res => this.coinService.setOnsClasses(res)),
					share(),
					finalize(() => this.cachedClassObservable = null)
				);

			observable = this.cachedClassObservable;
		}


		return observable;

	}

    

}