<div class="">
    <div class="row">
        <div class="col-12">
            <p text-center>
                <span> 
                    Sorry, this feature is only available in your current plan. Please contact the support team to upgrade your account.
                </span>
                <br/>
            </p>
        </div>
    </div>
</div>