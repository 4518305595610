import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCAPModule } from '../../_components_a_common/common.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { GlobalLoader } from '../_components_a_common/global-loader/global-loader.component';
import { ClassificationConnectionInfoComponent } from './classification-connection-info/classification-connection-info.component';

@NgModule({
  imports: [
    CommonModule,
    CommonCAPModule,
    NgbModule.forRoot(),
  ],
  declarations: [
    ClassificationConnectionInfoComponent,
  ],
  providers: [
  ],
  exports: [
    CommonModule,
    ClassificationConnectionInfoComponent,

  ]
})
export class ClassificationConnectionInfoModule { }
