
import { Component } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { ActivatedRoute, Params } from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})

export class ContactPageComponent {


	public contactDetails = {};
	public clickSubmit = false;
	public submitted = false;

	public modalRef;
	
	constructor(public global: GlobalService, public modalService: NgbModal, public route: ActivatedRoute) {

	}

	 openModal(modal){

		this.modalRef = this.modalService.open(modal, {size:'lg'});
	 }


	submitContactForm(contactForm) {
		this.clickSubmit = true;
		if (this.global.isFormValid(contactForm)) {
			this.global.http.post(this.global.API_URL + 'contactRequest', {
					contactDetails: this.contactDetails
				}
			)
			.subscribe(response => {
				this.submitted = true;
				this.contactDetails = {};
				setTimeout(() => {
					this.submitted = false;
				}, 10000);
				this.clickSubmit = false;
			}, error => {
				console.log(error); // Error getting the data
			});
		}
	}
}

