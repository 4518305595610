import { Component, EventEmitter, OnInit, Input, Output, ViewChild, SimpleChanges } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { Subject } from "rxjs";

@Component({
  selector: 'app-coin-search',
  templateUrl: './coin-search.component.html'
})
export class CoinSearchComponent implements OnInit {

	// escapes RegExp special characters
	public coinSearch 				= null
	public foundResults 		= []
	public foundResultsOnlyKey 	= []
	public coinSelected 		= null

	@ViewChild('searchComp') searchComp; 

	@Input('coin') selectedCoin = null; 
	@Input() type: any; // 1 = any, 2 = live
	@Input() showId = false;
	@Input() showTokenType = true;
	@Input() showSymbolOnly = false;
	@Input() allowSelect = true;
	@Input() clearOnSelect = false;
    @Input() emmiterType: any = 1; // 1 = id only, 2 = object
	@Input() resetCoinSearchSubject: Subject<boolean> = new Subject<boolean>();

	//pass a value to this component
	// return this value with component.
	@Input() returnValues: any = null;
	
	@Input() defaultSelect: any = null;
	@Input() defaultSelectLoader: any = false;
	@Input() zindex: number = 1000;

	@Output() coinTypeIdSet = new EventEmitter();
	@Output() onSelectCoin = new EventEmitter();
	@Output() clearCoin = new EventEmitter();
	
	 public isloadAllCoins = false;

	constructor(public global: GlobalService, public coinService:CoinService) {
	}

	ngOnInit(): void {

		this.coinSelected = this.defaultSelect
		this.isloadAllCoins = true;

		//[allowSelect]="1" [type]="1" [emmiterType]="2"
		if (this.allowSelect == true && this.type == 1 && this.emmiterType == 2) {
			
			this.selectCoin(this.coinSelected)
		}

		if(this.global.allWalletTypes==null && (this.type==1 || this.type == 3)) {
			this.global.httpGET('wallet_types_all').subscribe(response => {
				if(response.status==true){
	
					this.global.allWalletTypes = response.data;
					this.onSetCoin(this.selectedCoin);
				} 
				this.isloadAllCoins = false;
			}, error => {
				this.isloadAllCoins = false;
			});	
		}else{
			this.onSetCoin(this.selectedCoin)	
			this.isloadAllCoins = false;
		}

		if(this.global.allWalletTypes==null && this.type==2) {
			this.global.httpGET('wallet_types').subscribe(response => {
				if(response.status==true){
					this.global.allLiveWalletTypes = response.data;
					this.onSetCoin(this.selectedCoin);
				} else {
				}
				this.isloadAllCoins = false;
			}, error => {
				this.isloadAllCoins = false;
			});
		}else{
			this.onSetCoin(this.selectedCoin);
			this.isloadAllCoins = false;
		}

		this.resetCoinSearchSubject.subscribe(response => {
			if(response){
				console.log('reset called')
				this.resetComponent()
		  	}
		})
		
	}

	resetComponent(){
		this. coinSearch = null
		this. foundResults = []
		this. foundResultsOnlyKey = []
		this. coinSelected = null
	}

	ngOnChanges(changes: SimpleChanges){
		

		if(changes.selectedCoin && changes.selectedCoin.currentValue !== changes.selectedCoin.previousValue){
			
			this.onSetCoin(changes.selectedCoin.currentValue)
		} 
	}

	private onSetCoin(coin){
		this.coinSearch = coin;
		if(this.global.allWalletTypes !== null && coin !== null){

			for(var i = 0; i < this.global.allWalletTypes.length; i++) {
			

				if(this.global.allWalletTypes[i].display_symbol.toUpperCase() === this.coinSearch.toUpperCase()) {
					this.coinSelected = this.global.allWalletTypes[i];
					this.onSelectCoin.emit({
						coin: this.coinSelected,
						return: this.returnValues
					});
				
					break;
				}
	
				if(this.global.allWalletTypes[i].type.search(this.camelize(this.coinSearch)) !== -1){
					this.coinSelected = this.global.allWalletTypes[i];
					this.onSelectCoin.emit({
						coin: this.coinSelected,
						return: this.returnValues
					});
				
					break;
				}
			}
		}

	}

	ngAfterViewInit() {
		try { //Added try catch since this was failing when in different tags. -TR
			let width = this.searchComp.nativeElement.querySelector('input').offsetWidth;
			let div = this.searchComp.nativeElement.querySelector('.coin-search-div');
			div.style.width = width + 'px';
			div.style.zIndex = this.zindex;
		} catch (e) {}
	  }

	public onFocus(){
	
		let div = this.searchComp.nativeElement.querySelector('.coin-search-div');
		div.style.zIndex = this.zindex;
	}

	public onBlur(){

	}

	doSearch($event){
	
		let camerlized = this.camelize(this.coinSearch)
		this.foundResults = []
		this.foundResultsOnlyKey = []

		if(this.coinSearch==null || this.coinSearch == '' || this.coinSearch == " "){
			this.foundResults = []
			this.foundResultsOnlyKey = []
		} else {
			if(this.type==1){

				for(var i = 0; i < this.global.allWalletTypes.length; i++) {
					
					if(this.global.allWalletTypes[i].display_symbol.toUpperCase() === this.coinSearch.toUpperCase()) {
						if(!this.foundResultsOnlyKey.includes(this.global.allWalletTypes[i].id)){
							this.foundResultsOnlyKey.push(this.global.allWalletTypes[i].id)
							this.foundResults.push(this.global.allWalletTypes[i])
						}
					}
	
					if(this.global.allWalletTypes[i].type.search(this.camelize(this.coinSearch)) !== -1){
						if(!this.foundResultsOnlyKey.includes(this.global.allWalletTypes[i].id)){
							this.foundResultsOnlyKey.push(this.global.allWalletTypes[i].id)
							this.foundResults.push(this.global.allWalletTypes[i])
						}
					}

					if(this.global.allWalletTypes[i].id == this.coinSearch) {

						if(!this.foundResultsOnlyKey.includes(this.global.allWalletTypes[i].id)){
							this.foundResultsOnlyKey.push(this.global.allWalletTypes[i].id)
							this.foundResults.push(this.global.allWalletTypes[i])
						}
					}
	
				}
			} else if(this.type==2){
	
				for(var i = 0; i < this.global.allLiveWalletTypes.length; i++) {
					if(this.global.allLiveWalletTypes[i].display_symbol.toUpperCase() === this.coinSearch.toUpperCase()) {
						if(!this.foundResultsOnlyKey.includes(this.global.allLiveWalletTypes[i].id)){
							this.foundResultsOnlyKey.push(this.global.allLiveWalletTypes[i].id)
							this.foundResults.push(this.global.allLiveWalletTypes[i])
						}
					}
	
					if(this.global.allLiveWalletTypes[i].type.search(this.camelize(this.coinSearch)) !== -1){
						if(!this.foundResultsOnlyKey.includes(this.global.allLiveWalletTypes[i].id)){
							this.foundResultsOnlyKey.push(this.global.allLiveWalletTypes[i].id)
							this.foundResults.push(this.global.allLiveWalletTypes[i])
						}
					}
	
				}
			} else if(this.type==3){
		
				for(var i = 0; i < this.global.allWalletTypes.length; i++) {
					if(this.global.allWalletTypes[i].display_symbol.toUpperCase() === this.coinSearch.toUpperCase()) {
						if(!this.foundResultsOnlyKey.includes(this.global.allWalletTypes[i].symbol)){
							this.foundResultsOnlyKey.push(this.global.allWalletTypes[i].symbol)
							this.foundResults.push(this.global.allWalletTypes[i])
						}
					}
	
					if(this.global.allWalletTypes[i].type.search(this.camelize(this.coinSearch)) !== -1){
						if(!this.foundResultsOnlyKey.includes(this.global.allWalletTypes[i].symbol)){
							this.foundResultsOnlyKey.push(this.global.allWalletTypes[i].symbol)
							this.foundResults.push(this.global.allWalletTypes[i])
						}
					}
	
				}
			}

			this.foundResults.sort(function(a, b) {
				return a.id - b.id;
			});

		}
		
	}

	selectCoin(coin){
		if(this.allowSelect){
	
			this.coinSelected = coin
			if(this.emmiterType==1){
				this.coinTypeIdSet.emit(this.coinSelected.id)
			} else{
				this.coinTypeIdSet.emit(this.coinSelected)
			}

			this.onSelectCoin.emit({
				coin: this.coinSelected,
				return: this.returnValues
			});

			if(this.clearOnSelect){
				this.unselectCoin(false)
			}
		}
	}

	unselectCoin(emit = true){
		
		this.foundResultsOnlyKey = null
		this.foundResults = []
		this.coinSearch = null

		if(emit){
			this.coinTypeIdSet.emit(0);
			this.clearCoin.emit({
				coin: this.coinSelected,
				return: this.returnValues
			});
		}
		
		this.coinSelected=null
	}

	camelize(str) {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		// Directly return the joined string
		return splitStr.join(' '); 
	}

}
