
<div class="container">
    <br/>
    <div class="row">
        <div class="col-md-8">
            <app-invoice-view [currentViewInvoice]="currentViewInvoice"></app-invoice-view>
        </div>
        <div class="col-md-4" *ngIf="currentViewInvoice!=null">
            <div class="row">
                <div class="col-md-12">

                    <ngb-alert *ngIf="walletError==1" [type]="'warning'" (close)="closeSubAlert()">
                        Please select a wallet type and a wallet address.
                    </ngb-alert>
                    <ngb-alert *ngIf="walletError==2" [type]="'warning'" (close)="closeSubAlert()">
                        Invalid Wallet Address. Please check your wallet address again.
                    </ngb-alert>

                </div>
            </div>
            <!-- first row -->
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="sel1">Billing Currency</label>
                        <span>
                            <p class="lead ">
                                {{ currentViewInvoice.fiat['code'] }} 
                                <img src="{{global.FE_IMG_URL}}img/c_flags/{{currentViewInvoice.fiat['code']}}.png" width="20" height="14" class="d-inline-block align-top" alt="" style="margin-top: 5px; "> 
                            </p>
                        </span>
                    </div>
                    
                </div>

                <div class="col-md-6">

                    <div class="form-check pl-0">
                        <label for="sel1">Status</label>
                        <p class="lead">
                            <app-invoice-status [status]="currentViewInvoice['state']"></app-invoice-status>
                        </p>
                    </div>
                </div>
            </div>
            <br/>
            <div class="row" *ngIf="currentViewInvoice['state'] == 1 || currentViewInvoice['state'] == 2">
                <div class="col">

                    <div class="form-check pl-0">
                        <button class="btn btn-primary btn-lg" (click)="initiatePay()" *ngIf="paymentState==0"> <i class="fa fa-btc" aria-hidden="true"></i> Pay with Crypto </button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="paymentState==1">
                <div class="card">
                    <div class="card-body">
                        <div class="container">
                            <ng-container *ngIf="!paymentInitLoading">
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            Please send: 
                                            <br/>
                                            <span class="text-bold">
                                                {{ currentViewInvoice['total'] / paymentInitPriceFetch[currentViewInvoice['fiat']['code']]  }}
                                            </span>
                                            {{ initPayCryptoSymbol }} 
                                            <br/>
                                            to the address shown below.
                                            <br/>
                                            <small class="text-primary pointer" (click)="showCalculations()" *ngIf="!paymentInitShowCalculations">Show crypto-fiat calculation</small>
                                            <small class="text-primary pointer" (click)="hideCalculations()" *ngIf="paymentInitShowCalculations">Hide crypto-fiat calculation</small>
                                        </p>

                                        <span *ngIf="paymentInitShowCalculations">
                                            <div class="alert alert-secondary mb-0" role="alert">
                                                Calculation:
                                                <br/>
                                                1 {{ initPayCryptoSymbol }} = {{ paymentInitPriceFetch[currentViewInvoice['fiat']['code']] }} {{currentViewInvoice['fiat']['code']}}
                                                <br/>
                                                {{ currentViewInvoice['total'] / paymentInitPriceFetch[currentViewInvoice['fiat']['code']]  }} {{ currentViewInvoice['symbol'] }}
                                                = 
                                                {{ currentViewInvoice['total'] }} {{currentViewInvoice['fiat']['code']}}
                                            </div>

                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img class="loading-img" width="300" height="300" data-src="https://quickchart.io/qr?text={{ currentViewInvoice['address'] }}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <ng-container>
                                                <label for="sel1">Blockchain</label>
                                                <div class="form-group">
                                                    <div class="after-coin-select">
                                                        <div class="selected-coin">
                                                            <table class="table table-tx mb-0 table-nowrap card-table pt-2">
                                                                <tbody>
                                                                    <tr class="pointer" >
                                                                        <td>
                                                                            <img class="img-fluid" src="{{authService.COIN_IMG_URL}}{{currentViewInvoice['image']}}" width="40" height="40" alt=""> {{currentViewInvoice['type']}} ({{currentViewInvoice['symbol']}})
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>    
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                    
                                        <div class="form-check pl-0">
                                            <label class="form-check-label" for="inlineCheckbox1">Deposit Wallet Address</label>
                                            <div class="input-group">
                                                <input type="text" #invoiceDespositAddress class="form-control" value="{{ currentViewInvoice.address }}" readonly>
                                                <div class="input-group-append pointer" (click)="copyAddress(invoiceDespositAddress)" ngbTooltip="Copied!" triggers="click:blur">
                                                    <button class="btn btn-primary" type="button"><i class="fa fa-files-o" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <ng-container *ngIf="currentViewInvoice.tokenInfo.length > 0">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="sel1">Payment currency</label>
                                                <ng-container>
                                                    <div class="form-group">
                                                        <div class="after-coin-select">
                                                            <div class="selected-coin">
                                                                <table class="table table-tx mb-0 table-nowrap card-table pt-2">
                                                                    <tbody>
                                                                        <tr class="pointer" *ngFor="let token of currentViewInvoice.tokenInfo">
                                                                            <td>
                                                                                <img class="img-fluid" src="{{COIN_IMG_URL}}{{token['tokenInfo']['image']}}" width="40" height="40" alt=""> {{token['tokenInfo']['type']}} ({{token['tokenInfo']['symbol']}})
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </ng-container>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn btn-primary btn-lg btn-block" (click)="initiateConfirm(confirmTransaction)"> Confirm Transaction </button>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="paymentInitLoading">
                                <div class="loading-img"></div>
                                <br/>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn btn-primary btn-lg btn-block disabled" disabled> Confirm Transaction </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                </div>
                
            </ng-container>
            <ng-container *ngIf="paymentState==2 || (currentViewInvoice['state'] == 5 || currentViewInvoice['state'] == 6)">
                <div class="row">
                    <div class="col">
    
                        <div class="form-check pl-0">
                            <label class="form-check-label" for="inlineCheckbox1">Confirmation Id</label>
                            <div class="input-group">
                                <input type="text" #confirmationIdInput class="form-control" value="{{ confirmationId }}" readonly [class.is-invalid]="!confirmationValidation">
                                <div class="input-group-append pointer" (click)="copyAddress(confirmationIdInput)" ngbTooltip="Copied!" triggers="click:blur">
                                    <button class="btn btn-primary" type="button"><i class="fa fa-files-o" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row" *ngIf="currentViewInvoice['explorerLink'] != null">
                    <div class="col">
    
                        <a href="{{currentViewInvoice['explorerLink']}}" target="_blank" class="text-primary pointer"> <i class="fa fa-external-link" aria-hidden="true"></i> Open in Explorer </a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentViewInvoice['state'] == 7">
                <hr/>
                <div class="row">
                    <div class="col">
                        <label>Rejected reason</label>
                        <p>
                            {{ currentViewInvoice['rejected_reason'] }}
                        </p>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col">
                        <div class="form-check pl-0">
                            <label class="form-check-label" for="inlineCheckbox1">Refund ID</label>
                            <div class="input-group">
                                <input type="text" #rejectionConfirmationIDInput class="form-control" value="{{ currentViewInvoice['rejected_confirmation_id'] }}" readonly>
                                <div class="input-group-append pointer" (click)="copyAddress(rejectionConfirmationIDInput)" ngbTooltip="Copied!" triggers="click:blur">
                                    <button class="btn btn-primary" type="button"><i class="fa fa-files-o" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row" *ngIf="currentViewInvoice['rejectionLink'] != null">
                            <div class="col">
            
                                <a href="{{currentViewInvoice['rejectionLink']}}" target="_blank" class="text-primary pointer"> <i class="fa fa-external-link" aria-hidden="true"></i> Open in Explorer </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <br/>
                
               
        </div>
    </div>
</div>

<ng-template #confirmTransaction>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Transactions</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Enter the blockchain transaction ID that was generated on the transaction you paid this invoice. This helps the invoicer to accept the payment.
        </p>
        <div class="form-check pl-0">
            <label class="form-check-label" for="inlineCheckbox1">Blockchain Confirmation Id </label>
            <input class="form-control" (ngModelChange)="blockchainConfirmationValidation=true" [(ngModel)]="paymentConfirmationId" [class.is-invalid]="!confirmationValidation"  type="text">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-block" (click)="confirmTransactionNow()" *ngIf="!paymentConfirmationLoading"> Confirm Transaction</button>
        <button class="btn btn-primary btn-block" *ngIf="paymentConfirmationLoading"> <img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Confirm Transction</button>
    </div>
</ng-template>
