<div class="modal-header">
  <h4 class="modal-title pull-left">Cancel Snapshot Sync</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss(false);">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <ngb-alert *ngIf="cancleStatusCode==2" [type]="'warning'" [dismissible]="false">
      Snapshot cancelation operation in progress.
    </ngb-alert>

    <ngb-alert *ngIf="cancleStatusCode==3" [type]="'danger'" [dismissible]="false">
      {{ cancelErrorMessage }}
    </ngb-alert>

    <p *ngIf="[2,3].indexOf(cancleStatusCode)==-1"> Are you sure you want to cancel snapshot operation in this job ?
      <br><br>
      <b>This action is not reversible.</b>
    </p>
  </div>
</div>

<div class="modal-footer">

  <button *ngIf="[3,2, 0].indexOf(cancleStatusCode) !== -1" type="button" class="btn btn-danger btn-lg" (click)="confirmSnapshotCancel()">
    Confirm
  </button>
  <button *ngIf="cancleStatusCode == 1" type="button" class="btn btn-danger btn-lg disabled">
    <img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Confirm
  </button>

</div>
