import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';

@Component({
  selector: 'app-component-models',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class ComponentModelsComponent implements OnInit {

  constructor(public global: GlobalService) { }

  ngOnInit() {
  }

}
