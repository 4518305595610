
<div id="wrapper"  *ngIf="global._authStatus">

	<app-side-nav-bar></app-side-nav-bar>
	<!-- Content Wrapper -->
	<div id="content-wrapper" class="content-wrapper d-flex flex-column">
		<div id="content">
			<!-- <app-coin-page></app-coin-page> -->

		</div>
	</div>

	
</div>




<!-- <div id="" *ngIf="!global._authStatus">

	<app-coin-page></app-coin-page>

	
</div>
 -->
