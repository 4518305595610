import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoinService } from 'app/_services/coin.service';
import { PennyLaneService } from 'app/_services/gateways/pennylane.service';
import { GlobalService } from 'app/_services/global.service';


@Component({
  selector: 'app-pennylane-callback',
  templateUrl: './pennylane-callback.component.html',
  styleUrls: ['./pennylane-callback.component.css']
})
export class PennylaneCallbackComponent implements OnInit {

  constructor(public global: GlobalService, public route:ActivatedRoute, private router:Router, private pennylaneService:PennyLaneService) { }
  public status = 0;
  public isFetching = true;
  ngOnInit() : void {

		if(this.global.isAuthenticated()){

      this.route.queryParams.subscribe(params => {
      this.getCode(params)
      });
      } else {
        this.global._cookieService.delete('oauth2Data', '/')
        this.global.logoutFinishStage();
      }
  }

  public errormesage = null;
  private getCode(params){
    this.errormesage = null;

    let portfolioId = this.global._cookieService.get('current_portfolioId');
    let oauth2data = this.global._cookieService.get('oauth2Data');

    oauth2data = JSON.parse(oauth2data);

    this.isFetching = true;
    this.pennylaneService.getAuthToken(params.code, params.state, portfolioId)
    .subscribe( res=>{
      if(res.code !== 200){
        let msg = "Encounter and error. please contact support."

        if(res.data && res.data.error_description){
          msg = res.data.error_description;
        }

        this.errormesage = msg;

        this.status = 3;

        setTimeout( () => { 
       
          this.router.navigate(['workspace', portfolioId, 'connections']);
        }, 1500);

      }else{
        this.status = 2;
        setTimeout( () => { 
          this.router.navigate(['workspace', portfolioId, 'connections']);
        }, 1500);
      }
    }, error=>{
 
      this.status = 3;
      this.errormesage ="Encounter and error. please contact support.";
      this.isFetching = false;
      setTimeout( () => { 
        this.router.navigate(['workspace', portfolioId, 'connections']);
      }, 1500);
    }, ()=>{
        this.isFetching = false;

    });

    
  }

  private saveAuthData(){
    this.pennylaneService
  }

}
