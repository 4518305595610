import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';
import {
  ImportSourceComponent
} from '../../../_components_crypto_one/gateway-page/components/asset/modal/source-selection/import-source.component';
import {CancelSnapshotSyncComponent} from './modals/cancel-snapshot-sync/cancel-snapshot-sync.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cron-job-history-page',
    templateUrl: './cron-job-history.component.html',
  })

export class CronJobHistoryPageComponent implements OnInit {

  public connModalRef = null;
    public page = 1;
    public historyArray = [];
    public isLoading = true;

    public source = {
        1: 'portfolio',
        2: 'wallet',
        3: 'gateway'
    };

    public jobs = {
        0 : 'Spot Workspace Balance Snapshot',
        1 : 'Spot Workspace Balance Snapshot',
        2 : 'Workspace DeFi Sync',
        3 : 'Re-sync Xero',
        4 : 'Re-sync QuickBooks',
        5 : 'Re-sync Oracle NetSuite',
        6 : 'Disconnected connection',
        8 : 'NFT Floor Price Sync',
        9 : 'Calculate Portfolio Balance'
    };

    public statuses = {
        0: 'Pending',
        1: 'Running',
        2: 'Completed',
        3: 'Error'
    }

    constructor(public global: GlobalService, public modalService: NgbModal) {

    }

    ngOnInit(): void {
        this.getData(this.page);
    }

    public emitLoadData() {
        this.getData(this.page);
    }

    getData(page = 1) {
        this.global.loadingPortfolioHistoryPageStates = true;
        // this.historyArray = [];
        this.global.httpGET('get_cron_history', {page, portfolio_id: this.global.currentPortfolioId})
        .subscribe( res => {

            const arr = [];
            const jobHistoryData = res.data.data.cron_history;
            const erpStatusData = res.data.data.erp_status;
            const invoiceData = res.data.data.invoice_data;
            console.log(jobHistoryData);
            for (const item of erpStatusData) {
                arr.push({
                    created_at : item.created_at,
                    source: this.source[item.type],
                    status: item.status,

                    job: 'Xero GL Account Sync'
                });
            }

            // clarify whether source is required
            for (const item of invoiceData) {
                arr.push({
                    created_at : item.created_at,
                    // source: this.source[],
                    status: item.status,
                  is_cancel : item.is_cancel,
                    job: 'Invoice Sync'
                });
            }

            for (const job of jobHistoryData) {

                if (job.task_id == 6 || job.task_id == 8 || job.task_id == 9) {
                    arr.push({
                        id: job.id,
                        created_at: job.created_at,
                        source: this.source[ job.task_id ],
                        job: this.jobs[ job.task_id ],
                        is_cancel : job.is_cancel,
                        status: job.status
                    });
                } else {
                    arr.push({
                        id: job.id,
                        created_at: job.created_at,
                        source: this.source[ job.task_id ],
                        job: this.jobs[ job.source ],
                        status: job.status,
                        is_cancel : job.is_cancel,
                    });
                }
            }

            arr.sort((a, b) => {
                return Date.parse(b.created_at) - Date.parse(a.created_at);
            });

            this.historyArray = arr;
            this.global.loadingPortfolioHistoryPageStates = false;
        }, err => {
            this.global.loadingPortfolioHistoryPageStates = false;
        })
    }

  openCancelSyncPopup(job, index) {
    this.connModalRef = this.modalService.open(CancelSnapshotSyncComponent, );
    this.connModalRef.componentInstance.job = job;
    this.connModalRef.result.then( result => {

        if ( result.data && result.data !== null) {
          this.historyArray[index].status = result.data.status;
          this.historyArray[index].is_cancel = result.data.is_cancel;
        }
    });
  }

}
