<div class="row">
    <div class="col-md-8">
        <h3> Invoice Details</h3>
    </div>
    <div class="col text-right">

        <button class="btn btn-primary" (click)="clickDownloadPDF()" *ngIf="PDFLoading==false">
            <i class="fa fa-print" aria-hidden="true"></i> Print/PDF
        </button>

        <button class="btn btn-primary disabled" disabled *ngIf="PDFLoading==true">
            <img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif">
        </button>
    </div>
</div>
<br/>

<div class="invoice-design" id="invoiceViewDesign" #invoiceViewDesign  *ngIf="currentViewInvoice!=null">

    <div class="row">

        <div class="col-md-7">

            <h3 class="text-uppercase justify-content-between"><img *ngIf="currentViewInvoice.source == 1" src="{{global.IMG_URL}}gateways/req.svg" style="height: 25px;" alt="request network logo" /> Invoice</h3>

            <div *ngIf="currentViewInvoice['state'] == 4 || currentViewInvoice['state'] == 5 || currentViewInvoice['state'] == 6 || currentViewInvoice['state'] == 7">

                <div class="invoice-banner ib-red" *ngIf="currentViewInvoice['state'] == 4">
                    CANCELED

                </div>

                <div class="invoice-banner ib-blue" *ngIf="currentViewInvoice['state'] == 5">
                    PAID
                </div>

                <div class="invoice-banner ib-green" *ngIf="currentViewInvoice['state'] == 6">
                    ACCEPTED
                </div>

                <div class="invoice-banner ib-grey" *ngIf="currentViewInvoice['state'] == 7">
                    REJECTED
                </div>
            </div>
            <br/>
            <div *ngIf="currentViewInvoice['logo_url']">
                <img
                src="{{currentViewInvoice['logo_url']}}"
                alt="invoice-custom-img"
                class="uploaded-image"
                style="max-width: 120px"
                >
            </div>
            <br/>
            <h5 *ngIf="currentViewInvoice.companyDetails != null && !currentViewInvoice.company_name">
                {{ currentViewInvoice.companyDetails['name'] }}
            </h5>
            <h5 *ngIf="currentViewInvoice.company_name">
                {{ currentViewInvoice.company_name }}
            </h5>
            <p *ngIf="!currentViewInvoice.first_name && !currentViewInvoice.last_name">
                {{ currentViewInvoice.creatorInfo['first_name'] }}
                {{ currentViewInvoice.creatorInfo['last_name'] }}
                <br/>

                {{ currentViewInvoice.creatorInfo['email'] }}
            </p>
            <p *ngIf="currentViewInvoice.first_name && currentViewInvoice.last_name">
                {{ currentViewInvoice.first_name }}
                {{ currentViewInvoice.last_name }}
                <br/>

                {{ currentViewInvoice.creatorInfo['email'] }}
            </p>

        </div>
        <div class="col">
            <div class="form-check">
                <label class="form-check-label" for="inlineCheckbox1">Invoice #: </label>
                <br/>
                <p class="lead mb-0">{{ currentViewInvoice.invoice_no }}</p>
            </div>
            <br/>
            <div class="form-check">
                <label class="form-check-label" for="inlineCheckbox1">Date: </label>
                <br/>
                <p class="lead mb-0">{{ currentViewInvoice.date_created }}</p>
            </div>
            <br/>
            <div class="form-check">
                <label class="form-check-label" for="inlineCheckbox1">Due Date: </label>
                <br/>
                <p class="lead mb-0">{{ currentViewInvoice.due_date }}</p>
            </div>
            <br/>
            <div class="form-check text-center border bg-white pl-0">
                <br/>
                <span class="text-muted">Amount due </span>
                <h3 class="pl-2 mt-2">
                    {{ currentViewInvoice.fiat['symbol'] }} {{ currentViewInvoice.total  }}
                </h3>
                <br/>
            </div>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-md-7">
            <div class="form-check pl-0">
              <ng-container *ngIf="currentViewInvoice.fromInfo != null">
                <span *ngIf="currentViewInvoice.fromInfo.name != null"> <label class="form-check-label" >From: </label> {{ currentViewInvoice.fromInfo.name }}</span> <br/>
                <span *ngIf="currentViewInvoice.fromInfo.email != null"> <label class="form-check-label" >Email: </label> {{ currentViewInvoice.fromInfo.email }}</span> <br/>
                <span *ngIf="currentViewInvoice.fromInfo.tel != null"> <label class="form-check-label" >Tel: </label> {{ currentViewInvoice.fromInfo.tel }}</span>
              </ng-container>
                <br/>
                <span *ngIf=" [2,3].indexOf(currentViewInvoice.source) == -1">{{ currentViewInvoice.recipient_email }}</span>
            </div>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-md-12">
            <div class="invoice-content">
                <!-- begin table-responsive -->
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th> Description</th>
                            <th class="text-center" width="10%">Quantity</th>
                            <th class="text-center" width="10%">
                                Price
                            </th>
                            <th class="text-right" width="20%">
                                TOTAL
                                <span> ({{ currentViewInvoice.fiat['code'] }}) </span>
                            </th>
                        </thead>
                      <tbody>
                        <ng-container *ngFor="let item of currentViewInvoice['lineItems'];let index = index;trackBy:trackByIndex;">
                            <tr class="border-bottom" style="background-color: #fff;">
                                <td>
                                    <span *ngIf="item.title != null">{{item.title}}</span>
                                    <span *ngIf="item.title == null"> - </span>
                                    <br/>
                                    <span class="text-muted" *ngIf="item.description != null">{{item.description}}</span>
                                    <span *ngIf="item.description == null"> - </span>

                                </td>
                                <td class="text-center">
                                    <span>{{item.quantity}}</span>
                                </td>
                                <td class="text-right">
                                    <span>{{ currentViewInvoice.fiat['symbol'] }}  {{item.price}}</span>
                                </td>
                                <td class="text-right">
                                    <span> {{ currentViewInvoice['fiat']['symbol'] }} </span>
                                    <span>
                                        {{ item.quantity * item.price }}
                                    </span>

                                </td>
                             </tr>
                        </ng-container>
                      </tbody>
                   </table>
                </div>
                <!-- end invoice-price -->
             </div>
             <!-- end invoice-content -->
        </div>
    </div>

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 offset-md-4">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-tx table-nowrap card-table mb-0">
                        <tbody class="list border-left border-right border-bottom">
                            <tr class="bg-white">
                                <td  class="text-bold" style="width:35%">
                                    Subtotal
                                </td>
                                <td class="text-right">
                                    <span> {{ currentViewInvoice.fiat['symbol'] }} </span>
                                     {{ currentViewInvoice.sub_total  }}
                                </td>
                            </tr>
                            <tr class="bg-white">
                                <td  class="text-bold" style="width:35%">
                                    Discount %
                                </td>
                                <td class="text-right">
                                    {{ currentViewInvoice.discount_perc  }}
                                </td>
                            </tr>
                            <tr class="bg-white">
                                <td  class="text-bold" style="width:35%">
                                    Total
                                </td>
                                <td class="text-right">
                                    <span> {{ currentViewInvoice.fiat['symbol'] }} </span>
                                    {{ currentViewInvoice.total  }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                </div>
            </div>
        </div>
      </div>

    <hr/>
    <div class="row">

        <div class="col-md-6">
            <div class="form-group" *ngIf="currentEditWalletDetails==null">
                <label for="sel1">Notes to customer <small>(optional)</small> </label>
                <br/>
                <span *ngIf="currentViewInvoice.notes != null">{{ currentViewInvoice.notes }}</span>
                <span *ngIf="currentViewInvoice.notes == null"> - </span>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group" *ngIf="currentEditWalletDetails==null">
                <label for="sel1">Terms and conditions <small>(optional)</small> </label>
                <br/>
                <span *ngIf="currentViewInvoice.terms != null">{{ currentViewInvoice.terms }}</span>
                <span *ngIf="currentViewInvoice.terms == null"> - </span>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <span *ngIf="currentViewInvoice.source == 0">
                <i>Powered by: </i>
                <img src="/assets/img/logo/logo.png" style="width: 12%;" alt="cryptoworth logo">
            </span>
        </div>
    </div>
</div>


<!-- <div id="pdfTable" #pdfTable style="background-color: #fff;">
    <div style="max-width: 800px;margin: auto;padding: 30px;border: 1px solid #eee;box-shadow: 0 0 10px rgba(0, 0, 0, .15);font-size: 16px;line-height: 24px;font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;color: #555;background-color: #fff;">
        <h3 style="text-transform: uppercase">Invoice</h3>
        <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: solid 1px; line-height: inherit;text-align: left;">
            <tr class="top">
                <td colspan="4" style="padding: 5px;vertical-align: top;">
                    <table style="width: 100%;line-height: inherit;text-align: left;">
                        <tr>
                            <td class="title" style="padding: 5px;vertical-align: top;padding-bottom: 20px;color: #333;">
                                <h5>{{ currentViewInvoice.companyDetails['name'] }}</h5>
                                {{ currentViewInvoice.creatorInfo['first_name'] }}
                                {{ currentViewInvoice.creatorInfo['last_name'] }}
                                <br/>
                                {{ currentViewInvoice.creatorInfo['email'] }}
                            </td>

                            <td style="padding: 5px;vertical-align: top;text-align: right;padding-bottom: 20px;">
                                Invoice #: {{ currentViewInvoice.invoice_no }}<br>
                                Created: {{ currentViewInvoice.date_created }}<br>
                                Due: {{ currentViewInvoice.due_date }}
                            </td>
                        </tr>
                        <tr>
                            <td class="title" style="padding: 5px;vertical-align: top;padding-bottom: 20px;font-size: 45px;line-height: 45px;color: #333;">

                            </td>

                            <td style="padding: 5px;vertical-align: top;text-align: right;padding-bottom: 20px;">
                                <h3>{{ currentViewInvoice.total  }}</h3>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <hr/>
            <tr class="information">
                <td colspan="4" style="padding: 5px;vertical-align: top;">
                    <table style="width: 100%;line-height: inherit;text-align: left;">
                        <tr>
                            <td style="padding: 5px;vertical-align: top;text-align: left;padding-bottom: 40px;">
                                Send to: {{ currentViewInvoice.recipient_email }}<br>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr class="heading">
                <td style="padding: 5px;vertical-align: top;background: #eee;border-bottom: 1px solid #ddd;font-weight: bold;">
                Description
                </td>
                <td style="padding: 5px;vertical-align: top; text-align: center; background: #eee;border-bottom: 1px solid #ddd;font-weight: bold;">
                Quantity
                </td>
                <td style="padding: 5px;vertical-align: top; text-align: center; background: #eee;border-bottom: 1px solid #ddd;font-weight: bold;">
                    Price
                </td>
                <td style="padding: 5px;vertical-align: top; text-align: center; background: #eee;border-bottom: 1px solid #ddd;font-weight: bold;">
                    Total
                    <span> ({{ currentViewInvoice.fiat['code'] }}) </span>
                </td>
            </tr>

            <tr class="details" *ngFor="let item of currentViewInvoice['lineItems'];let index = index;trackBy:trackByIndex;">
                <td style="padding: 5px;vertical-align: top; padding-bottom: 20px; text-align: left;">
                    <span *ngIf="item.title != null"> {{item.title}} </span>
                    <span *ngIf="item.title == null"> - </span>
                    <span *ngIf="item.description != null"> {{item.description}} </span>
                    <span *ngIf="item.description == null"> - </span>
                </td>

                <td style="padding: 5px;vertical-align: top; text-align: center; padding-bottom: 20px;">
                    <span> {{item.quantity}} </span>
                </td>
                <td style="padding: 5px;vertical-align: top; text-align: center; padding-bottom: 20px;">
                    <span> {{ currentViewInvoice.fiat['symbol'] }}  {{item.price}} </span>
                </td>
                <td style="padding: 5px;vertical-align: top; text-align: center; padding-bottom: 20px;">
                    <span> {{ currentViewInvoice['fiat']['symbol'] }} </span>
                    <span> {{ item.quantity * item.price }} </span>
                </td>
            </tr>

        </table>

        <table cellpadding="0" cellspacing="0" style="width: 100%;line-height: inherit;text-align: left;">
            <tr class="item">
                <td>
                    <div style="float: right;padding: 10px 20px;">
                        <div style="display:inline-block;padding-right: 20px;"><b>Subtotal</b></div>
                        <div style="display:inline-block">
                            <span> {{ currentViewInvoice.fiat['symbol'] }} </span>
                            {{ currentViewInvoice.sub_total  }}
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="item">
                <td>
                    <div style="float: right;padding: 10px 20px;">
                        <div style="display:inline-block;padding-right: 20px;"><b> Discount % </b></div>
                        <div style="display:inline-block;">
                            {{ currentViewInvoice.discount_perc }}
                        </div>
                    </div>
                </td>
            </tr>

            <tr class="item last">
                <td colspan="6">
                    <div style="float: right;padding: 10px 20px;">
                        <div style="display:inline-block;padding-right: 20px;"><b>Total</b></div>
                        <div style="display:inline-block;">
                            <span> {{ currentViewInvoice.fiat['symbol'] }} </span>
                            {{ currentViewInvoice.total  }}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div> -->
