import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { SOCIAL_LOGIN, SOCIAL_LOGIN_CALLBACK, METHODS } from './constants';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  public API_URL = null;
  public TOKENS = null;

  constructor(public http:HttpClient) { 
    this.API_URL = environment['apiURL'];
  }

  private generateRequest(requestObject) {

    let url = requestObject.url;
    let options = {};

    if(Object.keys(requestObject.routeParams).length > 0){
      for(let i in requestObject.routeParams){
        url = url.replace("{"+ i +"}", requestObject.routeParams[i]);
      }
    }

    if( Object.keys(requestObject.queryParams).length > 0){
      let params = new HttpParams();
      for(let i in requestObject.queryParams){
        params = params.append(i, requestObject.queryParams[i]);
      }
      options['params'] = params;
    }

    let httpObject = null;

    switch(requestObject.method){
      case METHODS.POST:{
        httpObject = this.http.post(this.API_URL+''+url, requestObject.body, options);
        break;
      }

      default:{
        httpObject = this.http.get(this.API_URL+''+url, options);
        break;
      }
    }

    return httpObject.pipe(
      map(response => response)
    ).toPromise()
  }

  public socialLogin( data):Promise<any>{
    console.log('social login called')
    let _obj = SOCIAL_LOGIN;
    _obj.body = data;

    return this.generateRequest(_obj);
    // return this.http.post(this.API_URL + 'refresh_token', {
    //   refreshToken:this.TOKENS['refresh_token']
  }

  public getCallbackGoogle(provider):Promise<any>{
    let _obj = SOCIAL_LOGIN_CALLBACK;
    _obj.queryParams = {'provider': provider};
    return this.generateRequest(SOCIAL_LOGIN_CALLBACK);
  //   return this.http.post(this.API_URL + 'refresh_token', {
  //     refreshToken:this.TOKENS['refresh_token']
  // })
  }
}
