import { Component, OnInit, Renderer2, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'app-onboarding',
	templateUrl: './onboarding.component.html'
})
export class OnboardingComponent implements OnInit  {

	@ViewChild('onboardingModal') onboardingModal : any;

	modalRef = null

	constructor (public global: GlobalService, public coinService:CoinService, public route:ActivatedRoute, public modalService: NgbModal, 
		private renderer: Renderer2) {
	}

	ngOnInit() {


	}

	openModal(modal){
		this.modalRef = this.modalService.open(modal)
	}

	changeWindowState(state){
		
		console.log('state change: ' + state);
		this.global.onboardingWindowState = state

		if(state == 2){
			this.modalRef = this.modalService.open(this.onboardingModal, {size:'lg'})
		} else if(state == 1){
			this.modalRef.close()
		} else if(state == 0){
			this.modalRef.close()
		}

	}



}
