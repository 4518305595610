import { Output, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { URLSearchParams } from '@angular/http';
import { Title, SafeResourceUrl } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';
import { Observable, Subject, of } from 'rxjs';
import { finalize, map, share, tap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';

import { NgxPendoService } from 'ngx-pendo';
import * as moment from 'moment';
import 'moment-timezone';
import * as socketIo from 'socket.io-client';

import { AuthenticationService } from '../_services/authentication/authentication.service';
import { json } from 'express';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService, SocialUser } from 'angularx-social-login';
import { LoaderService } from './loader/loader.service';


declare var $;


@Injectable()
export class GlobalService {

	constructor(
		public http: Http,
		public _http: HttpClient, // _http are intercepted through authentication monitors
		public route: ActivatedRoute,
		public _cookieService: CookieService,
		public authService: AuthenticationService,
		public loaderService: LoaderService,
		public router: Router,
		public titleService: Title,
		public moment: MomentModule,
		private deviceService: DeviceDetectorService,
		public modalService: NgbModal,
		public socialAuth: AuthService,
		protected ngxPendoService: NgxPendoService
	) {

		this.API_URL = this.authService.API_URL
		this.IMG_URL = this.authService.IMG_URL
		this.COIN_IMG_URL = this.authService.COIN_IMG_URL
		this.DEFI_IMG_URL = this.authService.DEFI_IMG_URL
		this.EXCHANGE_IMG_URL = this.authService.EXCHANGE_IMG_URL
		this.GATEWAY_IMG_URL = this.authService.GATEWAY_IMG_URL
		this.HS_WALLET_IMG_URL = this.authService.HS_WALLET_IMG_URL
		this.FE_IMG_URL = this.authService.FE_IMG_URL
		this.CDN_IMG_URL = this.authService.CDN_IMG_URL

		console.log(environment['title'])

		this.isAuthenticated()
		this.checkServerStatus()
		this.getAppData()
		this.getCryptoWorldMarket()
		this.detectbrowser()
		this.ipLocationDetect()
		this.getAllAddresses()
		// this.detectAdblock()

		this.ngxPendoService.formatPendoId()

		this.appSettingsStateChangeSubscriber = this.appSettingsStateChange.subscribe(response => {
			this.httpPOST('user_settings', { userSettings: this.appSettings }).subscribe(response => {
				if (response.status == true) {

				}
			}, error => {
			});
		})

		this.userPortfolioSettingsStateChangeSubscriber = this.userPortfolioSettingsStateChange.subscribe(response => {
			console.log('called')
			this.httpPOST('user_portfolio_settings',
				{ userPortfolioSettings: this.userPortfolioSettings, portfolioId: this.currentPortfolioId }).subscribe(response => {
					if (response.status == true) {

					}
				}, error => {
				});
		})

	}
	readonly classificationLevels = [0, 1]
	readonly SERVER_UTC_OFFSET_MS = 18000000
	readonly DATE_FORMAT = 'MMM DD, YYYY hh:mm A'
	public DEFAULT_HEADING = 'Cryptoworth - Crypto accounting | Crypto Tax | Crypto Workspace management'

	public imageVer = '1.1'
	public PAGE_INDEX = null;

	public app_commerceInit = false
	public app_accountingInit = false

	public portfolioUTCOffset = null;
	public connectedGateways = []
	public connectedGatewaysLoading = false

	public skipOnboarding = false
	public currentStepInfo = null
	public onboardingMode = 0
	public showOnboarding = false
	public onboardingWindowState = 1
	public currentOnboardingStep = 0

	public workflowTypes = null

	public socialUser: SocialUser;
	public serverTz = 'America/Toronto'
	// public serverTzOffset = "-0500" // NOT IN USE - Need to adjust for daylight savings
	public _appState = 1 // 1 = active, 2 = loggin out
	public $ = $;
	public _uiState = 'default'
	public exchangeSyncStatus = 0
	public resentEmailSent = 0
	public keepUserLoggedIn = false
	public currentPortfolioViewMode = 'overview'
	public allWalletTypes = null
	public allLiveWalletTypes = null
	public parsedAnalyticsData = null
	public accountViewMode = 1
	public lastLoadedTimeDeFi = null

	public allNFTCollections = null

	public layoutMode = 1;
	public _PRODUCT_ID = null;

	public searchType = 'global';
	public searchText = null;
	public showSupportChat = false
	public loginTempDetails = null
	public perSessionShowTutorial = null
	public perSessionShowOnboarding = null

	public _tableLoaderSize = Array(50).fill(0).map((x, i) => i)

	public addedoAuth2Exchange = false
	public API_URL = null
	public COIN_IMG_URL = null
	public IMG_URL = null
	public DEFI_IMG_URL = null
	public EXCHANGE_IMG_URL = null
	public HS_WALLET_IMG_URL = null
	public FE_IMG_URL = null
	public GATEWAY_IMG_URL = null
	public CDN_IMG_URL = null
	public _authStatus = null
	public TOKENS = null
	public _HS_STATUS = false // handshake status

	public allConnections = null
	public allConnectionsLoading = false
	public globalPriceFetchError = false
	public _userDetails = null
	public _netWorth = 0
	public _DEFINetworth = 0
	public _wallets = null
	public _transactions = null
	public _transactionCount = null
	public _transactionAnalysisLimit = 50
	public _ignoredTxns = []
	public _ignoredTxnAssets = []
	public selectFullTx = false
	public selectFullDropTxns = []
	public _virtualWallets = null
	public _virtualWalletsActual = []
	public _walletsActual = []
	public _exchangesConnected: Array<any> | null = null
	public _exchangesActual = []
	public _allApplicableAssets = []
	public _allApplicableAssetsUnique = []
	public _assetUniqueString = null
	public _coingeckoUniqueString = null
	public _assetUniqueDetailedString = null
	public _companyDetails = null
	public _packageDetails = null
	public _packagePolicies = null
	public _packagePurchaseDetails = null
	public _companyUsers = null
	public _isOwner = false
	public _isAdmin = false;
	public _allowedEntities = -1;
	public _privileges = {}
	public _transactionTags = null
	public ranReconCheck = false
	public _transactionTypes = ['send', 'receive', 'buy', 'sell']
	public _currency = {
		'symbol': '$',
		'code': 'USD'
	}
	public _portfolioInfo = null
	public _portfolioCalcModes = []
	public classificationRuleCriterias = null
	public _portfolio_currency = null
	public _portfolio_currency_secondary = null
	public _classifications = null;
	public _classificationsAscending = null;
	public _classificationsFiltered = []
	public _labelLibrary = null;
	public _appMode = null
	public _UTCOffsets = null

	// Reports Dashboard variables
	public _taxDB_proceesedUCGLAssets = []
	public _taxDB_processedUCGLConnections = []
	public _taxDB_yearCalculatedDataTotal = null
	public _taxDB_yearCalculatedCGL = null
	public _taxDB_yearCalculatedUCGL = null
	public ERPStateLoader = false
	public ERPStateLoaderFT = false


	public _taxDB_yearIncludedFees = null
	public _taxDB_yearExcludedFees = null
	public _taxDB_yearTotalFees = null

	public _taxDB_yearData = null

	public _taxDB_calculatedTransactions = null
	public _taxDB_txCalculationMode = null
	public _taxDB_calculatedUCGL = null
	public _taxDB_totalSales = null
	public _taxDB_calculatedCGL = null
	public _taxDB_calculatedDataTotal = null
	public _taxDB_calculatedAssetKeys = null
	public _taxDB_calculationSummary = null
	public _taxDB_calculationAssetDetails = []
	public _taxDB_yearDataDetails
	public global_months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	public allowedTelCountries = ['us', 'ca', 'cn', 'mt', 'ch', 'gb']
	public _taxDB_taxYearView: any = 0;
	public _taxDB_taxlots = null
	public _taxDB_currencyDetails = null
	public _taxDB_calculatedUCGLUpdated = null
	public _taxDB_calculatedUCGLPurchaseTotal = null
	public _taxDB_calculatedUCGLCurrentTotal = null
	public _taxDB_calculatedUCGLJobStatus = null

	public _taxDB_calculatedUCGLDate = null

	public _taxDB_totalIncludedFees = null
	public _taxDB_totalExcludedFees = null
	public _taxDB_totalFees = null

	public _taxDB_processedTransactions = []
	public _taxDB_processedTransactionsAcquisitions = []

	public _taxDB_txBehaviorSanityFlag = null

	// end of Reports Dashboard

	public allWalletTypesCC
	public companyName = 'Cryptoworth'
	public appName = 'Crypto One'
	public productName = 'Crypto accounting | Crypto Tax | Crypto Workspace management'

	public currencies = null
	public currentViewNewsList = null


	public serverInfo = null

	public APIHandShakeStatus = false
	public checkUserActivationStatusCheck = false
	public servercheckStatus = null

	public supportedCoins = []
	public cryptoWorldMarket = null

	public currentPortfolioId = null
	public lastPortfolio = null
	public currentPortfolio = null
	public portfolios = null
	public portfoliosGlobalCount = -1
	public portfolioInvitations = null
	public exchangesSupported = null

	public connectionsReportShowUnsupportedTokens = false

	public logginOutStatus = 0

	public guestViewMode = 'main'

	public paymentActive = false
	public paymentModalRef
	public loginDivState: any = 0

	public deviceInfo = null
	public ipInfo: any = null

	public shWallets = null
	public walletTypes = null

	public portfolioHistoryMode = 1

	public hotWalletToOpen = null

	public recaptchaSiteKey = '6LdiHZ4bAAAAAI0SJTk4okzvhBGGNemoPg77kDXC'

	public selectedTx = []
	public specialSelectTx = null
	public selectedTxDetails = []

	public selectedNFT = []
	public selectFullDropNFTs = []
	public selectFullNFT = false
	public selectedNFTFull = []

	public _dashboardData = null
	public _dashboardARAPData = null

	public _notificationsPopups = []

	public currentPortfolioViewState = 0
	public currentPortfolioNewNavState = 0

	public SOCKET_SERVER_URL = null;
	public socket = null
	public portfolioLoadingPreCheck = false
	public viewSettings = false
	public settingsStatus = 0
	public portfolioHistoryViewPage = 6
	public filesViewItem = 0
	public connectionDisplay = 1

	// canery release
	public release = null;
	public totalProtfolioBalance = 0;
	public coinBalanceInfo = [];
	public defiBalanceInfo = null;
	public walletData = [];
	public exchageData = [];
	public virtualWallets = [];
	public coinValueDataSet = [];
	public coinNameDataSet = [];
	public portfolioLoadingStatus = null;
	public onSyncPortfolio = [];
	public pageConnections = [];
	public connectionsTotal = 0;
	public connectionAssets = [];
	public total_connection_count = 0;
	public total_wallet_count = 0;
	public total_acct_count = 0;
	public total_virtual_wallet_count = 0;
	public total_custom_connection_count = 0;

	public currentPortfolioTaxCalProcessCount = 0;
	public currentPortfolioTaxCalPreProcessCount = 0;
	public gatewaySyncStatus = {
		qb: -1,
		xero: -1,
		netSuite: -1,
		virtual: -1,
		pennylane: -1
	};

	public gatewayUGLSyncStatus = {
		qb: -1,
		xero: -1,
		netSuite: -1,
		virtual: -1,
		pennylane: -1
	};

	public gatewayVirtualSyncStatus = {
		qb: -1,
		xero: -1,
		netSuite: -1,
		virtual: -1,
		pennylane: -1
	};

	public latestSyncobj = {
		qb: null,
		xero: null,
		ons: null,
		virtual: null,
		pennylane: null
	}

	public nftSyncStatus = 2 // Set to 2 to have default match a 'success' db status

	public requestSyncStatus = 0
	public requestSyncLast = 'Never'

	public addressBookInfoCryptoView = []

	public taxDashboardFetchTime = null

	public txViewPage = 1

	public socketChannels = [
		'cw-notifications'
	]


	public txPageAgenda = false // Want to add a txPageAgenda? Switch this to an int, and duplicate the html on transactions-page! -Trent.
	public txPageAgendaType = 'warning'
	public txPageAgendaData = null

	public txDashboardLoaded = false
	public _calcualationTransactions = null

	public flags = {
		portfolioNetworthDidcrepancy: false
	}

	public portfolioIssue = {
		title: null,
		description: null,
		type: null,
		priority: null,
		sourceIds: []
	}

	public enableFormSubmission = true

	readonly paginateCount = 100

	public classificationPage = 0;
	public viewingReportId = 0

	public CCAPIKey = '59838afdc072a85bbe5b03d3179476552717fd2f47856f22a088b9639c93ab44';
	// 'bb7313df631767056e4306376d94438b3a2c8cf0ca6599324d179433fddc07cb';
	// public CCAPIKey = '1814d6eb0cc57394c958d0aa8b7fc007772cf12e0043a8a4f520b51557ebfbc3';

	public taxId = null;

	public debugAllowd = environment.production === false;

	globalPageMessageType = 'info'
	functionDescription = 'Filter for a specific blockchain function. Enter the function with or without the parameters. IE: if the function is withdrawAmount(int64), enter withdrawAmount() or withdrawAmount(int64).'
	readonly globalPageMessages = [
		// 1
		'The user has been added to the company.',

		// 2
		'The user has been successfully deleted.',

		// 3
		'The user\'s password is changed successfully.',

		// 4
		'Issue successfully created. Go to the \'Issues\' page to see all the issues.',

		// 5
		'Sanity check successfully completed. Sanity check values and discrepancies are updated.',

		// 6
		'Transaction dates have been modified successfully.',

		// 7
		'Transaction dates have been modified failed.'

	]

	public pressArticles = [
		{
			title: 'Crypto Has Come a Long Way Since the Last Bull Run in 2017. Here\'s Why.',
			link: 'https://www.entrepreneur.com/article/364623',
			image: '/assets/img/ep.png'
		},
		{
			title: 'Nasty Surprises Of Crypto Taxes And How To Avoid Them',
			link: 'https://cryptodaily.co.uk/2020/12/nasty-surprises-of-crypto-taxes-and-how-to-avoid-them',
			image: '/assets/img/cryptodaily.png'
		},
		{
			title: 'Cryptoworth Added To Polymath Service Provider Ecosystem',
			link: 'https://aithority.com/technology/cryptocurrency/cryptoworth-added-to-polymath-service-provider-ecosystem/',
			image: '/assets/img/aithority.png'
		},
		{
			title: 'These are the Top Blockchain Companies in Toronto (2021)',
			link: 'https://df.media/these-are-the-top-blockchain-companies-in-toronto-2021/',
			image: '/assets/img/daily_finance.png'
		},
		{
			title: 'Want to Hitch Your Business to the Crypto Bandwagon in 2021?',
			link: 'https://badcryptopodcast.com/2021/02/16/want-to-hitch-your-business-to-the-crypto-bandwagon-in-2021/',
			image: '/assets/img/badcrypto.png'
		},
		{
			title: 'How Crypto ATM Operators Ensure Compliance – Accounting',
			link: 'https://coinatmradar.com/blog/how-crypto-atm-operators-ensure-compliance-accounting/',
			image: '/assets/img/coinatmradar.png'
		},
		{
			title: 'Cryptoworth Integrates Dash Streamlining Cryptocurrency Workspace Management',
			link: 'https://dashnews.org/cryptoworth-integrates-dash-streamlining-cryptocurrency-portfolio-management/',
			image: '/assets/img/dash.png'
		},
		{
			title: 'Cryptoworth Added To Polymath Service Provider Ecosystem',
			link: 'https://info.polymath.network/blog/cryptoworth-added-to-polymath-service-provider-ecosystem',
			image: '/assets/img/polymath.png'
		}
	]

	public LogTypes = {
		'dispose_reference': 'Disposition references',
		'wac_reference': 'WAC references',
	}

	public pageMessage = 0

	public connectionDeleteText = 'delete me'
	public connectionIUnderstandText = 'I understand'

	public DeFiViewWallet = null
	public DeFiViewData = null
	public DeFiLastViewWalletId = null
	public DeFiLastViewTime = 0

	public libraryMode = 1

	public txSettingsDefaultView = {
		date: {
			'display': 'Date',
			show: 1
		},
		syncDate: {
			'display': 'Inserted Date',
			show: 0
		},
		type: {
			'display': 'Type',
			show: 1
		},
		running_balance: {
			'display': 'Running Balance',
			show: 1
		},
		in: {
			'display': 'In',
			show: 1
		},
		out: {
			'display': 'Out',
			show: 1
		},
		methodId: {
			'display': 'Method Id',
			show: 1
		},
		contractAddressExecutor: {
			'display': 'Contract Address Executor',
			show: 1
		},
		cost: {
			'display': 'Cost Basis',
			show: 1
		},
		sale: {
			'display': 'Proceeds',
			show: 1
		},
		transactionFee: {
			'display': 'Transaction Fee',
			show: 0
		},
		unitPrice: {
			'display': 'Unit Price',
			show: 0
		},
		gainLoss: {
			'display': 'Capital Gain/Loss',
			show: 0
		},
		market: {
			'display': 'Market',
			show: 0
		},
		contract: {
			'display': 'Contract',
			show: 0
		},
		txId: {
			'display': 'Tx Id',
			show: 1
		},
		functionName: {
			'display': 'Function',
			show: 0
		},
		orderId: {
			'display': 'Order Id',
			show: 0
		},
		memo: {
			'display': 'Memo',
			show: 0
		},
		behaviour: {
			'display': 'Behavior',
			show: 0
		},
		label: {
			'display': 'Label',
			show: 0
		},
		marketPair: {
			'display': 'marketPair',
			show: 0
		},
		marketPairRadio: {
			'display': 'marketPairRadio',
			show: 0
		},
		classification: {
			'display': 'Classification',
			show: 1
		},
		gateway: {
			'display': 'App',
			show: 0
		},
		qbClasses: {
			'display': 'ERP Class',
			show: 0,
			description: 'Only applicable if you have  ERP connected and class feature enabled in your ERP account. '
		},
		qbDepartment: {
			'display': 'ERP Department',
			show: 0,
			description: 'Only applicable if you have  ERP connected and department feature enabled in your ERP account. '
		},
		source: {
			'display': 'Source',
			show: 1
		},
	}

	// user app settings
	public appSettings = {
		defi_view_mode: 'grid',
		nft_view_mode: 'row',
		connections_hzb: false,
		positions_hzb: false,
		connections_accounts_hzb: false,
		positions_table_sort: null,
		assistance_links: true,
		txtable_columns: null,
		txtable_row_expand: null,
		txtable_multi_line_consolidation: null,
		sync_status: null,
		export_tx_colums: [],
		export_calc_colums: [],
		txtable_size: 50,
		api_key: null
	}

	public createAddressBookInfo = true
	public syncTransactions = true

	defaultTxFilter = {
		startDate: null,
		endDate: null,
		SyncedAtStartDate: null,
		SyncedAtEndDate: null,
		type: ['all', 'send', 'receive', 'buy', 'sell'],
		exchangeConnected: [],
		walletsConnected: [],
		virtualWalletsConnected: [],
		portfolioId: this.currentPortfolioId,
		allWalletsConnect: true,
		allExchangesConnect: true,
		assetsAll: true,
		assetIncludeFees: false,
		cryptoSymbolText: false,

		outputAddressIsEmpty: false,
		outputAddress: false,
		outputAddressMode: 1, // 1 = is, 0 = is not
		outputAddressGroup: [],

		inputAddress: false,
		inputAddressIsEmpty: false,
		inputAddressMode: 1, // 1 = is, 0 = is not
		inputAddressGroup: [],

		inputOrOutputAddressIsEmpty: false,
		inputOrOutputAddress: false,
		inputOrOutputAddressGroup: [],
		inputOrOutputAddressMode: 1, // 1 = is, 0 = is not

		contractAddress: false,
		contractAddressIsEmpty: false,
		contractAddressAddressMode: 1, // 1 = is, 0 = is not
		contractAddressGroup: [],

		executorContractAddress: false,
		executorContractAddressIsEmpty: false,
		executorContractAddressMode: 1, // 1 = is, 0 = is not
		executorContractAddressGroup: [],
		cryptoIdText: null,

		methodId: false,
		methodIdMode: 1, // 1 = is, 0 = is not
		methodIdGroup: [],
		transactionId: false,
		label: null,
		function: false,
		NFTName: false,
		NFTSymbol: false,
		tradeId: false,
		ledgerId: false,
		cryptoId: false,
		assetSymbols: [],
		deviations: null,
		taxability: null,
		txTags: [],
		tagsAll: true,
		txClasses: [],
		classesAll: true,
		ignoredErp: false,
		failedTx: null,
		calcUsed: null,
		isPegged: null,
		isLocked: null,
		isPaired: null,
		isInternalPaired: null,
		isMultipleEntry: null,
		isSplitTransaction: null,
		isRolled: null,
		userHidden: null,
		dataMerge: null,
		inAmount: [],
		inAmountOp: null,
		outAmount: [],
		outAmountOp: null,
		feeAmount: [],
		feeAmountOp: null,
		feeValue: [],
		feeValueOp: null,
		costBasis: [],
		costBasisOp: null,
		salePrice: [],
		salePriceOp: null,
		unitPrice: [],
		unitPriceOp: null,
		fiatValue: [],
		fiatValueOp: null,
		runningBalanceValue: [],
		runningBalanceValueOp: null,
		memo: false,
		memoContain: null,
		marketPair: null,
		marketPairRadio: null,
		programId: false,
		manuallyPriced: null,
		invoiceAttached: null,
		billAttached: null,
		fileAttached: null,
		hasRuleId: null,
		syncStatus: {
			synced: true,
			failed: true,
			ignored: true,
			unSynced: true,
			updated: true,
			virtual: true,
			all: true
		},
		erpSyncTypes: {
			journals: true,
			bankPayment: true,
			all: true
		},
		allAddressGroupsConnect: false,
		addressGroupsConnected: [],

		allConnectionGroupsConnect: false,
		connectionGroupsConnected: [],

		anyWeb3Product: true,
		web3ProductsByLabel: []

	}


	defaultNftFilter = {
		startDate: null,
		endDate: null,
		exchangeConnected: [],
		walletsConnected: [],
		virtualWalletsConnected: [],
		portfolioId: this.currentPortfolioId,
		allWalletsConnect: true,
		allExchangesConnect: true,
		assetsAll: true,
		classifications: false,
		assetSymbols: [],
	}

	public userPortfolioSettings = {
		export_tx_columns: [],
		export_calc_model_columns: [],
		txtable_filters: { ... this.defaultTxFilter },
		nft_filters: { ... this.defaultNftFilter },
		navbarState: null,
		connections_view_mode: 'table',
	}
	filterViewMode = 2 // 1 = all, 2 = common, 3 = variable, 4 = type, 5 = mathematical

	loadingPortfolioHistoryPageStates = false
	calculationInProgressModal = 0

	refreshTransactionsEmitter: EventEmitter<true> = new EventEmitter();

	appSettingsStateChange: EventEmitter<true> = new EventEmitter();
	appSettingsStateChangeSubscriber = null

	userPortfolioSettingsStateChange: EventEmitter<true> = new EventEmitter();
	userPortfolioSettingsStateChangeSubscriber = null

	chartOfAccountMainDefaults = null;

	allLabelledAddressInfo = []
	allLabelledAddressInfolLoading = false

	public portfolioSettingsMessages = {
		autoTransferCostBasis: 'Automatically match the cost basis values for Trades and DeFi swap transactions. Only swaps with two line items are detected. Transactions such as Liquidity Release which involves one send item and two receives are not automatically detected.',
	}

	public portfolioTransactionLocks = []

	public portfolioFasbDates = []

	// workspace settings
	public currentPortfolioSettings = {
		calculationMode: 1
	}
	nftReviewMode = 1

	// view modes
	public DeFiViewMode = 0
	public quickFiltersConnectionsMode = 1
	public showImportedERPCoAs = false
	public classificationsCount = -1

	public deFiSyncEmmiter: EventEmitter<any> = new EventEmitter();
	public balanceSnpaShotstatus: EventEmitter<any> = new EventEmitter();
	public reconcilationReportstatus: EventEmitter<any> = new EventEmitter();
	public balanceRangeReportstatus: EventEmitter<any> = new EventEmitter();
	public gatewasyLoadingEmitter: EventEmitter<true> = new EventEmitter();
	public sanityCheckReportEmitter: EventEmitter<true> = new EventEmitter();
	public historicalBalanceReportEmitter: EventEmitter<true> = new EventEmitter();
	public summaryReportEmitter: EventEmitter<true> = new EventEmitter();
	public classificationSummaryReportEmitter: EventEmitter<true> = new EventEmitter();
	public runningBalanceEmitter: EventEmitter<true> = new EventEmitter();
	public gatewaySyncStatusEmitter: EventEmitter<any> = new EventEmitter();

	public nftResolveEmitter: EventEmitter<true> = new EventEmitter();

	@Output() UGLCalculationEmitter: EventEmitter<true> = new EventEmitter();

	public addressGroupSelectedEmitter: EventEmitter<any> = new EventEmitter();

	selectedPivotTable = -1
	public pinnedViews = [];

	public newPortfolio = false;

	public socketConnectinStatus = null
	public portfolioSettings = 0


	public id = 0

	public taxCalculationSubscription: any = null
	public uglCalculationSubscription: any = null
	public rolledTxnsModalSubscription: any = null

	@Output() RolledTxnSourceModalEmitter: EventEmitter<true> = new EventEmitter();
	@Output() taxCalculationSubscriptionGrab: EventEmitter<any> = new EventEmitter();

	public connectionSyncEmmiter: EventEmitter<any> = new EventEmitter();
	public rollUpEmiiter: EventEmitter<any> = new EventEmitter();
	public heartbeatEmitter: EventEmitter<any> = new EventEmitter();
	public vendorSyncEmiiter: EventEmitter<any> = new EventEmitter();
	public invoiceSyncEmitter: EventEmitter<any> = new EventEmitter();
	public erpMetaDataSyncEmitter: EventEmitter<any> = new EventEmitter();
	public gatewaySyncPTREmitter: EventEmitter<any> = new EventEmitter();

	public loadingExchangesConnected = false
	public globalLiveWalletSync = false
	public quickSearchWalletsBackup = []
	public quickSearchVirtualWalletsBackup = []
	public quickSearchExchangesBackup = []

	public notificationCleanerInterval = false
	gettingAppData = 0
	appData = null


	@Output() APIHandshakeEmmitter: EventEmitter<any> = new EventEmitter();

	public hb_packagePurcahseDetails = null

	public modalRefTutorial = null;

	public topCoinsLoading = false
	public topCoins = []

	public syncedIgnoredAssets = false


	public syncedAssetPegs = false

	public gettingApplicableAssets = false

	public isMenuOpen = false;
	public contentMargin = 240;

	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;

	public isdropdownOpen = 0

	public addressTooltipText = 'Copy Address'

	public currentPortfolioViewStateMain = null

	lastLoaderState = -1

	public issuesPageMode = 0
	public taxLotPageMode = 0
	public exchangePageMode = 0
	public walletPageMode = 1
	public walletPageViewMode = 0
	public coinInfoPageMode = 0
	public NFTInfoPageMode = 0
	public loadingMode = false

	public sanityCheckViewMode = 1
	public dashboardViewMode = 1
  public assetsExpandViewMode = 1


	public currentExchangeView = null
	public currentExchangeBalanceList = null
	public currentExchangeViewId = null
	public currentCoinViewId = null
	public currentWalletViewId = null
	public currentNFTViewId = null
	public currentWalletMode = null
	public currentSAFTViewId = null

	public lastPortfolioViewState = null

	public currentTaxLot = null
	public processedTransactions = []
	public _sideBarData = null
	public currentTaxLotId = null

	loadingAccount = false

	public portfolioUsers = null
	loadingDashboardSummary = false

	loadingDashboardARAPSummary = false


	public issueTransactions = [];
	public selectedTxFull = []

	public createIssueMode = 0

	public createIssueModeValdiation = 0
	public showIssueData = 0
	public _issues = []
	public _issuesCount = null
	public issuesLoadMore = true
	public issuesLoading = false
	public issuesMode = 0
	public currentViewIssue = null
	public currentViewIssueLoading = false

	public issueComment = null
	public issueCommentAddStatus = false
	public issueCommentDeletetatus = false
	public noReload = false

	readonly issuePrioritiesIds = [
		1,
		2,
		3,
		4
	]

	readonly issuePriorities = [
		'Low',
		'Medium',
		'High',
		'Critical'
	]

	public modalRef: any

	public issueResolveStatus = false

	public highlightedTransactions = [];
	public showlogContainer = []
	public showlogList: { [k: string]: any } = {};
	public showLogLoadingIndex = []

	public currentTransactionDetails;


	public sourceTransactions = null
	public sourceTransactionsCount = 0
	public findSourceTxStatus = 0
	public modalRefTwo;
	public formState = false;
	public noOriginalTxFound = false;

	public unrollLoading = false
	public unrollMessage = 0

	public availableReportTypes = []
	loaindgReportType = false

	invoiceModal = null
	public CAPCommerceState = 0

	gatewaysLoadEvent: EventEmitter<true> = new EventEmitter();
	private gatewayConnectionCache = [];
	private cachedObservableGateway: Observable<any>;

	public navigation = []

	public connectionGroups = []

	public addresses = null
	public searchResultsAddress = null
	public addressGroups = []
	public addressBookTab = 0
	public addressLoading = false
	public addressGroupLoading = false
	public activeAddressGroup = false
	public addressBookListViewExpanded = true
	public addressDisplayCount = null

	specificPortfolioSettingLocation = 0

	description = null


	changingLayout = false

	public exportCBHistory = null
	public reportHistoryShowLoadMore = true

	showLoaderStatus = false
	trueshowLoaderStatus = false

	loadingPins = false
	currentViewPin: SafeResourceUrl
	currentViewVanilla = null
	currentViewPinInfo = null
	showPinIFrame = false

	componentLoading = false


	tutorialSteps = [
		{
			id: 0,
			title: 'Getting Started'

		},
		{
			id: 1,
			title: 'Setup Users'

		},
		{
			id: 2,
			title: 'Create a Workspace'

		},
		{
			id: 3,
			title: 'Connect Data Sources'

		},
		{
			id: 4,
			title: 'General Ledger Software'

		},
		{
			id: 5,
			title: 'Setup Classifications'

		},
		{
			id: 6,
			title: 'Running Calculations'

		},
		{
			id: 7,
			title: 'Generate Reports'

		},
		{
			id: 8,
			title: 'Final Notes'

		}
	]


	// connections page integrate to Tx page filter

	// wallets
	allConnectionWalletsSelected = false;




	// Accounts
	allConnectionAccountsSelected = false;


	// fireblocks vaults
	public connectedVaults = [];
	public sync_specific_vaults_mode = null;
	public excludeAllExchanges = null;
	public includeExclude = 'include';	// include or exclude
	public loadingVaults = false

	public updateExportTxColums(cols) {
		this.userPortfolioSettings.export_tx_columns = cols;
	}

	public updateCalcColums(cols) {
		this.userPortfolioSettings.export_calc_model_columns = cols;
	}

	initSocket(): void {
		if (this.socket == null) {
			if (window.location.host == 'am.cryptoworth.app' || window.location.host == 'cryptoworth.com' || window.location.host == 'one.cryptoworth.app') {
				this.SOCKET_SERVER_URL = 'https://notifications.cryptoworth.app/'
				this.connectSocket()
			} else if (window.location.host == 'staging.cryptoworth.app' || window.location.host == 'staging.interplay.network') {
				this.SOCKET_SERVER_URL = 'https://stagingnotifications.interplay.network/'
				this.connectSocket()
			} else if (window.location.host == 'staging-ca.interplay.network') {
				this.SOCKET_SERVER_URL = 'https://stagingnotifications.interplay.network/'
				this.connectSocket()
			} else {
				this.SOCKET_SERVER_URL = 'http://localhost:3000/'
				this.connectSocket()
			}

		}
	}


	connectSocket() {
		this.socketConnectinStatus = 1
		// console.log('initiating socket')
		this.socket = socketIo(this.SOCKET_SERVER_URL, {
			query: {
				userId: this._userDetails['id']
			}
		})

		this.socket.on('connect', (msg: any) => {
			console.log('Connected to WS server');
			this.socketConnectinStatus = 2

		});

		this.socket.on('disconnect', (msg: any) => {
			console.log('Disconnected Connected to WS server');
			this.socketConnectinStatus = 3


		});

		// this.socket.emit(this.socketChannels[0])

		this.socket.on('event', function (data) {
			console.log('EVENT', data)
		})

		this.socket.on('notification-messages', (msg: any) => {
			this.pushNotificationsInsert(msg);
		});


		this.socket.on('broadcast-messages', (msg: any) => {
			this.globalBroadcastHandling(msg);
		});

	}




	pushNotificationsInsert(data) {
		console.log('pushNotificationsInsert', data)
		if (this.currentPortfolioId == 0 || data['portfolioId'] == this.currentPortfolioId) {
			if (this.currentPortfolioId == 0) {
				this.portfolios.forEach(element => {
					if (data['portfolioId'] == element.id) {
						data['portfolioName'] = element.name
						this.postProcessPushNotifications(data, element, false)
					}
				});
			} else {

				if (data['family'] == 'syncs') {



					if (data['source'] == 'exchange') {

						// this._exchangesConnected.forEach(element => {
						// 	if(element.exchange_connection_id == data['sourceId']){
						// 		element.status = data['status']
						// 		this.postProcessPushNotifications(data, element)
						// 	}
						// })

					} else if (data['source'] == 'wallet') {

						// this._wallets.forEach(element => {
						// 	if(element.id == data['sourceId']){
						// 		element.sync_status = data['sync_status']
						// 		this.postProcessPushNotifications(data, element)
						// 	}
						// });
					}

					this.connectionSyncEmmiter.emit(data)

				} else if (data['family'] === 'disconnect') {



					if (data['source'] == 'exchange') {

						if (this._exchangesConnected !== null) {
							const exchanges = this._exchangesConnected.map((val) => val);

							exchanges.forEach(element => {
								if (element.exchange_connection_source_id == data['sourceId']) {
									console.log('exchange push')
									element.status = data['status']
									this.postProcessPushNotifications(data, element)
								}
							})
						}


					} else if (data['source'] == 'wallet') {
						const wallets = this._wallets.map((val) => val);
						console.log(wallets);
						console.log(data);
						wallets.forEach(element => {
							if (element.id == data['sourceId']) {
								console.log('wallet push')
								element.sync_status = data['status']
								this.postProcessPushNotifications(data, element)
							}
						});
					} else if (data['source'] == 'virtual_wallet') {
						const virtualWallets = this._virtualWallets.map((val) => val);
						// console.log(virtualWallets);
						// console.log(data);

						virtualWallets.forEach(element => {
							if (element.id == data['sourceId']) {
								// console.log("virtual push")
								element.sync_status = data['status']
								this.postProcessPushNotifications(data, element)
							}
						});
					}

					this.connectionSyncEmmiter.emit(data)

				} else if (data['family'] == 'imports') {
					if (data['source'] == 'virtual_wallet') {

						this._virtualWallets.forEach(element => {
							if (element.id == data['sourceId']) {
								// console.log('pushed')
								this.postProcessPushNotifications(data, element)
							}
						});
					}
					if (data['source'] == 'exchange') {
						this._exchangesConnected.forEach(element => {
							// console.log('looping element')
							// console.log(element)
							if (element.exchange_connection_source_id == data['sourceId']) {
								// console.log('pushed')
								this.postProcessPushNotifications(data, element)
							}
						});
					}

					// get issues
					this.getPortfolioIssues()

				} else if (data['family'] == 'import_details') {
					if (data['source'] == 'virtual_wallet') {
						this._virtualWallets.forEach(element => {
							if (element.id == data['sourceId']) {
								// console.log('pushed')
								this.postProcessPushNotifications(data, element)
							}
						});
					}
				} else if (data['family'] == 'tax_calculations') {

					if (data['source'] == 'portfolio') {

						const emitterObject = {
							status: data['status']
						}
						this.taxCalculationSubscriptionGrab.emit(emitterObject)

						if (data['status'] == 4 && data['data'] != null) {
							if (data['data']['count'] > 0) {
								this.currentPortfolioTaxCalProcessCount = data['data']['count']
								// console.log('count: ' + data['data']['count'])
								// console.log('fetch time: ' + data['data']['fetchTimeDiffInMins'])
								// console.log('calc time: ' + data['data']['fetchCalcDiffInMins'])
								// console.log('carryover time: ' + data['data']['fetchCarryOverDiffInMins'])
								// console.log('==========================')
							}
						} else {
							this.postProcessPushNotifications(data, null)
						}

					}
				} else if (data['family'] == 'gateways') {

					if (data['source'] == 'tax_calculations') {
						if (data.data['gateway_id'] == 1) {

							let status = data['status'];
							const canceled = data.data['canceled'];

							this.latestSyncobj.qb.status = data['status'];
							if (canceled === 1) {
								this.latestSyncobj.qb.status = 9;
							}
							if (data.data['log']) {
								this.latestSyncobj.qb['log'] = data.data['log'];
							}

							if (data.data['log_no']) {
								this.latestSyncobj.qb['log_no'] = data.data['log_no'];
							}

							if (data.data['type'] === 2) {
								status += 4;
							}

							if (data.data['mode'] == 1) {

								this.gatewaySyncStatus.qb = status;
								if (canceled === 1) {
									this.gatewaySyncStatus.qb = 9;
								}

							} else if (data.data['mode'] == 2) {

								this.gatewayVirtualSyncStatus.qb = status;
								if (canceled === 1) {
									this.gatewayVirtualSyncStatus.qb = 9;
								}
							} else if (data.data['mode'] == 3) {

								this.gatewayUGLSyncStatus.qb = status;
								if (canceled === 1) {
									this.gatewayUGLSyncStatus.qb = 9;
								}
							}

							console.log(this.gatewaySyncStatus.qb);
						} else if (data.data['gateway_id'] == 2) {

							let status = data['status'];
							const canceled = data.data['canceled'];

							this.latestSyncobj.xero.status = data['status'];
							if (canceled === 1) {
								this.latestSyncobj.xero.status = 9;
							}

							if (data.data['log']) {
								this.latestSyncobj.xero['log'] = data.data['log'];
							}

							if (data.data['log_no']) {
								this.latestSyncobj.xero['log_no'] = data.data['log_no'];
							}

							if (data.data['type'] === 2) {
								status += 4;
							}

							if (data.data['mode'] == 1) {
								this.gatewaySyncStatus.xero = status;
								if (canceled === 1) {
									this.gatewaySyncStatus.xero = 9;
								}
							} else if (data.data['mode'] == 2) {

								this.gatewayVirtualSyncStatus.xero = status;

								if (canceled === 1) {
									this.gatewayVirtualSyncStatus.xero = 9;
								}
							} else if (data.data['mode'] == 3) {

								this.gatewayUGLSyncStatus.xero = status;

								if (canceled === 1) {
									this.gatewayUGLSyncStatus.xero = 9;
								}
							}

						} else if (data.data['gateway_id'] == 3) {

							let status = data['status'];
							const canceled = data.data['canceled'];

							this.latestSyncobj.ons.status = data['status'];

							if (canceled === 1) {
								this.latestSyncobj.ons.status = 9;
							}


							if (data.data['log']) {
								this.latestSyncobj.ons['log'] = data.data['log'];
							}

							if (data.data['log_no']) {
								this.latestSyncobj.ons['log_no'] = data.data['log_no'];
							}

							if (data.data['type'] === 2) {
								status += 4;
							}

							if (data.data['mode'] == 1) {
								this.gatewaySyncStatus.netSuite = status;
								if (canceled === 1) {
									this.gatewaySyncStatus.netSuite = 9;
								}
							} else if (data.data['mode'] == 2) {
								this.gatewayVirtualSyncStatus.netSuite = status;
								if (canceled === 1) {
									this.gatewayVirtualSyncStatus.netSuite = 9;
								}
							} else if (data.data['mode'] == 3) {
								this.gatewayUGLSyncStatus.netSuite = status;
								if (canceled === 1) {
									this.gatewayUGLSyncStatus.netSuite = 9;
								}
							}


						} else if (data.data['gateway_id'] == 4) {

							const status = data['status'];
							this.requestSyncStatus = status

							if (status == 1) {
								this.requestSyncLast = 'Pending'
							} else if (status == 2) {
								this.requestSyncLast = data.data['date']
							} else {
								this.requestSyncLast = 'Failed'
							}


						} else if (data.data['gateway_id'] == 6) {

							let status = data['status'];
							const canceled = data.data['canceled'];

							this.latestSyncobj.virtual.status = data['status'];

							if (canceled === 1) {
								this.latestSyncobj.virtual.status = 9;
							}


							if (data.data['log']) {
								this.latestSyncobj.virtual['log'] = data.data['log'];
							}

							if (data.data['log_no']) {
								this.latestSyncobj.virtual['log_no'] = data.data['log_no'];
							}

							if (data.data['type'] === 2) {
								status += 4;
							}

							if (data.data['mode'] == 1) {
								this.gatewaySyncStatus.virtual = status;
								if (canceled === 1) {
									this.gatewaySyncStatus.virtual = 9;
								}
							} else if (data.data['mode'] == 2) {
								this.gatewayVirtualSyncStatus.virtual = status;
								if (canceled === 1) {
									this.gatewayVirtualSyncStatus.virtual = 9;
								}
							} else if (data.data['mode'] == 3) {
								this.gatewayUGLSyncStatus.virtual = status;
								if (canceled === 1) {
									this.gatewayUGLSyncStatus.virtual = 9;
								}
							}
						} else if (data.data['gateway_id'] == 8) {

							let status = data['status'];
							const canceled = data.data['canceled'];

							this.latestSyncobj.pennylane.status = data['status'];

							if (canceled === 1) {
								this.latestSyncobj.pennylane.status = 9;
							}


							if (data.data['log']) {
								this.latestSyncobj.pennylane['log'] = data.data['log'];
							}

							if (data.data['log_no']) {
								this.latestSyncobj.pennylane['log_no'] = data.data['log_no'];
							}

							if (data.data['type'] === 2) {
								status += 4;
							}

							if (data.data['mode'] == 1) {
								this.gatewaySyncStatus.pennylane = status;
								if (canceled === 1) {
									this.gatewaySyncStatus.pennylane = 9;
								}
							} else if (data.data['mode'] == 2) {
								this.gatewayVirtualSyncStatus.pennylane = status;
								if (canceled === 1) {
									this.gatewayVirtualSyncStatus.pennylane = 9;
								}
							} else if (data.data['mode'] == 3) {
								this.gatewayUGLSyncStatus.pennylane = status;
								if (canceled === 1) {
									this.gatewayUGLSyncStatus.pennylane = 9;
								}
							}
						}

						this.gatewaySyncStatusEmitter.emit(data);
						this.postProcessPushNotifications(data, null);
					}

				} else if (data['family'] == 'erp_meta_data_sync') {
					this.erpMetaDataSyncEmitter.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'vendors') {
					this.vendorSyncEmiiter.emit(data);
					this.postProcessPushNotifications(data, null);


				} else if (data['family'] == 'invoices') {
					this.invoiceSyncEmitter.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'invoices') {
					this.invoiceSyncEmitter.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'rollup') {
					if (data['portfolioId'] == this.currentPortfolioId) {
						this.rollUpEmiiter.emit(data)
						// this.postProcessPushNotifications(data, null);
					}
				} else if (data['family'] == 'ugl_calculation') {
					if (data['portfolioId'] == this.currentPortfolioId) {
						this.UGLCalculationEmitter.emit(data)
						// this.postProcessPushNotifications(data, null);
					}
				} else if (data['family'] == 'defi_sync') {
					// console.log(data);
					// emit an event ;
					if (data['portfolioId'] == this.currentPortfolioId) {
						this.deFiSyncEmmiter.emit(data)
					}

				} else if (data['family'] == 'balance_snapshotting') {
					this.balanceSnpaShotstatus.emit(data);

				} else if (data['family'] == 'reconciliation_report') {
					this.reconcilationReportstatus.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'balance_range_report') {
					this.balanceRangeReportstatus.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'sanity_check') {
					this.sanityCheckReportEmitter.emit(data);
					this.postProcessPushNotifications(data, null);
				} else if (data['family'] == 'historical_balance_report') {
					this.historicalBalanceReportEmitter.emit(data);
					this.postProcessPushNotifications(data, null);
				} else if (data['family'] == 'summary_report') {

					this.summaryReportEmitter.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'nft_resolve') {
					const status = data['status'];

					if (status == 2) {
						this.nftResolveEmitter.emit(data);
					}
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'classification_summary_report') {

					this.classificationSummaryReportEmitter.emit(data);
					this.postProcessPushNotifications(data, null);

				} else if (data['family'] == 'portfolio') {
					if (data['source'] == 'portfolio') {
						this.nftSyncStatus = data['status']
					}
					this.postProcessPushNotifications(data, null);
				} else if (data['family'] == 'running_balance') {
					this.runningBalanceEmitter.emit(data);
					this.postProcessPushNotifications(data, null);
				} else if (data['family'] == 'gateway_sync_ptr') {
					this.gatewaySyncPTREmitter.emit(data);
				} else {
					this.postProcessPushNotifications(data, null)
				}

			}

		} else {

		}
	}



	globalBroadcastHandling(data) {

		if (data['family'] == 'server_status') {
			// console.log(data['serverStatus'])
			this.serverInfo['status'] = data['data']['serverStatus']
		}

		if (data['family'] == 'deployments') {
			if (this._notificationsPopups.length > 0) {
				this._notificationsPopups = []
			}
			this._notificationsPopups.push(data)
		}

	}

	postProcessPushNotifications(data, element, trueData = true) {
		// console.log(data)
		// console.log(element)
		const newTimeObj = (Math.floor(new Date().valueOf() / 1000));

		data['time'] = newTimeObj + 5
		data['sourceDetails'] = element
		data['trueData'] = trueData

		// console.log('source details')

		if (this._notificationsPopups.length > 1) {
			// this._notificationsPopups = []
			return
		}


		this._notificationsPopups.push(data)

		if (data['family'] != 'deployments') {
			this.autoHideNotifications()
		}
	}

	autoHideNotifications() {

		if (!this.notificationCleanerInterval) {

			this.notificationCleanerInterval = true

			const intervalWatcher = setInterval(() => {
				// console.log('interval called')
				this._notificationsPopups.splice(0, 1);

				if (this._notificationsPopups.length == 0) {
					clearInterval(intervalWatcher)
					this.notificationCleanerInterval = false
				}

			}, 7000);
		}

	}

	autoHideNotificationsForce(index) {
		this._notificationsPopups.splice(index, 1);
	}

	detectAdblock() {
		// this.http.get('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js').pipe(map(res =>  res.json())).subscribe(
		// (response) => {
		// 	// console.log('addblock:off')
		// }, error => {
		// 	// console.log(error);// Error getting the data
		// 	console.log('addblock:on')
		// })
	}

	detectbrowser() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		this.deviceInfo['isMobile'] = this.deviceService.isMobile();
		this.deviceInfo['isTablet'] = this.deviceService.isTablet();
		this.deviceInfo['isDesktopDevice'] = this.deviceService.isDesktop();
	}

	ipLocationDetect() {
		this.http.get('https://extreme-ip-lookup.com/json/67.218.223.54').pipe(map(res => res.json())).subscribe(
			(response) => {
				this.ipInfo = response
			})
	}

	getCryptoWorldMarket() {
		// this.httpAPIGET('https://api.coinmarketcap.com/v1/global/?convert=USD')
		// .subscribe(response => {

		// 	this.cryptoWorldMarket = response

		// }, error => {
		// 	console.log(error);// Error getting the data
		// });

	}

	isAuthenticated(): boolean {
		if (this._authStatus != false) {
			if (this.authService.TOKENS == null) {
				if (this._cookieService.check('tokenData')) {
					this.authService.TOKENS = JSON.parse(this._cookieService.get('tokenData'))
				}
			}

			if (this._userDetails == null) {
				if (this._cookieService.check('userDetails')) {
					this._userDetails = JSON.parse(this._cookieService.get('userDetails'));
					this._appMode = this._userDetails['app_mode']
				}
			}
			if (this._currency == null) {
				if (this._cookieService.check('globalCurrency')) {
					this._currency = JSON.parse(this._cookieService.get('globalCurrency'));
				}
			}


			if (this.authService.TOKENS != null) {
				this._authStatus = true
				if (this._HS_STATUS == false) {
					this.APIHandShake()
				}
				this.initSocket();

				try {
					this.ngxPendoService.initialize({
						id: this._userDetails['id'],
						name: this._userDetails['first_name'] + ' ' + this._userDetails['last_name'],
						email: this._userDetails['email'],
						role: 'user'
					}, {
						id: '4814941645766656',
						name: 'Cryptoworth'
					});
				} catch (error) {
					console.error('Pendo service initialization failed:', error);
				}
				return true
			} else {
				return false
			}
		} else {
			return false;
		}
	}

	applicationHardRefresh() {
		this.httpGET('api_handshake').subscribe(response => {
			if (response.status == true) {
				this.isAuthenticated()
				this.router.navigate(['/']);
			}
		}, error => {
			this.logout()
		});
	}
	getAppData() {
		this.gettingAppData = 1
		this.httpAPIGET(this.API_URL + 'app_data').subscribe(response => {
			this.appData = response.data
			this.gettingAppData = 0

		}, error => {
			this.gettingAppData = -1
		});
	}

	checkServerStatus() {
		this.servercheckStatus = 0
		this.httpAPIGET(this.API_URL + 'server_handshake').subscribe(response => {
			this.serverInfo = response.data
			this.imageVer = this.serverInfo.version
			this.servercheckStatus = 1

		}, error => {
			this.servercheckStatus = 2
		});
	}

	APIHandShake(force = false) {
		// console.log('initiating API handshake')
		if (this.APIHandShakeStatus == false || force) {
			this.httpGET('api_handshake').subscribe(response => {
				if (response.status == true) {
					this._HS_STATUS = true

					this.setPackageDetails(response['data']['packageInfo'])
					this._privileges = response['data']['privileges']
					this.heartBeat()
					this.APIHandshakeEmmitter.emit(true)
					return (response.status)
				}
			}, error => {
				// console.log('handshake error')
				// console.log(error)
				this.logout()
			});

			this.APIHandShakeStatus = true
		}

	}

	heartBeat() {
		// trigger one
		// gets user specific things
		this.doHeartBeat()

		// gets global things
		this.getLabelledDeFiProtocols()
	}

	setDefaultTxView() {
		if (this.appSettings.txtable_columns == null) {
			this.appSettings.txtable_columns = this.txSettingsDefaultView
		}
	}
	doHeartBeat() {
		this.httpGET('api_heartbeat').subscribe(response => {
			if (response.status == true) {

				// console.log('/////////////////////')
				// console.log(response['data']['userDetails'])
				// get user details current in the variables and cookies and update it
				this._userDetails['tutorial'] = response['data']['userDetails']['tutorial']
				this._userDetails['status'] = response['data']['userDetails']['status']
				this._userDetails['timezone'] = response['data']['userDetails']['timezone']
				this._userDetails['email_confirmed'] = response['data']['userDetails']['email_confirmed']
				this._userDetails['onboarding_stage'] = response['data']['userDetails']['onboarding_stage']
				this._userDetails['last_annc_id'] = response['data']['userDetails']['last_annc_id']
				this.currentOnboardingStep = response['data']['userDetails']['onboarding_stage']
				this.workflowTypes = response['data']['workflowTypes']

				this._cookieService.set('userDetails', JSON.stringify(this._userDetails), null, '/')

				if (response['data']['userSettings'] != null) {
					this.appSettings = { ...this.appSettings, ...response['data']['userSettings'] };

					if (this.appSettings.txtable_columns) {
						this.appSettings.txtable_columns = { ...this.txSettingsDefaultView, ...response['data']['userSettings']['txtable_columns'] }
					}

					if (this.appSettings.txtable_size) {
						this.appSettings.txtable_size = response['data']['userSettings']['txtable_size'];

					}
					this.layoutMode = 1;//response['data']['userSettings']['layout_mode']
					this._userDetails['layout_mode'] = 1; //this.layoutMode
					this._userDetails['show_qs_bottm_bar'] = response['data']['userSettings']['show_qs_bottm_bar']
					this._userDetails['show_gl_status'] = response['data']['userSettings']['show_gl_status']
					this._userDetails['show_ext_portfolio_tier'] = response['data']['userSettings']['show_ext_portfolio_tier']

					this.setDefaultTxView()
				} else {
					// set default mode for backwards compatibility
					this.setDefaultTxView()
				}

				if (response['data']['exportCalcColums'] != null) {
					this.appSettings.export_calc_colums = response['data']['exportCalcColums'];
				}

				if (response['data']['exportTxColums'] != null) {
					this.appSettings.export_tx_colums = response['data']['exportTxColums'];
				}

				if (response['data']['currentPackageDetails'] != null) {
					this.hb_packagePurcahseDetails = response['data']['currentPackageDetails']['packagePurchase']
					this._packageDetails = response['data']['currentPackageDetails']['packageDetails']
					this._packagePolicies = response['data']['currentPackageDetails']['packageDetails']['policies']
					this._companyDetails = response['data']['currentPackageDetails']['companyDetails']
					// console.log(response)
				}


				if (this._userDetails['status'] == -1) {
					this.logout()
				}
			}

			this.heartbeatEmitter.emit(true);
		}, error => {
			// console.log('hearbeat error')
			// console.log(error)
			this.logout()
		});
	}

	getTopNews() {
		this.httpAPIGET(this.API_URL + 'top_news').subscribe(response => {
			if (response.resp_status == true) {
				this.currentViewNewsList = response.data

			} else {
			}
		}, error => {
		});
	}

	getTopCoins() {
		this.topCoinsLoading = true
		this.httpAPIGET('https://min-api.cryptocompare.com/data/top/mktcapfull?limit=10&tsym=USD').subscribe(response => {
			this.topCoinsLoading = false
			this.topCoins = response.Data
		})
	}

	getLatestNews() {
		this.httpAPIGET(this.API_URL + 'latest_news').subscribe(response => {
			if (response.resp_status == true) {
				this.currentViewNewsList = response.data

			} else {
			}
		}, error => {
		});
	}

	canActivate(): boolean {
		if (!this.isAuthenticated()) {
			this.logout()
			return false;
		}
		return true;
	}

	canConnectExchanges() {
		if (this._portfolioInfo.company_id == 0 || (this._portfolioInfo.company_id != 0 && this._privileges['companyAdmin'] && this._portfolioInfo.external != 1)) {
			return true;
		} else {
			return false;
		}
	}

	canEditPortfolioConnections() {
		if (this._portfolioInfo.company_id == 0 || (this._portfolioInfo.company_id != 0 && this._privileges['companyAdmin'] && this._portfolioInfo.external != 1)) {
			return true;
		} else if (this._portfolioInfo.company_id == 0 || (this._portfolioInfo.company_id != 0 && this._privileges['modifyCurrentPortfolio'])) {
			return true
		} else {
			return false;
		}
	}

	canEditCurrentPortfolio() {
		if (this._portfolioInfo.company_id == 0 || (this._portfolioInfo.company_id != 0 && this._privileges['modifyCurrentPortfolio']) || (this._portfolioInfo.company_id != 0 && this._privileges['companyAdmin'] && this._portfolioInfo.external != 1)) {
			return true;
		} else {
			return false;
		}
	}

	canAddPortfolioUsers() {
		if ((this._privileges['companyAdmin']) && this._portfolioInfo.external == 0) {
			return true;
		} else {
			return false;
		}
	}

	canAddTeamMembers() {
		if ((this._privileges['companyAdmin']) && this._portfolioInfo.external == 1) {
			return true;
		} else {
			return false;
		}
	}

	canAccessAppPayments() {
		if (
			this._packagePurchaseDetails['app_commerce'] == 1 ||
			(this._portfolioInfo != null && this._portfolioInfo.external == 1 && this._portfolioInfo.appPaymentsEnabled)
		) {
			return true;
		} else {
			return false;
		}
	}

	isCompanyAdmin() {
		if ((this._privileges['companyAdmin'])) {
			return true;
		} else {
			return false;
		}
	}

	tempCookieSetForOAuth2ExchangeCons() {
		this._cookieService.set('tokenData', JSON.stringify(this.authService.TOKENS), null, '/')
		this._cookieService.set('userDetails', JSON.stringify(this._userDetails), null, '/')
		this._cookieService.set('globalCurrency', JSON.stringify(this._currency), null, '/')


		this._cookieService.set('companyDetails', JSON.stringify(this._companyDetails), null, '/')
		this._cookieService.set('packageDetails', JSON.stringify(this._packageDetails), null, '/')
		this._cookieService.set('packagePolicies', JSON.stringify(this._packagePolicies['policies']), null, '/')
		this._cookieService.set('packagePurchaseDetails', JSON.stringify(this._packagePurchaseDetails), null, '/')
	}

	cookieUnsetForOAuth2ExchangeCons() {
		this._cookieService.deleteAll('/')
	}

	doLoginPreRequisites(loginResponse = null) {
		// history.replaceState('', '', '/')
		this._authStatus = true

		if (loginResponse != null) {
			if (this.keepUserLoggedIn) {
				this._cookieService.set('tokenData', JSON.stringify(loginResponse['tokenData']), null, '/')
				this._cookieService.set('userDetails', JSON.stringify(loginResponse['userDetails']), null, '/')
				this._cookieService.set('globalCurrency', JSON.stringify(loginResponse['currency']), null, '/')
			}


			this.authService.TOKENS = loginResponse['tokenData']
			this._userDetails = loginResponse['userDetails']
			this._currency = loginResponse['currency']

			this.setPackageDetails(loginResponse['packageInfo'])
			this._PRODUCT_ID = loginResponse['packageInfo']['packageDetails'].product
			this._appMode = this._userDetails['app_mode']
			// console.log('version: ' + this._PRODUCT_ID)
		}

		this.APIHandShake(true);
		// console.log('app mode: ' + loginResponse)
		if (this._appMode == 1) {
			if (loginResponse !== null && loginResponse['userInfo'] && loginResponse['userInfo']['last_portfolio'] != 0) {
				this.moveToPortfolioForce()
			} else {
				this.router.navigate(['workspaces'], { skipLocationChange: false });
			}
		} else {
			this.moveToPortfolioForce()
		}
	}

	saveLoginTemp() {
		this._cookieService.set('tokenData', JSON.stringify(this.authService.TOKENS), null, '/')
		this._cookieService.set('userDetails', JSON.stringify(this._userDetails), null, '/')
		this._cookieService.set('globalCurrency', JSON.stringify(this._currency), null, '/')
	}

	portfolioInit() {
		console.log('init')
		this.portfolioUTCOffset = this.getUTCOffset()
		this.getGlobalApplicableAssets()
		this.getAllAddresses()
		this.getPortfolioIssues(true)
		this.getPortfolioIgnoredAssetsTxns()
		this.getPortfolioPinnedViews()
		this.checkGatewayConnections()
			.subscribe(response => {
				this.connectedGatewaysLoading = false
				this.connectedGateways = response.data;
				this.gatewasyLoadingEmitter.emit(response.data);
			}, error => {
				this.connectedGatewaysLoading = false;
				const data = { 'error': true, obj: error };
				this.gatewasyLoadingEmitter.emit(true);
			});
		this.hideLoader()

		this.getConnectionGroups(true)
	}
	getPortfolioIgnoredAssets() { // This is for the settings + dashboard
		this.httpGET('ignored_assets', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			if (response.status == true) {
				this._portfolioInfo['ignored_assets'] = response.data

				this.syncedIgnoredAssets = true
			}

		}, error => {
			this.syncedIgnoredAssets = false
		});
	}

	getPortfolioIgnoredAssetsTxns() { // This is for parsing transactions and finding assets that are is_ignored==1.
		this.httpGET('get_assets_ignored_txns', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			if (response.status == true) {
				this._ignoredTxnAssets = response.data
			}
		}, error => {
		});
	}
	getPortfolioAssetPegs() {
		this.httpGET('asset_peg', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			if (response.status == true) {
				this._portfolioInfo['assetPegs'] = response.data

				this.syncedAssetPegs = true
			}

		}, error => {
			this.syncedAssetPegs = false
		});
	}

	assetPegById(id) {
		let pegToRetun = null
		this._portfolioInfo['assetPegs'].forEach(element => {
			if (element.id == id) {
				pegToRetun = element
			}
		});

		return pegToRetun
	}
	getGlobalApplicableAssets() {
		this.gettingApplicableAssets = true
		this.httpGET('all_assets', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			this.gettingApplicableAssets = false

			if (response.status == true) {
				this._allApplicableAssets = response.data
				// this._allApplicableAssetsUnique = response.data['uniqueSymbols']
			}

			// console.log(this._allApplicableAssetsUnique.length)

		}, error => {
			this.gettingApplicableAssets = false
		});

	}

	cryptoByIdApplicable(cryptoId) {
		if (this._allApplicableAssets == null) {
			this.getGlobalApplicableAssets()
		}
		const allAssets = this._allApplicableAssets;
		const assetIndex = allAssets.findIndex(element => element['id'] == cryptoId)

		console.log('Crypto found!')
		console.log(this._allApplicableAssets[assetIndex])

		return this._allApplicableAssets[assetIndex]
	}

	cryptoByIdAll(cryptoId) {
		if (cryptoId == null) { return null }

		if (this.allWalletTypes == null) {
			this.httpGET('wallet_types_all').subscribe(response => {
				if (response.status == true) {
					this.allWalletTypes = response.data
					const allAssets = this.allWalletTypes;
					const assetIndex = allAssets.findIndex(element => element['id'] == cryptoId)
					return this.allWalletTypes[assetIndex]
				}
			}, error => {
			});
		} else {
			const assetIndex = this.allWalletTypes.findIndex(element => element['id'] == cryptoId)
			return this.allWalletTypes[assetIndex]
		}
	}

	getAllCrypto() {

		if (this.allWalletTypes == null) {
			this.httpGET('wallet_types_all').subscribe(response => {
				if (response.status == true) {
					this.allWalletTypes = response.data
				}
			}, error => {
			});
		}

	}


	nftCollectionById(cryptoId) {
		if (cryptoId == null) { return null }

		if (this.allNFTCollections == null) {
			this.httpGET('portfolio_nft_collections').subscribe(response => {
				if (response.status == true) {
					this.allNFTCollections = response.data
				}
			}, error => {
			});
		}
		const allAssets = this.allNFTCollections;
		const assetIndex = allAssets.findIndex(element => element['id'] == cryptoId)

		return this.allNFTCollections[assetIndex]
	}


	convertToLocalTime(utcTime, format = null) {
		const timeReturn = null

		if (format == null) {
			return moment.utc(utcTime).utcOffset(this.portfolioUTCOffset).format(this.DATE_FORMAT);
		} else {
			return moment.utc(utcTime).utcOffset(this.portfolioUTCOffset).format(format);
		}
	}


	convertServerTimetoUserTime(datetime, format = 'YYY-MM-DD HH:mm:ss', convertFormat = this.DATE_FORMAT) {
		// console.log('input time' + datetime)
		const utcTime = moment(datetime, format, this.serverTz).utc().format('MM/DD/YYYY HH:mm')
		// console.log('input utcTime' + utcTime)
		const finalTime = moment.utc(utcTime).utcOffset(this._userDetails['timezone']).format(convertFormat);
		// console.log('input finalTime' + finalTime)
		return finalTime
	}

	convertServerTimetoPortfolioTime(datetime, format = 'YYYY-MM-DD HH:mm:ss', convertFormat = this.DATE_FORMAT) {

		datetime = datetime; // + this.serverTzOffset;
		format = format + 'Z';
		// console.log('================================')
		// console.log('format ' + format);
		// console.log('input time ' + datetime);
		// console.log('serverTz ' + this.serverTz);
		// console.log('portfolio timezone: ',this._portfolioInfo['timezone'])

		const utcTime = moment(datetime, format).utc().format('YYYY-MM-DD HH:mm:ss');
		// console.log('utcTime ' + utcTime);

		let finalTime = '-'

		finalTime = moment.utc(utcTime).utcOffset(this._portfolioInfo['timezone']).format(convertFormat);
		// console.log('finalTime ' + finalTime);


		// console.log('convert time ' + utcTime);
		// console.log('================================')
		return finalTime;
	}


	convertServerToLocal(timestamp) {

		if (timestamp == null) {
			return '-'
		}

		// get unix timestamp
		let check = moment(timestamp).valueOf();
		// console.log(check)

		// add +5 hours in miliseconds to convert server time to UTC
		check = check + this.SERVER_UTC_OFFSET_MS
		// console.log('afer: ' + check)

		// final timezone and format conversions
		const newMoment = moment.unix(check / 1000).format('L LT')
		const convertedfinalTime = moment.utc(moment.unix(check / 1000).format(this.DATE_FORMAT)).utcOffset(this.portfolioUTCOffset).format(this.DATE_FORMAT);
		// console.log(convertedfinalTime)

		return convertedfinalTime
	}

	getUTCOffset() {
		// console.log(this._portfolioInfo)
		let timezone = this._portfolioInfo['timezone']
		timezone = timezone.substring(1); // removes U of UTC
		timezone = timezone.substring(1); // removes T of TC
		timezone = timezone.substring(1); // removes C of C

		return timezone
	}

	moveToPortfolioForce() {
		const portfolio = this._userDetails['portfolio']
		this._cookieService.set('current_portfolioId', JSON.stringify(portfolio.id), null, '/')
		this.router.navigate(['workspace', portfolio.id, 'overview']);
		this._portfolioInfo = portfolio
		this._portfolio_currency = this._portfolioInfo.portfolio_currency
		this._portfolio_currency_secondary = this._portfolioInfo.portfolio_currency_secondary
	}


	setPackageDetails(packageInfo) {

		const companyDetails = packageInfo['companyDetails']
		const packageDetails = packageInfo['packageDetails']
		const packagePolicies = packageInfo['packageDetails']['policies']
		const packagePurchaseDetails = packageInfo['packagePurchase']

		this._isAdmin = packageInfo['isAdmin']
		this._isOwner = packageInfo['isOwner']
		this._PRODUCT_ID = packageDetails['product']

		if (companyDetails != null) {
			if (this.keepUserLoggedIn) {
				this._cookieService.set('companyDetails', JSON.stringify(companyDetails), null, '/')
			}
			this._companyDetails = companyDetails
		}

		if (packageDetails != null) {
			if (this.keepUserLoggedIn) {
				this._cookieService.set('packageDetails', JSON.stringify(packageDetails), null, '/')
			}
			this._packageDetails = packageDetails
		}

		if (packagePolicies != null) {
			if (this.keepUserLoggedIn) {
				this._cookieService.set('packagePolicies', JSON.stringify(packagePolicies['policies']), null, '/')
			}
			this._packagePolicies = packagePolicies
		}

		if (packagePurchaseDetails != null) {
			if (this.keepUserLoggedIn) {
				this._cookieService.set('packagePurchaseDetails', JSON.stringify(packagePurchaseDetails), null, '/')
			}
			this._packagePurchaseDetails = packagePurchaseDetails

			if (this._packagePurchaseDetails.app_commerce == 1) {
				this.app_commerceInit = true
			}

			if (this._packagePurchaseDetails.app_accounting == 1) {
				this.app_accountingInit = true
			}

			this._allowedEntities = this._packagePurchaseDetails.allowed_entities
		}


	}

	logout() {
		// console.log('logout at func')
		// console.log(this.logginOutStatus)
		if (this.socialUser !== undefined && this.socialUser !== null) {
			this.socialAuth.signOut();
		}
		if (this.logginOutStatus == 0) {
			this._appState = 2
			this.logginOutStatus = 1
			if (!this.isAuthenticated()) {
				// console.log('here 1')
				this._appState = 1
				this.logoutFinishStage()
			} else {
				// console.log('here 2')
				this.httpGET('logout').subscribe(response => {
					if (response.status == true) {
						this._appState = 1
						this.logoutFinishStage()
					}

				}, error => {
					// this.logout()
					this._appState = 1
				});
			}

		} else {
			// console.log('here 3')
			this.logoutFinishStage()
		}
	}

	logoutFinishStage() {
		this._authStatus = false

		this._cookieService.deleteAll('/')

		this.portfolios = null
		this._wallets = null
		this._exchangesConnected = null
		this._exchangesActual = []
		this._virtualWallets = null
		this._virtualWalletsActual = []
		this._walletsActual = []
		this._companyDetails = null
		this._transactionTags = null
		this._packageDetails = null
		this._packagePolicies = null
		this.hb_packagePurcahseDetails = null
		this.authService.TOKENS = null
		this._userDetails = null
		this.currentPortfolio = null
		this._allApplicableAssets = null
		this._allApplicableAssetsUnique = []
		this._currency = null
		this.router.navigate(['login']);
	}

	// intercepted API Call (see interceptors/cwae.interceptor)
	httpGET(path: string, getData = null): Observable<any> {
		if (this._authStatus || this._userDetails === null) {
			this.cookieAuthUpdate()
		}

		let params = new HttpParams();

		params = params.append('uId', this._userDetails['uId']);
		params = params.append('p', this._PRODUCT_ID);

		if (getData != null) {
			for (const key in getData) {
				// Begin assigning parameters
				params = params.append(key, getData[key]);
			}
		}

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.authService.TOKENS.access_token);

		if (this.currentPortfolioId != null) {
			headers = headers.append('PORTFOLIO-ID', this.currentPortfolioId);
		}

		return this._http.get(this.API_URL + path, { params: params, headers: headers })
	}

	// intercepted API Call (see interceptors/cwae.interceptor)
	httpPOST(path: string, postData): Observable<any> {
		if (this._authStatus) {
			this.cookieAuthUpdate()
		}

		postData.uId = this._userDetails['uId']
		postData.p = this._PRODUCT_ID

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.authService.TOKENS.access_token);
		if (this.currentPortfolioId != null) {
			headers = headers.append('PORTFOLIO-ID', this.currentPortfolioId);
		}

		return this._http.post(this.API_URL + path, postData, { headers: headers })


	}


	httpGETNoAuth(path: string, getData = null): Observable<any> {

		const headers = new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		});

		const myParams = new URLSearchParams();
		if (getData != null) {
			for (const key in getData) {
				myParams.append(key, getData[key]);
			}
		}
		// headers.append('Authorization', 'Bearer '+ this.authService.TOKENS.access_token);
		const options = new RequestOptions({
			headers: headers,
			params: myParams
		});

		return this.http.get(this.API_URL + path, options)
			.pipe(map(res => res.json()))
	}



	httpPOSTNoAuth(path: string, postData): Observable<any> {

		const headers = new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		});

		const options = new RequestOptions({
			headers: headers
		});

		return this.http.post(this.API_URL + path, Object.assign(postData), options)
			.pipe(map(res => res.json()))
	}

	httpAPIGET(path: string, getData = null): Observable<any> {
		if (this._authStatus) {
			this.cookieAuthUpdate()
		}
		const headers = new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		});

		return this.http.get(path)
			.pipe(map(res => res.json()))
	}

	httpCoingeckoAPIGET(path: string, getData = null): Observable<any> {
		if (this._authStatus) {
			this.cookieAuthUpdate()
		}

		const headers = new Headers({
			'Accept': 'application/json',
			// 'x-cg-pro-api-key': 'CG-',
			'Content-Type': 'application/json'
		});

		const options = new RequestOptions({
			headers: headers
		});

		return this.http.get(path, options)
			.pipe(map(res => res.json()))
	}

	httpCMCAPIGET(path: string, getData = null): Observable<any> {
		if (this._authStatus) {
			this.cookieAuthUpdate()
		}

		const headers = new Headers({
			'Accept': 'application/json',
			'X-CMC_PRO_API_KEY': '2b431506-a92d-4d76-af06-19241615854c',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		});

		const options = new RequestOptions({
			headers: headers
		});

		return this.http.get(path, options)
			.pipe(map(res => res.json()))
	}

	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email))
	}

	validatePassword(password) {
		const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
		return re.test(String(password))
	}

	cookieAuthUpdate() {
		// debugger;
		if (this._userDetails == null) {
			if (this._cookieService.check('userDetails')) {
				// console.log('hit')
				this._userDetails = JSON.parse(this._cookieService.get('userDetails'));
			} else {
				this.logout()
			}
		} else {

		}

	}

	isFormValid(formName) {
		let valid = true;
		// tslint:disable-next-line:forin
		for (const controlName in formName.controls) {
			if (!formName.controls[controlName].valid) {
				valid = false;
				break;
			}
		}
		return valid;
	}

	getSupportedCoinList() {
		// this.httpAPIGET(this.API_URL + 'get_supported_coins_and_exchanges').subscribe(response => {
		// 	this.supportedCoins = response.data
		// }, error => {
		// })
	}

	handleUnauthRequest() {
		// console.log('unauthenticated')
	}

	pageMessageService(pnum, type = null) {
		this.pageMessage = pnum

		if (type != null) {
			this.globalPageMessageType = type
		}
		setTimeout(() => {
			this.globalPageMessageType = 'info'
			this.pageMessage = 0
		}, 5000);
	}

	isBusinessAccount() {
		if (this._packagePolicies['business']['status'] == 'true') {
			return true
		} else {
			return false
		}
	}

	isTaxAllowed() {
		if (this._packagePolicies['features']['tax'] == true) {
			return true
		} else {
			return false
		}
	}

	isSnapshotAllowed() {
		if (this._packagePolicies['features']['portfolio_snapshots'] == true) {
			return true
		} else {
			return false
		}
	}

	isOwner() {
		if (this._isOwner) {
			return true
		} else {
			return false
		}
	}

	isAdmin() {
		if (this._isAdmin) {
			return true
		} else {
			return false
		}
	}



	openBlogPage() {
		window.open('https://medium.com/@cryptoworth', '_blank');
	}

	onToolbarMenuToggle() {
		// console.log('On toolbar toggled', this.isMenuOpen);
		this.isMenuOpen = !this.isMenuOpen;

		if (!this.isMenuOpen) {
			this.contentMargin = 70;
		} else {
			this.contentMargin = 240;
		}
	}

	handleCaptchaLoad(): void {
		this.captchaIsLoaded = true;
	}

	handleCaptchaReady(): void {
		this.captchaIsReady = true;
	}

	handleReset(): void {
		this.captchaSuccess = false;
	}

	getCurrentDateTimeStamp() {
		const today = new Date();
		const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		const time = today.getHours() + ':' + today.getMinutes()

		return date + ' ' + time
	}


	isLiveEnv() {
		if (window.location.host == 'cryptoworth.app') {
			return true
		} else {
			return false
		}
	}

	getTime() {
		const d = new Date(),
			h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
			m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
		return h + ':' + m;
	}

	unixTimeStamp(unixTimeStamp) {
		return moment.unix(unixTimeStamp / 1000).local().format('MMMM Do, YYYY h:mm A');
		// return moment.unix(unixTimeStamp / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A');
	}

	unixTimestampMain(unixTimeStamp) {
		return moment.unix(unixTimeStamp / 1000).local().format('L') + ' ' + moment.unix(unixTimeStamp / 1000).local().format('LT');
	}

	unixTimestampMainNoTZConvert(unixTimeStamp) {
		return moment.utc(unixTimeStamp).format('L') + ' ' + moment.utc(unixTimeStamp).format('LT');
	}

	unixTimeStampMin(unixTimeStamp) {
		return moment.unix(unixTimeStamp / 1000).local().format('MMM Do, YYYY h:mm A');
		// return moment.unix(unixTimeStamp / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A');
	}

	unixTimeStampDate(unixTimeStamp) {
		return moment.unix(unixTimeStamp / 1000).local().format('MMMM Do, YYYY');
		// return moment.unix(unixTimeStamp / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A');
	}

	unixTimeStampTime(unixTimeStamp) {
		return moment.unix(unixTimeStamp / 1000).local().format('h:mm A');
		// return moment.unix(unixTimeStamp / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A');
	}

	currentTimeZone(unixTimeStamp) {
		return moment().local().format('Z');
		// return moment.unix(unixTimeStamp / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A');
	}

	currentUTCOffset() {
		return moment().local().utcOffset();
	}

	openTxPageModalTracker = 0
	dropdownOpenClose(state) {
		// console.log(state)
		if (this.isdropdownOpen != state) {
			this.isdropdownOpen = state
		} else {
			this.isdropdownOpen = 0
		}


	}
	copyAddress(inputElement) {
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);

		this.addressTooltipText = 'Copied!'
		setTimeout(() => {
			this.addressTooltipText = 'Copy Address'
		}, 1000);
	}

	cryptoSymbolParse(symbol) {
		if (symbol != null) {
			if (symbol.length > 12) {
				return symbol.substring(0, 7) + '...' + symbol.substring(symbol.length - 5, symbol.length)
			} else {
				return symbol
			}
		}
	}


	transactionIdentificationCheck(transaction) {
		if (
			transaction['transaction_type'] == 'send' ||
			transaction['transaction_type'] == 'buy' ||
			transaction['transaction_type'] == 'sell' ||
			transaction['transaction_type'] == 'receive' ||
			transaction['transaction_type'] == 'transfer') {
			return true
		} else {
			return false
		}
	}

	copyToClipboard(item) {
		// console.log(item)
		// document.addEventListener('copy', (e: ClipboardEvent) => {
		//   e.clipboardData.setData('text/plain', (item));
		//   e.preventDefault();
		//   document.removeEventListener('copy', null);
		// });

		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = item;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	portfolioViewState(state, linkOnly = false) {
		this.componentLoading = false
		console.log('portfolioViewState: ' + state);

		if (!linkOnly) {
			this.currentPortfolioViewState = state
			this.currentPortfolioViewStateMain = state
			this.currentPortfolioNewNavState = state
			this.showPinIFrame = false
		}

		const mainURL = window.location.origin
		// console.log('===================')
		// console.log(mainURL)

		if (state == 1) {

			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'overview'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
				this.titleService.setTitle(this.appName + ' - Workspace: Overview')
				this.getDashboardSummary()
				this.getDashboardARAPSummary()
			}

		} else if (state == 2) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'connections'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 3) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'exchanges'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				if (this.exchangePageMode == 1) {
					// console.log('going hard route')
					this.router.navigate([subURL]);
				} else {
					// console.log('no hard route')
				}
				this.exchangePageMode = 0
				window.history.replaceState({}, '', subURL);

			}

		} else if (state == 4) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'transactions'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 5) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'analytics'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 7) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'history'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 8) {

			this.currentPortfolioViewState = 0
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'classifications'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/classifications']);


		} else if (state == 9) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'taxlots'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {

				if (this.taxLotPageMode == 1) {
					// console.log('going hard route')
					this.router.navigate(['/workspace/' + this.currentPortfolioId + '/' + 'taxlots']);
				} else {
					// console.log('no hard route')
				}
				this.taxLotPageMode = 0
				window.history.replaceState({}, '', subURL);

			}
		} else if (state == 10) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'reports'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/reports']);
			this.currentPortfolioViewState = -1

		} else if (state == 11) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'activity'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/activity']);
			this.currentPortfolioViewState = 0

		} else if (state == 12) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'users'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {

			}

			window.history.replaceState({}, '', subURL);
		} else if (state == 13) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'portfolio'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {

			}

			window.history.replaceState({}, '', subURL);
		} else if (state == 14) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'settings'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/settings']);
			this.currentPortfolioViewState = -1

		} else if (state == 15) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'issues'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				if (this.issuesPageMode == 1) {
					// console.log('going hard route')
					this.router.navigate([subURL]);
				} else {
					// console.log('no hard route')
				}
				this.exchangePageMode = 0
				window.history.replaceState({}, '', subURL);

			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/issues']);
			this.currentPortfolioViewState = 0

		} else if (state == 16) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'defi'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/defi']);
			this.currentPortfolioViewState = 0

		} else if (state == 17) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'gateways'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 18) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'payments'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
		} else if (state == 19) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'invoices'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 20) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'bills'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 24) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'nfts'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 26) {
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'nft_review'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}

		} else if (state == 27) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'files'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/files']);
			this.currentPortfolioViewState = 0

		} else if (state == 28) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'automations'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/automations']);
			this.currentPortfolioViewState = 0

		} else if (state == 29) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'vendors'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/vendors']);
			this.currentPortfolioViewState = 0

		} else if (state == 30) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'safts'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/safts']);
			this.currentPortfolioViewState = 0

		} else if (state == 31) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'reconciliation'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/reconciliation']);
			this.currentPortfolioViewState = 0
		} else if (state == 32) {
			this.setComponentLoading(state)
			const subURL = '/workspace/' + this.currentPortfolioId + '/' + 'workflows'
			const finalURL = subURL;
			if (linkOnly) {
				return finalURL
			} else {
				window.history.replaceState({}, '', subURL);
			}
			this.router.navigate(['/workspace/' + this.currentPortfolioId + '/workflows']);
			this.currentPortfolioViewState = 0
		}
	}
	setComponentLoading(state) {
		// setTimeout(() => {
		if (this.lastLoaderState != state) {
			this.componentLoading = true
			this.lastLoaderState = state
		}
		// }, 1);
	}

	setComponentLoadingOff() {
		// setTimeout(() => {
		this.componentLoading = false
		// }, 1);
	}

	/*
		IMPORTANT
		always use a global variable to set the subView type
		it does not work on the component local variables
	*/
	portfolioSubViewState(mainPage, subviewId) {
		if (mainPage == 'taxlots') {
			this.currentPortfolioViewState = 9
			this.taxLotPageMode = 1
			if (this.taxLotPageMode != 1) {
				this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/taxlots/' + subviewId]);
			}
			this.currentTaxLotId = subviewId
			this.loadSpecificTaxlot()
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/taxlots/' + subviewId);
		}

		if (mainPage == 'exchanges') {
			// console.log('hitttt url')
			this.currentPortfolioViewState = 3
			this.exchangePageMode = 1
			if (this.exchangePageMode != 1) {
				this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/exchanges/' + subviewId]);
			}
			this.currentExchangeViewId = subviewId
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/exchanges/' + subviewId);
		}

		if (mainPage == 'coin_info') {
			// console.log('coin_info url')
			// console.log(subviewId)
			this.currentPortfolioViewState = 21
			this.coinInfoPageMode = 1
			if (this.coinInfoPageMode != 1) {
				this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/coin_info/' + subviewId]);
			}
			this.currentCoinViewId = subviewId
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/coin_info/' + subviewId);
		}


		if (mainPage == 'wallets') {
			// console.log('hitttt wallets')
			// console.log('hitttt wallets ' + subviewId)
			this.currentPortfolioViewState = 22
			this.walletPageViewMode = 1
			if (this.walletPageViewMode != 1) {
				// console.log('router navigate')
				this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/wallets/' + subviewId]);
			}
			this.currentWalletViewId = subviewId
			this.currentWalletMode = 1
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/wallets/' + subviewId);
		}

		if (mainPage == 'nft') {
			// console.log('hit nfts')
			// console.log('hit nfts ' + subviewId)
			this.currentPortfolioViewState = 25
			this.currentNFTViewId = 1
			if (this.currentNFTViewId != 1) {
				// console.log('router navigate')
				this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/nft/' + subviewId]);
			}
			this.currentNFTViewId = subviewId
			this.NFTInfoPageMode = 1
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/nft/' + subviewId);
		}

		if (mainPage == 'safts') {
			console.log('hit safts subview')
			console.log('hit safts subview ' + subviewId)
			this.currentPortfolioViewState = 0
			this.currentSAFTViewId = 1;
			// if(this.currentSAFTViewId != 1){
			// 	// console.log('router navigate')
			// 	this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/safts/' + subviewId]);
			// }
			this.currentSAFTViewId = subviewId;
			window.history.replaceState({}, '', '/portfolio/' + this.currentPortfolioId + '/safts/' + subviewId);
			this.router.navigate(['/portfolio/' + this.currentPortfolioId + '/safts/' + subviewId]);
		}

	}

	loadSpecificTaxlot() {
		this.loadingMode = true
		this.httpGET('get_specific_tax_lot', { taxlotId: this.currentTaxLotId }).subscribe(response => {
			this.loadingMode = false
			if (response.status == true) {
				this.currentTaxLot = response.data
				this.processCostBasisOutput(this.currentTaxLot.transactions)
			} else {
			}
		}, error => {
			this.loadingMode = false
		});
	}
	loadSpecificExchanges(showloader = true, requestAccountId = null) {
		if (showloader) {
			this.loadingAccount = true
		}

		let requestViewNow = requestAccountId

		if (requestViewNow == null) {
			requestViewNow = this.currentExchangeViewId
		}

		this.httpGET('exchange_connection_info', { exchangeConnectionId: requestViewNow, portfolioId: this.currentPortfolioId })
			.subscribe(response => {

				this.loadingAccount = false

				if (response.status == true) {
					const tempCurrentExchangeView = response.data

					tempCurrentExchangeView['wallets'].forEach(element => {
						element.amountInCurrency = this.getVirtualWalletAmountInFiat(element.id)
						// console.log('got: ' + element.amountInCurrency)
					});

					this.currentExchangeView = tempCurrentExchangeView

				}

			}, error => {
				this.loadingAccount = false
			});
	}

	getExchangeBalancesGroupByAsset(doClear = true) {
		this.httpGET('exchange_connection_balance', {
			exchangeConnectionId: this.currentExchangeViewId,
			portfolioId: this.currentPortfolioId,
			doClear: doClear
		}).subscribe(response => {
			if (response.status == true) {
				this.currentExchangeBalanceList = response.data;
			} else {
				this.currentExchangeBalanceList = [];
			}
		});
	}

	getVirtualWalletAmountInFiat(walletId) {
		let returnValue = null
		this._virtualWallets.forEach(element => {
			if (element.id == walletId) {
				returnValue = element.amountInCurrency
			}
		});

		return returnValue
	}

	processCostBasisOutput(transactions) {
		this.processedTransactions = []
		for (const i in transactions) {
			const txLot = transactions[i]
			txLot.forEach(element => {
				this.processedTransactions.push(element)
			});
		}
		// console.log(this.processedTransactions.length)
		this.loadingMode = false
	}

	getDashboardSummary() {
		this.loadingDashboardSummary = true
		this.httpGET('dashboard_summary', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			this.loadingDashboardSummary = false
			if (response.status == true) {
				this._dashboardData = response.data
				this.portfolioUsers = response.data['users']
			} else {
			}
		}, error => {
			this.loadingDashboardSummary = false
		});
	}
	getDashboardARAPSummary() {
		this.loadingDashboardARAPSummary = true
		this.httpGET('dashboard_arap_summary', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			this.loadingDashboardARAPSummary = false
			if (response.status == true) {
				this._dashboardARAPData = response.data
				console.log(this._dashboardARAPData)
			}
		}, error => {
			this.loadingDashboardARAPSummary = false
		});
	}


	getSideBarSummary() {
		// this.httpGET('sidebar_summary', {portfolioId:this.currentPortfolioId}).subscribe(response => {
		// 	if(response.status==true){
		// 		this._sideBarData = response.data

		// 		//console.log(this._dashboardData)

		// 	} else {
		// 	}
		// }, error => {
		// });
	}

	getParentWalletStatus(wallet) {
		this._wallets.forEach(element => {
			if (element.parent_wallet_id == null && element.id == wallet.parent_wallet_id) {
				wallet['deep_sync_status'] = element['deep_sync_status']
			}
		});

	}

	pricePerCoin(costBasis, amount) {
		return costBasis / amount;
	}

	truncateLargeText(text, limit, trigger) {
		if (text != null) {
			if (text.length > trigger) {
				return text.substring(0, limit / 2) + '...' + text.substring(text.length - limit / 2, text.length)
			} else {
				return text
			}
		}
	}

	parseWalletAddress(address, main = false, forceShrink = false) {

		if (main) {
			if (typeof address === 'string') {
				const addressCheck = address.substring(0, 4);
				if ((addressCheck === 'xpub' || addressCheck === 'ypub' || addressCheck === 'zpub')) {
					return address.substring(0, 40) + '...' + address.substring(address.length - 4, address.length);
				} else {
					if (address.length > 50) {
						return address.substring(0, 40) + '...' + address.substring(address.length - 4, address.length);
					} else {
						return address
					}
				}
			} else {
				return address;
			}

		} else {

			return address.substring(0, 5) + '...' + address.substring(address.length - 3, address.length);
		}

	}

	parseFileName(name, cutOffAt = 50) {

		if (name != null) {

			let mainText = ''
			let doDotTrail = true

			for (let index = 0; index < cutOffAt; index++) {
				if (name[index]) {
					mainText = mainText + name[index];
				} else {
					doDotTrail = false
					break;
				}

			}

			if (doDotTrail) {
				mainText = mainText + '...'
			}

			return mainText
		} else {
			return null
		}
	}

	parseWalletAddressExtended(address) {

		let mainText = ''

		for (let index = 0; index < 45; index++) {
			mainText = mainText + address[index];

		}

		mainText = mainText + '...'
		return mainText
	}

	parseAPIKey(key) {
		if (
			key != '-' && key != null
		) {
			return key[0] + '' + key[1] + '' + key[2] + '' + key[3] + '' + key[4] + '' + key[5] + '' + key[6] + '' + key[7] + '' + key[8] + '' + key[9] + '' + key[10] + '' + key[11] + '...'
		} else {
			return '-'
		}
	}

	//
	// a = wallet amount
	// b = snaity amount
	subFloats(aValue, bValue) {
		const ALenth = aValue + '';
		const ALenthSplit = ALenth.split('.')
		let ALenthFinal = 0

		if (ALenthSplit.length > 1) {
			ALenthFinal = ALenthSplit[1].length
		}


		const BLenth = bValue + '';
		const BLenthSplit = BLenth.split('.')
		let BLenthFinal = 0

		if (BLenthSplit.length > 1) {
			BLenthFinal = BLenthSplit[1].length
		}

		let decimalAccuracy = 18

		if (BLenthFinal > ALenthFinal) {
			decimalAccuracy = BLenthFinal
		} else {
			decimalAccuracy = ALenthFinal
		}

		let substractedValue = 0

		substractedValue = aValue - bValue

		return substractedValue.toFixed(decimalAccuracy);
	}

	valueAbs(value) {
		return Math.abs(value)
	}

	hideTooltip(tooltip) {
		setTimeout(() => {
			tooltip.hide()
		}, 1000);
	}

	getTxTableTooltip(column) {
		let text = null;

		switch (column) {
			case 'date':
				return text = 'Timestamp of the transaction in UTC ' + this.portfolioUTCOffset + '.'

			case 'type':
				return text = 'The main type of the transaction. (Eg: Buy, Sell, Receive, Send)'

			case 'tag':
				return text = 'An autogenerated identifier assigned to transactions whenever such identification is detectable. (Eg: Trade, Withdraw, Deposit, etc.)'

			case 'market':
				return text = 'Applicable only for trades. Represents the trading pair in which the transactions was traded.'

			case 'amount':
				return text = 'Represents how much of the asset was associated with the transaction.'

			case 'fees':
				return text = 'Transaction fees. Internal (ie:  mining) and External (ie: withdrawal) fees.'

			case 'cost':
				return text = 'Cost of the transaction. (Eg:- Cost Basis or WAC)'

			case 'fmv':
				return text = 'The fair market value of the asset.'

			case 'classification':
				return text = 'The assigned classification.'

			case 'source':
				return text = 'The source from which the transaction originates from.'

			case 'unitPrice':
				return text = 'The unit price of the asset.'

			case 'capitalGain':
				return text = 'Capital Realized Gain/Loss realized per transactions EXCLUDING fee deductions.'

			case 'salePrice':
				return text = 'The fair market value of the asset. Only applicable for dispositions. (Eg:- Sends, Sells)'

			case 'wac':
				return text = 'The weighted average cost.'

			case 'tax':
				return text = 'Shows if the transactions is taxable or not.'

			case 'remaining':
				return text = 'Remaining amount available to use for future transactions. Also known as the carry-forward value.'

			case 'costBasisRemaining':
				return text = 'Remaining cost basis. (Amount x Remaining)'

			case 'used':
				return text = 'Amount used from this transaction to satisfy the current outgoing transaction.'

			case 'id':
				return text = 'Ledger ID of the transaction'

			case 'cogs':
				return text = 'Cost of goods sold. Includes any fees paid as mining fees or external fees. '

			case 'sales':
				return text = 'Total value of sales or dispositions'

			case 'disposed':
				return text = 'Total number of disposed assets excluding mining fees'

			case 'totalSales':
				return text = 'Total proceeds'

			case 'gainLossExludingFees':
				return text = 'Realized Gain/Loss calculated for the transaction excluding any fees. Fees are NOT deducted from this Realized Gain/Loss value.'

			case 'totalFees':
				return text = 'Internal and External fees combined.'

			case 'internalFees':
				return text = 'Fees paid as a part of the transaction.'

			case 'externalFees':
				return text = 'Fees paid as separate transactions. All fees paid as separate transactions will be tagged as \'FEES\'.'

			case 'unrealizedGains':
				return text = 'Fair market value of the assets that are not disposed.'

			case 'currentTotalUGL':
				return text = 'Total FMV value'

			case 'purchaseTotalUGL':
				return text = 'Total purchase value'
		}
	}

	getIssueNameFromId(id) {

		for (let index = 0; index < this.issuePrioritiesIds.length; index++) {
			if (this.issuePrioritiesIds[index] == id) {
				return this.issuePriorities[index]
			}

		}
	}

	createIssueComment() {
		this.noReload = true
		this.issueCommentAddStatus = true
		this.httpPOST('issue_add_comment', { portfolioIssueId: this.currentViewIssue.id, comment: this.issueComment }).subscribe(response => {
			if (response.status == true) {
				this.getNSetsetCurrentViewIssue(this.currentViewIssue)

			}
		}, error => {
			this.issueCommentAddStatus = false
		});
	}

	deleteIssueComment(commentId) {
		this.noReload = true
		this.issueCommentDeletetatus = true
		this.httpGET('issue_delete_comments', { commentId: commentId }).subscribe(response => {
			if (response.status == true) {
				this.getNSetsetCurrentViewIssue(this.currentViewIssue)

			}
		}, error => {
			this.issueCommentDeletetatus = false
		});
	}

	getNSetsetCurrentViewIssue(issue, mode = 'soft') {
		this.currentViewIssueLoading = true
		this.httpGET('get_issue', { issueId: issue.id, mode: mode }).subscribe(response => {

			this.currentViewIssueLoading = false

			this.issueCommentDeletetatus = false
			this.issueComment = null
			this.issueCommentAddStatus = false
			this.noReload = false

			if (response.status == true) {
				this.currentViewIssue = response.data

				if (mode == 'full') {
					this._transactions = response.data.references
					// console.log(this._transactions)
				}
			}
		}, error => {
			this.currentViewIssueLoading = false
		});
	}

	createIssue(type) {
		this.createIssueMode = 1
		this.portfolioIssue['type'] = type

		// console.log(this.portfolioIssue)

		// parse sourceIds
		if (type == 'transactions') {
			this.portfolioIssue['sourceIds'] = this.selectedTx
		}

		if (this.createIssueValidationCheck()) {

			this.createIssueModeValdiation = 0

			// do the HTTP call
			this.httpPOST('create_issue', { portfolioIssue: this.portfolioIssue, portfolioId: this.currentPortfolioId }).subscribe(response => {
				this.createIssueMode = 0
				if (response.status == true) {
					this.pageMessageService(4)
					this.modalRef.close()
					this.getPortfolioIssues(true)

					this.resetPortfolioIssues()

				}
			}, error => {
				this.createIssueMode = 0
			});


			// console.log('create issue')

		} else {
			this.createIssueMode = 0
			this.createIssueModeValdiation = 1
		}

	}

	resetPortfolioIssues() {
		this.portfolioIssue = {
			title: null,
			description: null,
			type: null,
			priority: null,
			sourceIds: []
		}
	}

	createIssueValidationCheck() {
		// console.log(this.portfolioIssue)
		if (this.portfolioIssue['type'] == null) {
			return false
		}

		if (this.portfolioIssue['title'] == null) {
			return false
		}

		if (this.portfolioIssue['description'] == null) {
			return false
		}

		if (this.portfolioIssue['priority'] == null) {
			return false
		}

		if (this.portfolioIssue['sourceIds'] == null || this.portfolioIssue['sourceIds'].length == 0) {
			return false
		}

		return true

	}


	resetIssue() {
		this.createIssueModeValdiation = 0
		this.portfolioIssue['sourceIds'] = []
	}


	setIssueLoadingMode(mode) {
		this._issues = []
		this.issuesMode = mode
	}

	getPortfolioIssues(isFresh = false) {

		if (isFresh) {
			this._issues = []
		}

		this.issuesLoading = true
		this.issuesLoadMore = true

		this.httpGET('get_issues', { portfolioId: this.currentPortfolioId, skip: this._issues.length, mode: this.issuesMode }).subscribe(response => {
			this.issuesLoading = false
			if (response.status == true) {

				this._issuesCount = response.data['totalCount']

				if (response.data['all'].length > 0) {
					response.data['all'].forEach(element => {
						this._issues.push(element)
					});

					if (response.data['all'].length < this.paginateCount) {
						this.issuesLoadMore = false
					}

				} else {
					this.issuesLoadMore = false

				}

			}
		}, error => {
			this.issuesLoading = false
		});
	}

	markResolved(status, issueId = this.currentViewIssue.id,) {
		this.issueResolveStatus = true
		this.httpGET('resolve_issue', { issueId: issueId, status: status, portfolioId: this.currentPortfolioId }).subscribe(response => {

			if (response.status == true) {

				this.issueResolveStatus = false
				// this.getNSetsetCurrentViewIssue(this.currentViewIssue)

				if (issueId == -1) {
					this.getPortfolioIssues()
				}

				if (this.currentViewIssue != null) {
					this.currentViewIssue.status = status
				}

				this._issues.forEach(element => {
					if ((this.currentViewIssue != null && element.id == this.currentViewIssue.id) || issueId == -1) {
						element.status = status
					}
				});


				if (status == 1) {
					this._issuesCount--
				} else {
					this._issuesCount++
				}

			}
		}, error => {
			this.issueResolveStatus = false
		});
	}

	getLogTypes(type) {
		return this.LogTypes[type]
	}

	highlightTransactions(txId) {
		if (this.highlightedTransactions.indexOf(txId) == -1) {
			this.highlightedTransactions.push(txId)
		}

		// console.log(this.highlightedTransactions)
	}

	unHighlightTransactions(txId) {
		this.highlightedTransactions.splice(this.highlightedTransactions.indexOf(txId), 1) // pop loader
	}

	isTransactionHighlighted(txId) {
		if (this.highlightedTransactions.indexOf(txId) !== -1) {
			return true
		} else {
			return false
		}
	}

	shortenTxId(txId) {

		if (txId != null) {
			const txIdLength = txId.length

			if (txIdLength >= 10) {
				return txId[0] + txId[1] + txId[2] + txId[3] + txId[4] + '...' + txId[txIdLength - 4] + txId[txIdLength - 3] + txId[txIdLength - 2] + txId[txIdLength - 1]
			} else {
				return txId
			}

		}
	}

	shortenTokenId(tokenId) {

		if (tokenId != null) {
			const tokenIdLength = tokenId.length

			if (tokenIdLength >= 10) {
				return tokenId[0] + tokenId[1] + tokenId[2] + tokenId[3] + tokenId[4] + '...' + tokenId[tokenIdLength - 4] + tokenId[tokenIdLength - 3] + tokenId[tokenIdLength - 2] + tokenId[tokenIdLength - 1]
			} else {
				return tokenId
			}

		}
	}

	shortenTokenTitle(title) {

		if (title != null) {
			const titleLength = title.length

			if (titleLength >= 12) {
				return title[0] + title[1] + title[2] + title[3] + title[4] + title[5] + title[6] + title[7] + title[8] + title[9] + title[10] + '...'
			} else {
				return title
			}

		}
	}

	shortenNameDisplay(title) {

		if (title != null) {
			const titleLength = title.length

			if (titleLength >= 12) {
				return (title != null && title.length > 17) ? title.substring(0, 12) + '...' + title.substring(title.length - 5, title.length) : title
			} else {
				return title
			}

		}
	}

	shortenNameForAddressBookDisplay(title) {

		let lengthOne = 12

		if(this.addressBookListViewExpanded){
			lengthOne = 160

		}

		if (title != null) {
			const titleLength = title.length

			if (titleLength >= lengthOne) {
				return (title != null && title.length > 17) ? title.substring(0, lengthOne) + '...' + title.substring(title.length - 5, title.length) : title
			} else {
				return title
			}

		}
	}

	shortenedClassificationDisplay(title) {

		if (title != null) {
			const titleLength = title.length

			if (titleLength >= 40) {
				return (title != null && title.length > 35) ? title.substring(0, 40) + '...' + title.substring(title.length - 5, title.length) : title
			} else {
				return title
			}

		}
	}


	WACLogsParse(transaction, getUnit) {

		const transactionParse = JSON.parse(transaction.native_value_details)

		if (getUnit == 'totalInputs') {
			return transactionParse['totalInputs']
		}

		if (getUnit == 'totalInputCostBasis') {
			return transactionParse['totalInputCostBasis']
		}

		if (getUnit == 'WAC') {
			return transactionParse['weightedAverageCostOfAsset']
		}

		if (getUnit == 'amountSentSolid') {
			return transactionParse['amountSentSolid']
		}

	}

	getLogs(transaction, incomingIndex) {


		if (this.showlogContainer.indexOf(incomingIndex) == -1) {

			this.showlogContainer.push(incomingIndex) // push load
			this.showLogLoadingIndex.push(incomingIndex) // push log loader

			this.httpGET('get_transaction_logs', { transactionId: transaction.id, portfolioId: this.currentPortfolioId })
				.subscribe(response => {

					this.showlogList[incomingIndex] = response.data

					// console.log(this.showlogList[incomingIndex].dispose_reference)

					this.showLogLoadingIndex.splice(this.showLogLoadingIndex.indexOf(incomingIndex), 1) // pop loader


					// console.log(this.showLogLoadingIndex)
				}, error => {

					this.showLogLoadingIndex.splice(this.showlogContainer.indexOf(incomingIndex), 1) // pop loader
				});
		}

	}

	removeAllLogs() {
		this.showlogContainer = []
	}

	removeLogView(transaction, index) {
		this.showlogContainer.splice(this.showlogContainer.indexOf(index), 1)  // pop view reference
		this.showLogLoadingIndex.splice(this.showlogContainer.indexOf(index), 1)  // pop loader
		this.showlogList.splice(index, 1)  // pop item
	}

	openTransactionDetailsModal(TranscationDetailsModal, transaction) {
		// console.log(transaction)
		this.currentTransactionDetails = transaction;
		this.openModal(TranscationDetailsModal, { windowClass: 'gr-modal-right' });
	}


	openTransactionRollUpDetailsModal(transaction) {
		// console.log(transaction)
		this.currentTransactionDetails = transaction;
		this.RolledTxnSourceModalEmitter.emit(transaction)

	}

	openModal(content, data = null) {
		this.modalRef = this.modalService.open(content, data);
	}

	closeModal() {
		this.modalRef.close()
	}

	closeAllOpenedModals() {
		this.modalService.dismissAll();
	}

	findSourceTxnsOperation(parentTxn, mode = 1, page = null) {
		this.formState = true
		this.findSourceTxStatus = 1
		this.httpPOST('rolled/get_rolled_transaction', {
			portfolioId: this.currentPortfolioId,
			parentTxId: parentTxn.id, // 1=scan, 2=clean
			rollUpId: parentTxn.roll_up_id,
		}).subscribe(response => {

			this.formState = false;
			this.findSourceTxStatus = 0;

			if (response.status == true) {
				this.findSourceTxStatus = 2
				this.sourceTransactions = response.data
				// this.sourceTransactionsCount = response.data[1]

				if (this.sourceTransactions.length == 0) {
					this.noOriginalTxFound = true
					this.unrollMessage = 3
				} else {
					this.noOriginalTxFound = false
					this.unrollMessage = 0
				}

			} else {
			}

			if (mode == 2) {
				if (this.modalRefTwo != null) {
					this.modalRefTwo.close()

				}
			}

		}, error => {
			this.formState = false;
			this.findSourceTxStatus = 0;
			this.noOriginalTxFound = true;
		})

	}

	unrollSingleTx(parentTxn) {
		this.httpPOST('rolled/unroll_single_transaction', {
			portfolioId: this.currentPortfolioId,
			parentTxId: parentTxn.id, // 1=scan, 2=clean
			rollUpId: parentTxn.roll_up_id,
		}).subscribe(response => {

			if (response.status == true) {
				console.log('unrolled successfully')
				this.unrollMessage = 1
			} else {
				this.unrollMessage = 2
			}

			this.unrollLoading = false


		}, error => {
			this.unrollLoading = false
			console.log('unroll failed')
			this.unrollMessage = 2
		})


	}

	sourceTxnsPageChange(value: any) {
		console.log('duplicate page change called')
		this.findSourceTxnsOperation(1, value.page)
	}


	roundNumbers(value, precision) {
		const multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	getInvoiceBaseURL() {
		return window.location.host + '/commerce/view-invoice/';
	}

	getReportGenerationTypes() {
		if (this.availableReportTypes.length == 0) {
			this.loaindgReportType = true
			this.httpGET('report_types', { portfolioId: this.currentPortfolioId })
				.subscribe(response => {

					this.loaindgReportType = false
					this.availableReportTypes = response.data

				}, error => {
					this.loaindgReportType = false
				})
		}
	}


	closeCAPCommerceModal() {
		this.CAPCommerceState = 0
		this.invoiceModal.close();

	}

	checkGatewayConnections() {

		let observable: Observable<any>;
		this.connectedGatewaysLoading = true;
		if (this.gatewayConnectionCache.length > 0) {
			observable = of(this.gatewayConnectionCache);
		} else if (this.cachedObservableGateway) {
			observable = this.cachedObservableGateway;
		} else {
			this.cachedObservableGateway = this.httpGET('connected_gateways', {
				portfolioId: this.currentPortfolioId
			})
				.pipe(
					tap(res => this.gatewayConnectionCache = res),
					share(),
					finalize(() => this.cachedObservableGateway = null)
				);

			observable = this.cachedObservableGateway;
		}

		// observable.subscribe( response=>{
		// 	subject.next(response);
		// 	subject.complete();
		// }, error=>{
		// 	subject.error(error);
		// 	subject.complete();
		// });
		return observable;

	}


	setDefaultHeading() {
		// this.titleService.setTitle(this.DEFAULT_HEADING)
	}

	setNav() {
		this.navigation = []
		const url = this.router.url
		const parseUrl = url.split('/');

		for (let i = 0; i < parseUrl.length; i++) {
			if (i == 0) {
				this.navigation.push('Home')
			}
			if (i != 0) {
				this.navigation.push(parseUrl[i])
			}
		};
	}

	getAllAddresses() {
		if (this._authStatus) {
			this.addressLoading = true

			let getData = null
			if (this.currentPortfolioId) {
				getData = {
					portfolioId: this.currentPortfolioId
				}
			}

			this.httpGET('get_addresses', getData).subscribe(response => {
				this.addressLoading = false
				this.addresses = response['data']
				this.searchResultsAddress = this.addresses
			}, error => {
				this.addressLoading = false
			});

			this.addressGroupLoading = true


			this.httpGET('get_address_groups', getData).subscribe(response => {
				this.addressGroupLoading = false
				this.addressGroups = response['data']
			}, error => {
				this.addressGroupLoading = false
			});
		}


	}

	selectedAddressGroup(group) {
		this.addressDisplayCount = null
		this.addressGroupSelectedEmitter.emit(group)
	}

	getAddressGroupFromId(groupId) {
		let finalGroup = false;
		this.addressGroups.forEach(group => {
			if (group.id == groupId) {
				finalGroup = group.group_name
			}
		});
		return finalGroup
	}
	goToSettingsSpecific(id) {
		this.specificPortfolioSettingLocation = id
		this.portfolioViewState(14)
	}

	classificationNameFormat(classificationName) {

		const name = classificationName

		if (name != null) {

			if (classificationName != null) {
				const clsLength = classificationName.length

				if (clsLength >= 12) {
					return classificationName[0] + classificationName[1] + classificationName[2] + classificationName[3] + classificationName[4] + '...' + classificationName[clsLength - 4] + classificationName[clsLength - 3] + classificationName[clsLength - 2] + classificationName[clsLength - 1]
				} else {
					return classificationName
				}

			}

		} else {
			return null;
		}

	}
	getTagDescription(tag) {
		// console.log('tag dec called: ' + tag);

		const text = null

		if (this._transactionTags != null && tag != null) {
			this._transactionTags.forEach(element => {
				if (element.tag_type != null) {
					if (element.tag_type.toLowerCase() == tag.toLowerCase()) {
						this.description = element.description
					}
				}
			});
		} else {
			// console.log('tag dec called: null');
			return null;
		}

		return this.description
	}

	getInvoiceExportSucessMessage() {
		return '<p> Your export is on the way. We will send an email to <b>' + this._userDetails['email'] + '</b> when it is ready.<br/>Directions to view the invoices will be sent through the email. </p>'
	}

	unselectAllSelected() {
		this.selectedTx = []
		this.selectFullDropTxns = []
		this.selectFullTx = false
		this.selectedTxFull = []
	}

	unselectAllSelectedNFT() {
		this.selectedNFT = []
		this.selectFullDropNFTs = []
		this.selectFullNFT = false
		this.selectedNFTFull = []
	}
	changeLayout(event) {
		this.changingLayout = true
		this.httpPOST('change_layout', {
			uId: this._userDetails['uId'],
			layout: this._userDetails['layout_mode'],
			QSBottomBar: this._userDetails['show_qs_bottm_bar']
		}).subscribe(response => {
			this.changingLayout = false
			if (response.status == true) {
				// set the cookie
				this.layoutMode = 1;//this._userDetails['layout_mode']
			}
		}, error => {
			this.changingLayout = false
		});
	}

	allowModifyingCostSalePrice(transaction) {
		// handling the worse case scenario of a dev forgetting to inject the tx object
		if (transaction == null) {
			return true
		}

		// console.log('allow modify called')
		// console.log(transaction['tag_type'])
		// console.log(transaction['crypto_id'])

		if ((
			transaction['tag_type'] != 'nft mint' &&
			transaction['tag_type'] != 'nft' &&
			transaction['tag_type'] != 'nft 1155' &&
			transaction['crypto_id'] == -1
		)) {
			return false
		} else {
			return true
		}

	}


	public getRecurringIntervalString(interval) {
		if (interval === 0) {
			return 'Weekly'
		}

		if (interval === 1) {
			return 'Bi-Weekly'
		}

		if (interval === 2) {
			return 'Monthly'
		}

		if (interval === 3) {
			return 'Quarterly'
		}

		if (interval === 4) {
			return 'Yearly'
		}
	}

	// Sanitizes "[\"sends\",\"receives\"]" into an object
	public arrayDBJsonString(jsonObjectString) {
		// console.log(jsonObjectString)
		const arrString = JSON.parse(jsonObjectString)
		// console.log(arrString)
		return arrString
	}

	// Sanitizes "{\"sends\":false,\"receives\":true}" into an object
	public objectDBJsonString(jsonObjectString) {
		return JSON.parse(jsonObjectString)
	}

	public getCCAPIKey() {
		if (this.authService.ENV_SET == 'prod') {
			//59838afdc072a85bbe5b03d3179476552717fd2f47856f22a088b9639c93ab44 - main key
			return '59838afdc072a85bbe5b03d3179476552717fd2f47856f22a088b9639c93ab44' // '7e50565d056d34040c086bd3f81f127b456d2688183c5b28e44a4310b444d771'
		} else {
			return '59838afdc072a85bbe5b03d3179476552717fd2f47856f22a088b9639c93ab44'
		}
	}

	loadCBExportHistory(fresh = true) {
		this.exportCBHistory = null
		let skipcount = 0
		this.loadingPortfolioHistoryPageStates = true

		if (this.exportCBHistory != null && !fresh) {
			skipcount = this.exportCBHistory.length
		}

		this.httpGET('portfolio_report_history', { portfolioId: this.currentPortfolioId, skip: skipcount }).subscribe(response => {
			this.loadingPortfolioHistoryPageStates = false
			if (response.status == true) {

				this.reportHistoryShowLoadMore = true
				this.exportCBHistory = response.data

			} else {
			}
		}, error => {
			this.loadingPortfolioHistoryPageStates = false
		});

	}
	showLoader() {
		console.log('show global loader')
		this.showLoaderStatus = true

		// setTimeout(() => {
		// 	if(this.showLoaderStatus){
		// 		this.trueshowLoaderStatus = true
		// 	}
		// }, 2000);
	}

	hideLoader() {
		this.showLoaderStatus = false
		this.componentLoading = false

	}

	randomIntFromInterval(min, max) { // min and max included
		return Math.floor(Math.random() * ((max - 1) - min + 1) + min)
	}
	getPortfolioPinnedViews() {
		this.loadingPins = true

		this.httpGET('pins', { portfolioId: this.currentPortfolioId }).subscribe(response => {
			this.loadingPins = false
			if (response.status == true) {
				if (response.data != null) {
					this.pinnedViews = response.data
				}

			}
		}, error => {
			this.loadingPins = false
		});
	}

	// load connection groups
	getConnectionGroups(reset = false) {
		if (reset) {
			this.connectionGroups = []
		}

		this.httpGET('get_connection_groups', {
			portfolioId: this.currentPortfolioId
		}).subscribe(response => {
			// console.log("export res", response)

			if (response.status == true) {
				this.connectionGroups = response.data

				// this.searchResultsGroup = this.connectionGroups
				// this.global.connectionGroups = this.connectionGroups

				console.log('connection groups in global :::')
				console.log(this.connectionGroups)



			}

		}, error => {

		});


	}

	getPortfolioNameById(id) {
		console.log(id)
		this.portfolios.forEach(portfolio => {
			if (portfolio.id == id) {
				return portfolio.name
			}
		});
	}

	getLabelledDeFiProtocols() {
		this.allLabelledAddressInfolLoading = true
		this.httpGET('get_oracle_labels')
			.subscribe(response => {
				this.allLabelledAddressInfolLoading = false
				this.allLabelledAddressInfo = response.data
			}, error => {
				this.allLabelledAddressInfolLoading = false
			});
	}

	selectAllConnectionWallets() {
		this.allConnectionWalletsSelected = true;
		this.userPortfolioSettings.txtable_filters['allWalletsConnect'] = true

		this._wallets.forEach(element => {
			if (this.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(element.id) == -1) {
				if (element.parent_wallet_id == null) {
					this.userPortfolioSettings.txtable_filters['walletsConnected'].push(element.id)
				}
			}
		});

		this._virtualWallets.forEach(element => {
			if (element.exchange_connection_id == 0) {
				if (this.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].indexOf(element.id) == -1) {
					this.userPortfolioSettings.txtable_filters['virtualWalletsConnected'].push(element.id)
				}
			}
		});
	}

	unselectAllConnectionWallets() {
		this.allConnectionWalletsSelected = false

		this.userPortfolioSettings.txtable_filters['allWalletsConnect'] = false
		this.userPortfolioSettings.txtable_filters['walletsConnected'] = []
		this.userPortfolioSettings.txtable_filters['virtualWalletsConnected'] = []
	}

	selectAllConnectionAccounts() {
		this.allConnectionAccountsSelected = true

		this.userPortfolioSettings.txtable_filters['allExchangesConnect'] = true
		this._exchangesConnected.forEach(element => {
			if (this.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(element.exchange_connection_id) == -1) {
				this.userPortfolioSettings.txtable_filters['exchangeConnected'].push(element.exchange_connection_id)
			}
		});
	}

	unselectAllConnectionAccounts() {
		this.allConnectionAccountsSelected = false

		this.userPortfolioSettings.txtable_filters['exchangeConnected'] = []
		this.userPortfolioSettings.txtable_filters['allExchangesConnect'] = false
	}

	connectionsFilterSelect(pageConn, connectionsFilterMode) {

		this.allConnectionWalletsSelected = false;
		this.allConnectionAccountsSelected = false;

		if (this.release == 'v2' && connectionsFilterMode == 2) {

			if (pageConn.connection_type == 'exchange') {

				this.userPortfolioSettings.txtable_filters['allExchangesConnect'] = false
				const arrIndex = this.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(pageConn.id)

				if (arrIndex !== -1) {
					this.userPortfolioSettings.txtable_filters['exchangeConnected'].splice(arrIndex, 1)
				} else {
					this.userPortfolioSettings.txtable_filters['exchangeConnected'].push(pageConn.id)
				}
			} else if (pageConn.connection_type == 'wallet') {

				this.userPortfolioSettings.txtable_filters['allWalletsConnect'] = false
				const arrIndex = this.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(pageConn.id)

				if (arrIndex !== -1) {
					this.userPortfolioSettings.txtable_filters['walletsConnected'].splice(arrIndex, 1)
				} else {

					this.userPortfolioSettings.txtable_filters['walletsConnected'].push(pageConn.id)
				}
			}

		}

	}

	connectionsFilterItemCheckSelected(pageConn, connectionsFilterMode) {


		if (this.release == 'v2' && connectionsFilterMode == 2) {

			if (pageConn.connection_type == 'exchange') {

				if (this.userPortfolioSettings.txtable_filters['allExchangesConnect']) {
					const arrIndex = this.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(pageConn.id)
					if (arrIndex == -1) {
						this.userPortfolioSettings.txtable_filters['exchangeConnected'].push(pageConn.id)
					}

				}

				if (this.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(pageConn.id) !== -1 || this.userPortfolioSettings.txtable_filters['allExchangesConnect']) {
					return true
				} else {
					return false
				}
			} else if (pageConn.connection_type == 'wallet') {

				if (this.userPortfolioSettings.txtable_filters['allWalletsConnect']) {
					const arrIndex = this.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(pageConn.id)
					if (arrIndex == -1) {
						this.userPortfolioSettings.txtable_filters['walletsConnected'].push(pageConn.id)
					}

				}

				if (this.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(pageConn.id) !== -1 || this.userPortfolioSettings.txtable_filters['allWalletsConnect']) {
					return true
				} else {
					return false
				}
			}

		} else {
			return false;
		}
	}

	makeConnectionsArraysUnique() {
		if (this.userPortfolioSettings.txtable_filters != null) {
			this.userPortfolioSettings.txtable_filters['exchangeConnected'].filter((item, index) => this.userPortfolioSettings.txtable_filters['exchangeConnected'].indexOf(item) === index);
			this.userPortfolioSettings.txtable_filters['walletsConnected'].filter((item, index) => this.userPortfolioSettings.txtable_filters['walletsConnected'].indexOf(item) === index);
		}
	}

	getExchangeConnectionVaults() {

		this.httpGET('vault_info', {

			uId: this._userDetails['uId'],
			exchangeConnectionId: this.currentExchangeView.exchange_connection_id,
			exchangeConnectionPortfolioId: this.currentExchangeView.exchange_connection_source_id

		}).subscribe(response => {
			if (response.status == true) {
				console.log('vaults fetched successfully')
				console.log(response.data)

				this.connectedVaults = []

				response.data.vaults.forEach(element => {
					this.connectedVaults.push(element.vault)
				});

				this.sync_specific_vaults_mode = response.data.sync_specific_vaults

				this.onChangeIncludeExclude()
				this.checkExcludeAllExchanges()


			}
		}, error => {

		});
	}

	checkExcludeAllExchanges() {
		if (this.sync_specific_vaults_mode == 3 || this.sync_specific_vaults_mode == 4) {
			this.excludeAllExchanges = true;

		} else if (this.sync_specific_vaults_mode == 1 || this.sync_specific_vaults_mode == 2) {
			this.excludeAllExchanges = false;

		}

	}

	onChangeIncludeExclude() {

		if (this.sync_specific_vaults_mode == 1 || this.sync_specific_vaults_mode == 3) {
			this.includeExclude = 'include';
		} else if (this.sync_specific_vaults_mode == 2 || this.sync_specific_vaults_mode == 4) {
			this.includeExclude = 'exclude';
		}

	}


	updateVaults() {

		this.loadingVaults = true

		console.log(this.connectedVaults)

		this.httpPOST('vault_info',

			{
				portfolioId: this.currentPortfolioId,
				uId: this._userDetails['uId'],
				exchangeConnectionId: this.currentExchangeView.exchange_connection_id,
				exchangeConnectionPortfolioId: this.currentExchangeView.exchange_connection_source_id,
				vaults: this.connectedVaults

			}).subscribe(response => {
				this.loadingVaults = false
				if (response.status == true) {
					console.log('vaults updated successfully')
					console.log(response.data)

				} else {
					console.log('vaults update failed')
				}
			}, error => {
				this.loadingVaults = false
				console.log('vaults update failed')
			});

	}


	getMaxLockDate() {

		let lockDateInt = 0
		this.portfolioTransactionLocks.forEach(element => {
			if (element.end_date > lockDateInt) {
				lockDateInt = element.end_date
			}
			if (element.endDate > lockDateInt) {
				lockDateInt = element.endDate
			}
		});

		const lockDate = new Date((lockDateInt * 1000) - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]
		return lockDate;

	}

	checkStatus(): Observable<any> {
		return this.httpGET('report_status', { portfolioId: this.currentPortfolioId });
	}

	getCryptoAmount(transactionInfo){
		if(transactionInfo['transaction_type'] == 'buy'){
			return transactionInfo['transaction_amount']
		} else {
			return transactionInfo['amount']
		}
	}

}
