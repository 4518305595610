import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';
import { CoinService } from '../../../_services/coin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

	// acount page
	constructor(public global: GlobalService, public route: ActivatedRoute, public coinService: CoinService) {
	}

	ngOnInit() {
		this.global.doHeartBeat()
	}






}