import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCAPModule } from '../../_components_a_common/common.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { GlobalLoader } from '../_components_a_common/global-loader/global-loader.component';
import { AutomationRuleViewComponent } from './automation-rule-view/automation-rule-view.component';

import { ClassificationConnectionInfoModule } from '../../_components_crypto_one_commons/_components_classification-connection/components-classification-connection-info.module';

@NgModule({
  imports: [
    CommonModule,
    ClassificationConnectionInfoModule,
    CommonCAPModule,
    NgbModule.forRoot(),
  ],
  declarations: [
    AutomationRuleViewComponent,
  ],
  providers: [
  ],
  exports: [
    CommonModule,
    AutomationRuleViewComponent,

  ]
})
export class AutomationViewModule { }
