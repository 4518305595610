import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { GlobalService } from '../../_services/global.service';
import { concatMap, finalize, share, tap } from 'rxjs/operators';
import { CoinService } from '../coin.service';
@Injectable({
	providedIn: 'root'
})

export class PennyLaneService {

    constructor(private global: GlobalService, private coinService: CoinService) {

	 }
  getTxAccountMap(portfolioId, txId): Observable<any> {
    return this.global.httpGET('get_tx_account_map', {
      gatewayeId: 8,
      portfolioId,
      txId
    });
  }
    public getAuthUrl() {
        // get_pl_auth_url
        return this.global.httpPOST('get_pl_auth_url', {})
        .pipe( concatMap(res => {
            const cookieJSON = res.data.params;

            this.global._cookieService.set('oauth2Data', JSON.stringify(cookieJSON), null, '/')
            return of(res);
        }));
    }

    public getAuthToken(code, state, portfolioId) {
        return this.global.httpPOST('pl_v_code', {
            'code': code,
            'state': state,
            'portfolio_id': portfolioId
        })
    }

    public getGlAccounts(page= 1) {
        return this.global.httpPOST('pl_get_accounts', {
            'page': page,
            'portfolio_id': this.global.currentPortfolioId
        })
    }

    addTxAccountMap(portfolioId, txId, creditAccountId, debitAccountId, creditAmount, debitAmount, gainAccountId, lossAccountId) {

        const body = {
            gatewayeId: 8,
            portfolioId,
            txId
        };

        if (creditAccountId !== null) {
            body['creditAccountId'] = creditAccountId;
            body['creditAmount'] = creditAmount;
        }

        if (debitAccountId !== null) {
            body['debitAccountId'] = debitAccountId;
            body['debitAmount'] = debitAmount;
        }

        if (gainAccountId !== null) {
            body['gainAccountId'] = gainAccountId;
        }


        if (lossAccountId !== null) {
            body['lossAccountId'] = lossAccountId;
        }

        return this.global.httpPOST('add_tx_account_map', body);
    }

    public getAccountsSyncStatus() {
        return this.global.httpPOST('pl_get_accounts_sync_status', {
            'portfolio_id': this.global.currentPortfolioId
        })
    }

  public syncErpAccounts() {
    return this.global.httpPOST('pl_sync_erp_accounts', {
      portfolioId: this.global.currentPortfolioId,
      gatewayId: 8,
    });

  }
}
