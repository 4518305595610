

export const METHODS = {
    GET: 'get',
    POST: 'post'
};

export const SOCIAL_LOGIN = {
    url: 'login/social',
    queryParams: {},
    routeParams: {},
    body: {},
    method: METHODS.POST
};

export const SOCIAL_LOGIN_CALLBACK = {
    url: 'login/social/callback',
    queryParams: {},
    routeParams: {},
    body: {},
    method: METHODS.GET
};