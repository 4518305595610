import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable, of } from 'rxjs';


@Injectable()
export class PolicyService {

	constructor(private global: GlobalService,
	) {

	}

	getPortfolioCreationPolicy(): Observable<any> {
		let status = null
		if(this.global._allowedEntities==-1){
			status = true
			console.log(1)
		} else {
			if(this.global.portfoliosGlobalCount < this.global._allowedEntities) {
				status	=  true
				console.log(2)
			} else {
				status 	=  false
				console.log(3)
			}
		}
		
		return of(status)
	}

	getAnalyticsPolicy() {
		return this.global._packagePolicies.features.analytics
	}

	getExchangeConnectionPolicy(): Observable<any> {
		let status = null
		if(this.global._packagePolicies.features.exchanges!=-1){
			if(this.global._exchangesConnected.length < this.global._packagePolicies.features.exchanges) {
				status	=  true
			} else {
				status 	=  false
			}
		} else {
			status = true
		}
		
		return of(status)
	}

	getUserCreationPolicy(): Observable<any> {
		let status = null
		if(this.global._packagePolicies.business.status=='true'){
			if(this.global._companyUsers.length < this.global._packagePolicies.business.members) {
				status	=  true
			} else {
				status 	=  false
			}
		} else {
			status = true
		}
		
		return of(status)
	}


	getCSVPolicy(): Observable<any> {
		return this.global._packagePolicies.features.CSV
	}


	

}
