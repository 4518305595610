import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';

import { AppComponent } from './app.component';
import { DecimalPipe } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { CWAEInterceptor } from './interceptors/cwae.interceptor';

// common components
import { CommonCAPModule } from './_components_a_common/common.module';
import { ComponentCOCommonModule } from './_components_crypto_one_commons/_components_crypto_one_common_tx/co-commons-tx.module';
import { NotFoundComponent } from './_components_a_common/404-not-found/404-not-found.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// all services
import { GlobalService } from './_services/global.service';
import { CoinService } from './_services/coin.service';
import { LibraryService } from './_services/library/library.service';
import { ExploreService } from './_services/explore/explore.service';
import { ConnectionService } from './_services/connections/connections.service';
import { AuthenticationService } from './_services/authentication/authentication.service';
import { StripeLocalService } from './_services/stripe.service';
import { PolicyService } from './_services/policy.service';
import { LoaderService } from './_services/loader/loader.service';
import { HotWalletService } from './_services/hotwallet/hotwallet.service';
import { NotificationService } from './_services//notification/notification.service';
import { PaymentsService } from './_services/payments/payments.service';
import { AddressBookService } from './_services/addressbook.service';
import { NFTService } from './_services/nft/nft.service';
import { PollService } from './_services/poll.service';

// global common components

import { NgxPlaidLinkModule } from "ngx-plaid-link"

// import { HotWalletsPageComponent } from './_components_crypto_one/hot-wallets/hot-wallets-page.component';

import { LoginPageComponent } from './_login-page/login-page.component';
import  { RestartMembershipComponent } from './_login-page/restart-membership/restart-membership.component'

import { ExchangeAPICallback } from './_components_crypto_one/exchange-api-callback/exchanges-api-callback.component';
import { SearchResultsComponent } from './_components_crypto_one/search-results/search-results.component';
import { QBCallback } from './_components_crypto_one/qb-callback/qb-callback.component';

//import { AddressBookPageComponent } from './_component_address_book/address-book/address-book-page.component'

// import { APITestPageComponent } from './_components_crypto_one/apitest/apitest.component';
import { NgxTawkComponent } from './_components_crypto_one/ngx-tawk/ngx-tawk.component';
import { InvoicePubPageComponent } from './_payments_app/invoice-pub-page/invoice-pub-page.component';

import { XeroCallbackComponent } from './_components_crypto_one/xero-callback/xero-callback.component';
import { XeroLoginComponent } from './_components_crypto_one/xero-login/xero-login.component';

import { XeroService } from './_services/gateways/xero.service';
import { QbService } from './_services/gateways/qb.service';
import { PennyLaneService } from './_services/gateways/pennylane.service';

import { ONSCallbackComponent } from './_components_crypto_one/ons-callback/ons-callback.component';
import { OracleNetSuiteService } from './_services/gateways/ons.service';

import { NgxPendoModule } from 'ngx-pendo';
import { PennylaneCallbackComponent } from './_components_crypto_one/pennylane-callback/pennylane-callback/pennylane-callback.component';



//const config: SocketIoConfig = { url: 'https://coincap.io', options: {} };


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginPageComponent,
    RestartMembershipComponent,
    // HotWalletsPageComponent,

    ExchangeAPICallback,
    SearchResultsComponent,
    //AddressBookPageComponent,

    QBCallback,
    //APITestPageComponent,
    NgxTawkComponent,
    InvoicePubPageComponent,
    XeroCallbackComponent,
    XeroLoginComponent,
    ONSCallbackComponent,
    PennylaneCallbackComponent

  ],
  imports: [
    CommonCAPModule,
    ComponentCOCommonModule,
	  BrowserModule,
    NgxPendoModule.forRoot({
      pendoApiKey: '1ed2331c-430e-4210-6c88-b21d417eab4f'
    }),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    NgxPlaidLinkModule,


    NgbModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxStripeModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ProgressbarModule.forRoot(),
  ],

  providers: [
    LoaderService,
    GlobalService,
    PollService,
    LibraryService,
    ExploreService,
    CoinService,
    ConnectionService,
    AuthenticationService,
    StripeLocalService,
    PolicyService,
    HotWalletService,
    NotificationService,
    PaymentsService,
    AddressBookService,
    NFTService,
    CookieService, DecimalPipe,
    XeroService,
    QbService,
    OracleNetSuiteService,
    PennyLaneService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CWAEInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule { }

