<div [class.left-popupmodal]="global.onboardingWindowState != 2" *ngIf="global.showOnboarding">
	<div class="modal-header bb-0 pb-0" style="background: #1c78f6; color:#fff" >
		<h5 class="w-100 pointer" (click)="sidePopupOnboarding();">Onboarding Checklist</h5>

		<button type="button" class="text-white close pull-right" aria-label="Close" (click)="minimizeOnboarding();">
			<i class="fa fa-window-minimize" aria-hidden="true"></i>
		</button>
		<!-- <button type="button" class="text-white close pull-right" aria-label="Close" (click)="sidePopupOnboarding();">
			<i class="fa fa-window-maximize" aria-hidden="true"></i>
		</button> -->
		<button type="button" class="text-white close pull-right" aria-label="Close" (click)="emitMaximizeOnboarding()" *ngIf="global.onboardingWindowState != 2">
			<i class="fa fa-expand" aria-hidden="true"></i>
		</button>
		<button type="button" class="text-white close pull-right" aria-label="Close" (click)="emitCompressOnboarding()" *ngIf="global.onboardingWindowState == 2">
			<i class="fa fa-compress" aria-hidden="true"></i>
		</button>
		<button type="button" class="text-white close pull-right" aria-label="Close" (click)="closeOnboarding()">
			<i class="fa fa-times" aria-hidden="true"></i>
		</button>
	</div>
	<div style="background: #1c78f6; color:#fff">
		<div class="row">
			<div class="col-6 pt-2 pb-3 pl-4 ml-2">
				<progressbar *ngIf="global._userDetails['onboarding_stage'] != -1" class="progress-striped" max="{{global.tutorialSteps.length}}" value="{{global._userDetails['onboarding_stage']}}" type="info"></progressbar>
				<progressbar *ngIf="global._userDetails['onboarding_stage'] == -1" class="progress-striped" max="1" value="1" type="info"></progressbar>
			</div>
		</div>
	</div>
	<div class="modal-body" *ngIf="global.onboardingWindowState != 0">
		<div class="row mb-4" *ngIf="global.onboardingMode == 1">
			<div class="col-1">
				<button class="btn btn-hr-min pull-lrft" (click)="global.onboardingMode = 0">
					<i class="fa fa-arrow-left" aria-hidden="true"></i>
				</button>
			</div>
			<div class="col text-center">
				<span class="h5">
					{{global.currentStepInfo.title}}
				</span>
			</div>
			<!-- <div class="col-1">
				<button class="btn btn-hr-min pull-right" (click)="goToOnboardingStepNext()">
					<i class="fa fa-arrow-right" aria-hidden="true"></i>
				</button>
			</div> -->
		</div>
		
		<div style="min-height: 55vh;max-height: 55vh;overflow-y: scroll;overflow-x: hidden;" >
			
			<ng-container *ngIf="global.onboardingMode == 0">
				<p>
					Welcome to Cryptoworth
					<img style="vertical-align: sub;" src="{{global.FE_IMG_URL}}img/emojis/party-popper.svg" height="30px">
					<br/>
					Please take some time to go through the checklist to learn the software.
				</p>
				<div class="media text-muted pt-2 border w-100" *ngFor="let step of global.tutorialSteps; let i = index">
					<i class="font-disabled icon-md fa fa-circle-o text-grey ml-2 pr-2" *ngIf="step.id >= global._userDetails['onboarding_stage'] && global._userDetails['onboarding_stage'] != -1" aria-hidden="true"></i>
					<i class="fa fa-check-circle icon-md ml-2 pr-2 text-success" *ngIf="step.id < global._userDetails['onboarding_stage'] || global._userDetails['onboarding_stage'] == -1" aria-hidden="true"></i>
					<div class="media-body mb-0 lh-125" [class.font-disabled]="step.id > global._userDetails['onboarding_stage']">
						<div class="d-flex justify-content-between align-items-center w-100">
							<p class="text-gray-dark" >
								{{step.title}} 
								<!-- <small *ngIf="step.id == 0"> (required)</small> -->
							</p>
							<span class="d-block ml-2 mb-2"> 
								<button type="button" class="btn btn-primary ml-2 mr-2" (click)="goToOnboardingStep(step)" *ngIf="step.id == global._userDetails['onboarding_stage']">
									Start
								</button>
								<button type="button" class="btn btn-hr-min ml-2 mr-2" (click)="goToOnboardingStep(step)" *ngIf="step.id < global._userDetails['onboarding_stage'] || global._userDetails['onboarding_stage'] == -1">
									View
								</button>
							</span>
						</div>
					</div>
				</div>
				<br/>
			</ng-container>

			<ng-container *ngIf="global.onboardingMode == 1">

				
				<ng-container *ngIf="global.currentOnboardingStep == 0">
					<p>
						Welcome to {{global.appName}} Thank you for choosing our product and we are delighted to onboard you into the system. 
						We continuously update our system to meet the fast and growing demands of the crypto ecosystem. Please checkout the video to get started with Cryptoworth's basics. 
					</p>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/uKQlRhavZrs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					<br/>
					<br/>
					<p class="mb-0">
						Create an account on the 
						<a href="https://help.cryptoworth.com/" target="_blank" class="text-primary pointer">
								Help Center	
								<i class="fa fa-external-link" aria-hidden="true"></i>
						</a>. Help center will help you raise tickets for your requests or issues.
					</p>
					<br/>
					<p class="lead text-bold mb-0">
						Maintaining a Sandbox Workspace
					</p>
					<p>
						Financial reports are crucial for your organizational success. So we recommend you maintain a sandbox workspace to test our connections, behaviours and different configurations. In the sanbox, you will be able to 
						test new features, different strategies available.
					</p>
					

				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 1">
					<p>
						Take a minute to invite your team members. You can add admin level users and read-only users. Users outside your organization can be added as external users. Go to 
						<span class="text-primary pointer" (click)="coinService.gotoLink('company', global._companyDetails.id)">Organization Settings</span> to add users to your team.
					</p>
					
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000628816-user-management-basics" target="_blank" class="text-primary pointer">
							User Management Basics
							<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000600779-how-to-add-company-users" target="_blank" class="text-primary pointer">
						How to Add Company Users
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000599802-how-to-add-external-users" target="_blank" class="text-primary pointer">
						How to Add External Users
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 2">
					<p>
						All activity in {{global.appName}} is aggregated to a workspace. It is important to create the workspace with the correct configurations. You can always change workspace 
						configurations in the workspace settings. Make sure you have the following setup correctly:
					</p>
					<ul>
						<li>
							Timezone
							<br/>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000587412-how-to-set-a-portfolio-timezone" target="_blank" class="small text-primary pointer">
									Read more
									<!-- <i class="fa fa-external-link" aria-hidden="true"></i> -->
							</a>
							<br/>
							<br/>
						</li>
						<li>
							Currency
							<br/>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000633004-decimal-precision" target="_blank" class="small text-primary pointer">
									Read more
									<!-- <i class="fa fa-external-link" aria-hidden="true"></i> -->
							</a>
							<br/>
							<br/>
						</li>
						<li>
							Decimal Accuracy
							<br/>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000633004-decimal-precision" target="_blank" class="small text-primary pointer">
									Read more
									<!-- <i class="fa fa-external-link" aria-hidden="true"></i> -->
							</a>
							<br/>
							<br/>
						</li>
						<li>
							FMV Settings
							<br/>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000623442-how-to-set-your-fair-market-value-settings" target="_blank" class="small text-primary pointer">
									Read more
									<!-- <i class="fa fa-external-link" aria-hidden="true"></i> -->
							</a>
							<br/>
							<br/>
						</li>
						<li>
							Calculation Settings
						</li>
					</ul>
					<p>
						If you created the account online using the free trial, then you must discard your current workspace and create new workspace prior to going live after the product is upgraded. Go to 
						<span class="text-primary pointer" (click)="coinService.gotoLink('workspaces')">Workspaces</span> page.
					</p>
					<p>
						To continue with the rest of the onboarding, please create and open your workspace. 
					</p>
					
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000628130-how-to-convert-a-private-portfolio-to-company-portfolio" target="_blank" class="text-primary pointer">
							Converting Private Workspaces to Company Workspaces
							<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 4">
					<p>
						Most organizations use a general ledger software like QuickBooks, Xero, Netsuite or Sage. If you are using one, you should connect your general ledger software to {{global.appName}} to 
						utilize the automations that are available to you.
					</p>
					QuickBooks
					<ul>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000600039-how-to-synchronize-to-quickbooks" target="_blank" class="text-primary pointer">
								How to Synchronize to QuickBooks <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000634865-how-to-use-connection-level-mapping-for-quickbooks" target="_blank" class="text-primary pointer">
								How to use Connection Level Mapping for QuickBooks <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000641908-how-to-sync-unrealized-gain-loss-into-quickbooks" target="_blank" class="text-primary pointer">
								How To Sync Unrealized Gain/Loss Into QuickBooks <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000629725-how-to-import-your-quickbooks-chart-of-accounts" target="_blank" class="text-primary pointer">
								How To Import Your QuickBooks Chart of Accounts <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
					</ul>

					Xero
					<ul>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000621628-how-to-synchronize-to-xero" target="_blank" class="text-primary pointer">
								How to Synchronize to Xero <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000634868-how-to-use-connection-level-mapping-for-xero" target="_blank" class="text-primary pointer">
								How to use Connection Level Mapping for Xero <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
						<li>
							<a href="https://help.cryptoworth.com/support/solutions/articles/70000629726-how-to-import-your-xero-chart-of-accounts" target="_blank" class="text-primary pointer">
								How To Import Your Xero Chart of Accounts <i class="fa fa-external-link" aria-hidden="true"></i>
							</a>
						</li>
					</ul>

					<p>
						If you have questions regarding other software such as Netsuite, Sage, SAP etc, please contact your account manager. 
					</p>
					
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000642263-how-to-sync-virtual-journals" target="_blank" class="text-primary pointer">
						How To Sync Virtual Journals
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 3">
					<p>
						{{global.appName}} supports over 1000 intgerations to web3 data sources like blockchains, exchanges, custody providers, DeFi protocols etc. The data syncing is in read-only mode and any permission
						needed from centralized data sources (such as exchanges and custody providers) require read-only keys only. 
						You can add connections one at a time or if you have a large number of connections, you can use the bulk connection import feature. 
						<a href="https://help.cryptoworth.com/support/solutions/articles/70000600438-how-to-use-bulk-wallet-import" target="_blank" class="text-primary pointer">
							How to Use Bulk Wallet Import
								<i class="fa fa-external-link" aria-hidden="true"></i>
						</a>
					</p>
					<p class="lead text-bold mb-0">Data Sanity</p>
					<p>
						Make sure connections have acceptable sanity levels. Run sanity checks on all your connections to make sure the wallets are syncing as you expect. 
						Do not move into the next steps until and unless all the connections are synced with all the data you need. Make sure all the tokens have fair-market values, all the transactions are available. 
					</p>
					<p class="lead text-bold mb-0">Data Accuracy</p>
					<p>
						Cryptoworth’s team agonize on data accuracy and reliability when it comes to ingestion of all types of data. However the software ingests data from multiple data providers. 
						The data providers of integrations (blockchains, exchanges, custody platforms, DeFi protocols etc) have a high chance of performing unannounced abnormal service states and have been 
						known to provide unreliable or inaccurate data sporadically. Cryptoworth is equipped with tools such as sanity checkers with configurable decimal thresholds to detect any discrepancies 
						in the data. Cryptoworth does not guarantee 100% accuracy of any data ingested, calculated or reported. The user’s must ensure that the reports are accurate and 
						Cryptoworth Corporation is not liable for any inconveniences caused by inaccuracy of information. You will be using any information provided at your own risk. 
						Please see our  
						<a href="https://cryptoworth.com/legal/disclaimer" target="_blank" class="text-primary pointer">
							disclaimer (https://cryptoworth.com/legal/disclaimer) 
							<i class="fa fa-external-link" aria-hidden="true"></i>
						</a> and <a href="https://cryptoworth.com/legal/disclaimer" target="_blank" class="text-primary pointer">
							terms of use (https://cryptoworth.com/legal/disclaimer)
							<i class="fa fa-external-link" aria-hidden="true"></i>
						</a>
					</p>
					<p>
						Please pay extra attention to your data on beta connections. Cryptoworth flags integrations as beta for multiple reasons including the following but not limited to:
					</p>
					<ul>
						<li>
							The product of the integration is new or in beta.
						</li>
						<li>
							The product of the integration has known issues either sporadic or recurring.
						</li>
						<li>
							The product documentation is unreliable.
						</li>
						<li>
							Cryptoworth’s confidence in the data source is not satisfactory. 
						</li>
					</ul>
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000626412-how-to-import-transactions-manually" target="_blank" class="text-primary pointer">
						How To Import Transactions Manually
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000154806-creating-a-virtual-wallet" target="_blank" class="text-primary pointer">
						Creating a Virtual Wallet
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 5">
					<p>
						{{global.appName}} allows transaction classification via a chart of accounts or labeling. Users can also set up classifications via automated rules. It is highly recommended to set up and use rules to classify 
						transactions in the ledger.
					</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000587075-creating-and-assigning-classifications" target="_blank" class="text-primary pointer">
						Creating and Assigning Classifications
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000622503-creating-and-assigning-classification-rules" target="_blank" class="text-primary pointer">
						Creating and Assigning Classification Rules
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<br/>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/IMCSFaxcYnc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					<br/>
					<br/>
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000637105-chart-of-accounts-csv-import" target="_blank" class="text-primary pointer">
						Chart of Accounts - CSV Import
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000642735-how-to-automatically-classify-swaps" target="_blank" class="text-primary pointer">
						How To Automatically Classify Swaps
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000629203-how-to-ignore-spam-tokens-and-other-transactions" target="_blank" class="text-primary pointer">
						How To Ignore Spam Tokens And Other Transactions
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 6">
					<p>
						{{global.appName}} supports multiple calculation methods such as FIFO, WAC, LIFO etc. The calculations are executed automatically if you have enabled automatic syncing and report generation. 
					</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000584189-how-to-run-a-calculation" target="_blank" class="text-primary pointer">
						How to run a calculation
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<br/>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/ney7uoNE1Wg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					<br/>
					<br/>
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000641904-how-to-enable-audit-logs" target="_blank" class="text-primary pointer">
						How To Enable Audit Logs
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000641487-asset-treatment" target="_blank" class="text-primary pointer">
						Asset Treatment
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000621811-how-to-resolve-transaction-deviations" target="_blank" class="text-primary pointer">
						How to Resolve Transaction Deviations
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 7">
					<p class="mb-0">
						{{global.appName}} provides over 50 different reports that are available in the UI as well as via CSV/PDF exports. There are two main types of reports:
					</p>
					<ul>
						<li>
							General Reports - can be generated anytime on demand
						</li>
						<li>
							Calculation Reports - generated only after a successful calculation. 
						</li>
					</ul>
					<br/>
					<p class="mb-0">
						A common warning/error that occurs during calculation are deviation warnings. Cryptoworth has strict sanity checking built into the accounting engine. Therefore if the inventory deviates (more outflows than inflows), 
						{{global.appName}} raises a warning. Read more:
					</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000621811-how-to-resolve-transaction-deviations" target="_blank" class="text-primary pointer">
						How to Resolve Transaction Deviations
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<p class="mb-0">Once deviations are solved, you can access the reports in the UI or by exporting. Read more</p> 
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000584191-how-to-export-a-report" target="_blank" class="text-primary pointer">
						How to export a report
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<br/>
					<p class="mb-0">Help center guides</p>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000641907-turning-on-and-using-balance-snapshots" target="_blank" class="text-primary pointer">
						Turning On And Using Balance Snapshots
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
					<br/>
					<a href="https://help.cryptoworth.com/support/solutions/articles/70000641850-how-to-lock-the-transaction-period" target="_blank" class="text-primary pointer">
						How To Lock The Transaction Period
						<i class="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</ng-container>

				<ng-container *ngIf="global.currentOnboardingStep == 8">
					<p class="mb-0">
						Congratulations! You have almost completed {{global.appName}}'s onboarding checklist. On a final note, please ensure you understand the following points. You can always reach our customer support service via your 
						account manager, chat, Help Center or email.
					</p>
					<br/>
					<p class="lead text-bold mb-0">
						Fair-Market Values
					</p>
					<p>
						Cryptoworth uses third party data providers to get fair-market values. The data granularity   depends on the market provider and the timestamp the data was queried. Please ensure the granularities are in an acceptable level and if there is additional  implementation needed, please contact the support team. 
						Accounting Notes
					</p>
					<p class="lead text-bold mb-0">
						Accounting Notes
					</p>
					<ul>
						<li>
							Cost of Goods Sold - Also represented as Cost Basis on disposition transactions (Sends,Sells) in the ledger.  This value includes any fees associated - referred as internal fees - in 
							transactions (such as mining fees) or transactions that are used to pay only a fee (such as trading fees) - referred to as external transactions. General Ledger 
							synchronizations pushes Cost basis values in disposition transactions with the fees included. 
						</li>
						<li>
							In the app configuration, if a fee account is mapped, the fees are pushed separately to a designated fee account (if not designated, then to the default account). It is important that the user takes this into an account when doing the final reconciliation in the main ledger in an ERP.  
						</li>
						<li>
							As a best practice, always classify all transactions. This can help avoid various errors such as duplicates
						</li>
					</ul>
					<p class="lead text-bold mb-0">
						General Notes
					</p>
					<p>

						All known issues on blockchains, accounts and apps are reported in the Help Center. Please be sure to checkout the help center’s Issue section here:
						<a href="https://help.cryptoworth.com/support/solutions/folders/70000480932" target="_blank">
							Cryptoworth - Common Issues <i class="fa fa-external-link" aria-hidden="true"></i>
						</a>
						
					</p>
				</ng-container>

			</ng-container>
		</div>
	</div>
	<div class="modal-footer" *ngIf="global.onboardingWindowState != 0">
		<div class="w-100" *ngIf="global._userDetails['onboarding_stage'] != -1">
			<ng-container *ngIf="global.onboardingMode == 1">
				<ng-container *ngIf="!onboardingMarking">
					<button type="button" class="pull-left btn btn-primary" (click)="markOnboarding()" *ngIf="global.currentOnboardingStep >= global._userDetails['onboarding_stage']">
						<span *ngIf="global.tutorialSteps.length > global.currentStepInfo.id + 1">Mark as Complete</span>
						<span *ngIf="global.tutorialSteps.length == global.currentStepInfo.id + 1">Finish Onboarding</span>
					</button>
					<button type="button" class="pull-left btn btn-hr-min disabled" disabled *ngIf="global.currentOnboardingStep < global._userDetails['onboarding_stage']" >
						<i class="fa fa-check-circle pr-2 text-success" aria-hidden="true"></i> Already Complete
					</button>
				</ng-container>
				<ng-container *ngIf="onboardingMarking">
					<button type="button" class="pull-left btn btn-primary disabled" disabled> 
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> 
						<span *ngIf="global.tutorialSteps.length > global.currentStepInfo.id + 1">Mark as Complete</span>
						<span *ngIf="global.tutorialSteps.length == global.currentStepInfo.id + 1">Finish Onboarding</span>
					</button>
				</ng-container>
			</ng-container>
			<span class="text-muted text-underline pointer ml-3" (click)="skipOnboardingConfirm(skipModal)">Skip for now</span>
		</div>

		<div class="w-100" *ngIf="global._userDetails['onboarding_stage'] == -1">
			<button type="button" class="pull-left btn btn-hr-min disabled" disabled>
				<i class="fa fa-check-circle pr-2 text-success" aria-hidden="true"></i> Onboarding Completed
			</button>
		</div>
	</div>
</div>


<ng-template #skipModal let-c="close" let-d="dismiss">

    <div class="modal-header bb-0">
        <!-- <h4 class="modal-title pull-left">Layout</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
		We highly recommend you complete the onboarding before producing reports for your organization. You can always re-open the checklist by clicking on the 
		<span class="text-bold"><i style="font-size: 19px;" class="fa fa-question-circle"  aria-hidden="true"></i> Help</span> button.
    </div>
	<div class="modal-footer">
		<button class="btn btn-primary" (click)="skipOnboardingStep();d('Cross click')">
			Skip For Now
		</button>
	</div>

</ng-template>
