<div class="card" *ngIf="addressInfo != null">
    <div class="card-body bg-light">
        <div class="col-md-10">
            <p class="mb-0 text-bold"> {{ addressInfo.name }} </p>
            Email: 
            <span *ngIf="addressInfo.email != null">{{addressInfo.email}}</span>
            <span *ngIf="addressInfo.email == null">-</span>
            <br/>
            Tel: 
            <span *ngIf="addressInfo.tel != null">{{addressInfo.tel}}</span>
            <span *ngIf="addressInfo.tel == null">-</span>
        </div>
    </div>
</div>