import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { ActivatedRoute, Router, Params} from '@angular/router';

import { StripeLocalService } from '../../_services/stripe.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-restart-membership',
  templateUrl: './restart-membership.component.html'
})
export class RestartMembershipComponent implements OnInit {
	@ViewChild('paymentProcessingModal') paymentProcessingModal;

	public packages = null
	public billingPreviewMode = 'annual'

	public currentPackage = null
	public restartMemberShipDetails = {}

	public formState = 0
	public divState = 0
	public stripeChargeToken = null
	public modalRef
	public errorStatus

	constructor(public global: GlobalService, private route:ActivatedRoute, private router:Router, private modalService: NgbModal, public stripeLocalService: StripeLocalService,) {
		
	}

	ngOnInit() {
		this.restartMemberShipDetails['billingType']='yearly'
		if(this.global.isAuthenticated()){
			this.router.navigate(['home']);
			this.global.doLoginPreRequisites()
		} else {
			this.getPackages()
			this.stripeLocalService.stripeInit()
		}
	}

	ngOnDestroy(){
		this.global.loginTempDetails = null
	}

	getPackages(){
		
		this.global.httpGETNoAuth('get_user_package_Details', {uId:this.global._userDetails['id']}).subscribe(response => {
			if(response.status==true){
				this.currentPackage = response.data
			} 
		}, error => {
		});

	}

	purchaseCheck(){
		if(
			this.restartMemberShipDetails['billingType'] != null
		){
			this.stripeCheck()
			
		} 

	}

	stripeCheck(){
		this.formState = 1
		this.stripeLocalService.stripeCheck().subscribe(result => {
			if (result.token) {
				this.divState = 1
				this.openPaymentProcessingModal(this.paymentProcessingModal)
				this.stripeChargeToken = result.token.id
				this.purchase()
			} else if (result.error) {
				this.divState = 2
				this.formState = 0
				console.log(result.error.message);
				return false;
			}
      });
	}

	openPaymentProcessingModal(modal){
		this.modalRef = this.modalService.open(modal, {backdrop: 'static', keyboard: false})
	}

	public payErrorMessgae
	purchase(){
		this.global.http.post(this.global.API_URL + 'restart_membership', {
				loginDetails: this.global.loginTempDetails,
				restartMemberShipDetails:this.restartMemberShipDetails,
				chargeToken:this.stripeChargeToken,
				deviceInfo: this.global.deviceInfo,
				ipInfo:this.global.ipInfo
			}
		)
		.subscribe(response => {

			this.divState = 0
			this.formState = 0
			let newResponse = JSON.parse(response['_body'])
			if(newResponse['status']==true){
				this.divState = 3
				setTimeout(() => {
					this.modalRef.close()
					this.global.doLoginPreRequisites(newResponse['data'])
				}, 3000);
			} else if(newResponse['status']==false){
				this.divState = 2
				this.formState = 0
				this.payErrorMessgae = newResponse['data']['message']

				if(newResponse['code']==203){
					this.modalRef.close()
				}
				this.errorStatus = newResponse['code']
			}

		}, error => {
			this.divState = 2
			this.formState = 0
			console.log(error);// Error getting the data
		});
		
	}

}
