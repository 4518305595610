
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';
import { StripeLocalService } from '../../../_services/stripe.service';
import { ActivatedRoute, Params } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-re-payment',
  templateUrl: './re-payment.component.html',
  styleUrls: ['./re-payment.component.css']
})

export class RePaymentComponent { 

	@ViewChild('paymentProcessingModal') paymentProcessingModal;
	@Input() login:any = false
	@Input() loginDetails:any = null
	
	public key

	public pagemode = 0
	// 0 - show current package and upgrade
	// 1 - upgrade mode only

	public formState = 0
	public divState:any=0

	public stripeChargeToken
	public modalRef
	public errorStatus = 0

	constructor(public global: GlobalService,public stripeLocalService: StripeLocalService, public route:ActivatedRoute, public modalService: NgbModal) {  }

	ngOnInit(){
		window.scroll(0, 0);
		this.stripeLocalService.stripeInit()
		console.log('inputDetais: ' + this.login)

	}

	makePayment(){
		this.formState = 1
		this.global.paymentActive = true
		this.stripeLocalService.stripeCheck().subscribe(result => {
			console.log('hit')
			console.log(result)
			if (result.hasOwnProperty('error')) {
				this.divState = 2
				this.formState = 0
				this.global.paymentActive = false
				return false;
			} else {
				if (result.hasOwnProperty('token')) {
					if (result.token) {
						this.divState = 1
						this.openPaymentProcessingModal(this.paymentProcessingModal)
						this.stripeChargeToken = result.token.id
						if(this.login==false){
							this.purchase()
						} else {
							this.purchaseNoAuth()
						}
					} 
				} else {
					console.log('unsupported card')
					this.errorStatus = 444
					this.formState = 0

					setInterval(() => {
						this.errorStatus = 0
						
					}, 3000);
				}
			}
			
      }, error => {
			this.errorStatus = 444
			console.log(error);// Error getting the data
		});
	}

	openPaymentProcessingModal(modal){
		this.modalRef= this.modalService.open(modal, {size:'lg', backdrop: 'static', keyboard: false})
	}
	resetFormStates(){
		this.formState = 0
		this.global.paymentActive = false
		this.divState = 0
	}

	purchaseNoAuth(){
		this.global.httpPOSTNoAuth('do_repay_no_auth', {
				chargeToken:this.stripeChargeToken,
				loginDetails:this.loginDetails
			}
		)
		.subscribe(response => {
			this.divState = 0
			this.formState = 0
			if(response['status']==true){
				
				this.divState = 4
				this.global.loginDivState = 0
				
			} else if(response['status']==false){
				this.divState = 2
				this.formState = 0

				if(response['code']==203){
					this.modalRef.close()
				}
				this.errorStatus = response['code']
			}

		}, error => {
			this.divState = 2
			this.formState = 0
			console.log(error);// Error getting the data
		});
	}

	public payErrorMessgae
	purchase(){
		this.global.httpPOST('do_repay', {
				chargeToken:this.stripeChargeToken
			}
		)
		.subscribe(response => {
			this.divState = 0
			this.formState = 0
			if(response['status']==true){
				
				this.divState = 3
				this.global.paymentModalRef.close()
				this.global.doHeartBeat()
				
				
			} else if(response['status']==false){
				this.divState = 2
				this.formState = 0
				this.payErrorMessgae = response['data']['message']

				if(response['code']==203){
					this.modalRef.close()
				}
				this.errorStatus = response['code']
			}

		}, error => {
			this.divState = 2
			this.formState = 0
			console.log(error);// Error getting the data
		});
		
	}
}

