
<!-- Goals -->
<div class="" *ngIf="!global.addressLoading && global.addresses != null">
	<div class="input-group" *ngIf="!subAddressesForContact">

		<input class="form-control br-0 fc-mini" [(ngModel)]="search" placeholder="Quick search">

		<div class="input-group-append">
			<span class="input-group-text bg-white fc-mini">
				<i class="fa fa-times  pointer" (click)="resetSearch()" [class.text-primary]="specificSearch" [class.text-white]="!specificSearch" aria-hidden="true" ></i>
			</span>
		</div>
		<div class="input-group-append">
			<span class="input-group-text bg-white fc-mini" (click)="searchAddress(true)" *ngIf="searching == 0">
				<i class="fa fa-search" aria-hidden="true"></i>
			</span>
			<span class="input-group-text bg-white fc-mini" disabled *ngIf="searching != 0">
				<i class="fa fa-search" aria-hidden="true"></i>
			</span>
		</div>

	</div>
	<ng-container *ngIf="subAddressesForContact == null">
		<ul class="list-group sb-div" *ngIf="!searchMode">
			<li style="white-space: nowrap;" class="list-group-item zbr pointer" [class.active]="viewingAddress!=null && viewingAddress.id == address.id" *ngFor="let address of global.addresses; let i = index" (click)="addressSelectAction(address, i)">
				<span>{{ global.shortenNameForAddressBookDisplay(address.name) }}</span>
				<span *ngIf="listShowEmail">
					<br/>
					<small>Email: {{ address.email }}</small>
				</span>
				<span *ngIf="address.type == 1" class="pull-right"><i class="fa fa-user pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
				</span>
				<span *ngIf="address.type == 2" class="pull-right"><i class="fa fa-building pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
				</span>
				<span *ngIf="address.type == 3" class="pull-right"><i class="fa fa-briefcase pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
				</span>
			</li>
		</ul>
	
		<ul class="list-group sb-div border-right" *ngIf="searchMode">
			<ng-container *ngIf="searchResults != null && searchResults != undefined">
				<li style="white-space: nowrap;" class="list-group-item zbr pointer" [class.active]="viewingAddress!=null && viewingAddress.id==address.id" *ngFor="let address of searchResults; let i = index" (click)="addressSelectAction(address, i)">
					<span>{{ global.shortenNameForAddressBookDisplay(address.name) }}</span>
					<span *ngIf="listShowEmail">
						<br/>
						<small>Email: {{ address.email }}</small>
					</span>
					<span *ngIf="address.type == 1" class="pull-right"><i class="fa fa-user pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
					</span>
					<span *ngIf="address.type == 2" class="pull-right"><i class="fa fa-building pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
					</span>
					<span *ngIf="address.type == 3" class="pull-right"><i class="fa fa-briefcase pt-2 mr-2" ngbTooltip="Workspace address" placement="left" aria-hidden="true"></i>
					</span>
				</li>
			</ng-container>
			<ng-container *ngIf="searchResults == null || searchResults == undefined">
				<span class="text-center mt-4">No results found</span>
			</ng-container>
		</ul>
		
	</ng-container>

	<ng-container *ngIf="subAddressesForContact && subAddressSelect">

		<span class="text-primary pointer" (click)="subAddressesForContact = null"> 
			<i class="fa fa-arrow-left" aria-hidden="true"></i> Back
		</span>

		<app-address-contact-info [addressInfo]="subAddressesForContact"></app-address-contact-info>
		<div class="card bg-light">
			<div class="card-body">
				<span class="mb-2">Select Address: </span>
				<div class="card pointer card-select" *ngFor="let address of subAddressesForContact['allAddresses']" (click)="selectSubAddress(address, subAddressesForContact)">
					<div class="card-body pt-1 pb-1">
						<label *ngIf="address['label'] != null"> {{ address['label'] }} </label>
						<label *ngIf="address['label'] == null"> - </label>
						<p> {{ address['address'] }} </p>
					</div>
				</div>	

			</div>
		</div>

	</ng-container>
</div>

<ng-container *ngIf="!global.addressLoading && global.addresses == null">
	<p class="pt-4 pl-2">
		No addresses to display

	</p>
</ng-container>


<ng-container *ngIf="global.addressLoading">
	<p class="pt-4 pl-2">
		<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader.gif"> Loading
	</p>
</ng-container>