import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';
//import {jsPDF} from 'jspdf';

declare var jsPDF: any;
declare var html2pdf: any;

@Component({
	selector: 'app-invoice-view',
	templateUrl: './invoice-view.component.html'
})

export class InvoiceViewComponent implements OnInit {

    @Input() currentViewInvoice;
    @ViewChild('pdfTable') pdfTable: ElementRef;
    @ViewChild('invoiceViewDesign') invoiceViewDesign: ElementRef;

    public PDFLoading = false
    
    constructor(public global:GlobalService){

    }

    ngOnInit(){
        
    }
    

    clickDownloadPDF(){

        this.PDFLoading = true
        setTimeout(() => {
            this.PDFLoading = false
        }, 3000);

        setTimeout(() => {
            this.downloadAsPDF()
        }, 100);
    }

    downloadAsPDF() {
        const invoice = this.invoiceViewDesign.nativeElement;
        console.log(invoice);
        console.log(window);
        console.log(this.PDFLoading)
        var opt = {
            margin: 0.5,
            filename: this.currentViewInvoice['identifier'] + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(invoice).set(opt).save();

    }

}