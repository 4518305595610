import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCAPModule } from '../../_components_a_common/common.module';
import { ClassificationConnectionInfoModule } from '../_components_classification-connection/components-classification-connection-info.module';
import { AutomationViewModule } from '../_components_automation_view/automation-view.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule, BsDatepickerModule  } from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { TransactionTableComponent } from './transaction-table/transaction-table.component';
import { ClassificationSelector } from './transaction-table/classification-selector/classification-selector.component';
import { QboClassSelector } from './transaction-table/qbo-class-selector/qbo-class-selector.component';


import { TxGatewayOptionComponent } from './tx-gateway-option/tx-gateway-option.component';
import { TransactionSourceComponent } from './transaction-source/transaction-source.component';
import { TxEditorComponent } from './tx-editor/tx-editor.component';
import { TransactionAuditLogsComponent } from "./transaction-audit-logs/transaction-audit-logs.component";

import { TransactionDetailsComponent } from './transaction-details-modal/transaction-details-modal.component';
import { TCBWModalComponent } from './tcbw-modal/tcbw-modal.component';

import { JournalInfoSimComponent } from './journal-info-sim/journal-info-sim.component';
import { JournalInfoComponent } from './journal-info/journal-info.component';

import { WalletViewOverviewPageComponent } from './wallet-view-overview/wallet-view-overview.component';
import { ExchangeViewOverviewPageComponent } from './exchange-view-overview/exchange-view-overview.component';

import { InvoicesPageComponent } from '../../_payments_app/invoices-page/invoices-page.component';
import { BillsPageComponent } from '../../_payments_app/bills-page/bills-page.component';

import { TxCreditDebitMapComponent } from './tx-credit-debit-map/tx-credit-debit-map.component';

import { NFTItemDisplayComponent } from "./nft-item-display/nft-item-display.component";
import { TransactionsCostChangeComponent } from './transaction-cost-change/tranaction-cost-change.component';

import { FmvAuditInfoComponent } from "./fmv-audit-info/fmv-audit-info.component";

import { InvoiceListComponent } from '../../_payments_app/invoices-page/invoice-list/invoice-list.component';
import { BillViewComponent } from '../../_payments_app/bills-page/bill-view/bill-view.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ExchangeViewVaultsComponent } from './exchange-view-vaults/exchange-view-vaults.component';


@NgModule({
    imports: [
        CommonModule,
        CommonCAPModule,
        ClassificationConnectionInfoModule,
        AutomationViewModule,
        NgbModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CollapseModule.forRoot(),
        ProgressbarModule.forRoot(),
        MatTooltipModule,
    ],
  declarations: [
    TransactionTableComponent,
    ClassificationSelector,
    QboClassSelector,
    TxGatewayOptionComponent,
    TransactionSourceComponent,
    TxEditorComponent,
    TransactionAuditLogsComponent,
    TransactionDetailsComponent,
    TCBWModalComponent,
    JournalInfoComponent,
    JournalInfoSimComponent,
    WalletViewOverviewPageComponent,
    ExchangeViewOverviewPageComponent,
    InvoicesPageComponent,
    BillsPageComponent,
    TxCreditDebitMapComponent,
    NFTItemDisplayComponent,
    TransactionsCostChangeComponent,
    FmvAuditInfoComponent,
    InvoiceListComponent,
    BillViewComponent,
    ExchangeViewVaultsComponent

  ],
  providers: [
    TransactionTableComponent,
  ],
  exports: [
    TransactionTableComponent,
    ClassificationSelector,
    QboClassSelector,
    TxGatewayOptionComponent,
    TransactionSourceComponent,
    TxEditorComponent,
    TransactionAuditLogsComponent,
    TransactionDetailsComponent,
    TCBWModalComponent,
    JournalInfoComponent,
    JournalInfoSimComponent,
    WalletViewOverviewPageComponent,
    ExchangeViewOverviewPageComponent,
    InvoicesPageComponent,
    BillsPageComponent,
    TxCreditDebitMapComponent,
    NFTItemDisplayComponent,
    TransactionsCostChangeComponent,
    FmvAuditInfoComponent,
    InvoiceListComponent,
    BillViewComponent,
    ExchangeViewVaultsComponent
  ]
})
export class ComponentCOCommonModule { }
