
import { Component } from '@angular/core';
import { GlobalService } from '../../_services/global.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  moduleId: module.id,
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.css']
})

export class GlobalLoader { 

    quoteIndex = -1

    constructor(public global: GlobalService) {
      this.getRandomQuote()
		}

    getRandomQuote(){
      this.quoteIndex = this.global.randomIntFromInterval(0, this.global.loaderService.quotes.length)
    }

	
}

