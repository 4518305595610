import { Component, OnInit } from '@angular/core';

import { XeroService } from 'app/_services/gateways/xero.service';

@Component({
  selector: 'app-xero-login',
  templateUrl: './xero-login.component.html'
})
export class XeroLoginComponent implements OnInit {

  public code = null;
  public status = null;
  public type = null;

	constructor(
    private xeroService: XeroService
  ){

	}

  ngOnInit() : void {
		let url = this.xeroService.getLoginUrl(null, 2);
		window.open(url,"_self");
  }

}
