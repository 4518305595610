import { Injectable } from '@angular/core';
import { idLocale } from 'ngx-bootstrap';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { GlobalService } from '../../_services/global.service';

@Injectable({
  providedIn: 'root'
})

export class ReportsService {

	public historicalbalanceDetails = {
		mode: 2,
		startDate: null,
		endDate: null
	}

	public historicalbalanceByAssetDetails = {
		mode: 2,
		startDate: null,
		endDate: null
	}

	public historicalBalanceSourcesParsed=null
	public historicalBalanceSourcesParsedMode = 0
	public historicalBalanceFiltersApplied = false

	public cacheFinancialData = null;
	public financialFlowData = null;
	public cacheAccountSummary = null;

	private totalCount = 0;

	constructor(private global: GlobalService) { }

	public getTotalCount(){

		return this.totalCount;
	}

	public getLongShortGain(portfolioId:number, portfolio_tax_id:number, page:number = 1, searchKey:string = ""){

		var subject = new Subject<any>();

		this.global.httpGET('long_short_gain',{
			portfolioId: portfolioId,
			calculation_id: portfolio_tax_id,
			page,
			searchKey
		}).subscribe( res=>{

			let termGain = [];
			let assets = {};
			this.totalCount = res.data.total_records;
			for(let j in res.data.assets){

				assets[  res.data.assets[j].id ] = res.data.assets[j];
			}

			for(let i in res.data.data){

				let totalNoOfDays = 0;
				let totalShortTermGain = 0;
				let totalLongTermGain = 0;
				let totalSendAmount = 0;
				let trx = [];
				for( let j in res.data.data[i].transactions){
				let millisecods = res.data.data[i].transactions[j].disposed_at_unix - res.data.data[i].created_at_unix;
				let noOfDays = millisecods/(86400000); //1000 * 60 * 60 * 24

				if(noOfDays > totalNoOfDays) {
					totalNoOfDays = noOfDays;
				}

				if(res.data.data[i].transactions[j].term == 0 ){
					totalShortTermGain += res.data.data[i].transactions[j].gain_loss;
				}else{
					totalLongTermGain += res.data.data[i].transactions[j].gain_loss;
				}

				totalSendAmount += res.data.data[i].transactions[j].disposed_crypto_amount;
				trx.push({
					'tax_lot_id' :  '',
          'non_taxable': res.data.data[i].transactions[j].non_taxable,
          'disposed_crypto_amount': res.data.data[i].transactions[j].disposed_crypto_amount,
					'cost_basis': res.data.data[i].transactions[j].cost_basis,
					'sale_price': res.data.data[i].transactions[j].sale_price,
					'gain_loss': res.data.data[i].transactions[j].gain_loss,
					'term': res.data.data[i].transactions[j].term,
					'unix_time': res.data.data[i].transactions[j].disposed_at_unix,
					'noOfDays' : noOfDays
				});
			}
			let displaytooltip = null
			if(res.data.data[i].lot_hash != null){
				displaytooltip = res.data.data[i].lot_hash.split('-');
			}

				termGain.push({
				'id': res.data.data[i].id,
				'connection': res.data.data[i].connection ? res.data.data[i].connection : null,
        'non_taxable': res.data.data[i].non_taxable,
        'crypto_id': res.data.data[i].crypto_id,
				'asset': assets[ res.data.data[i].crypto_id ],
				'total_cost_basis' : res.data.data[i].total_cost_basis,
				'tax_lot_id' : res.data.data[i].lot_hash,
				'display_lot_id': (displaytooltip!=null)?displaytooltip[0]+'-'+displaytooltip[1]+'...':null,
				'total_sale_price': res.data.data[i].total_sale_price,
				'total_gain_loss' : res.data.data[i].total_gain_loss,
				'amount': res.data.data[i].transaction_amount,
				'total_short_term_gain_loss': totalShortTermGain,
				'total_long_term_gain_loss' : totalLongTermGain,
				'unix_time': res.data.data[i].created_at_unix,
				'totalNoOfDays' : totalNoOfDays,
				'totalSendAmount': totalSendAmount,
				'transactions': trx
				});

			}
			subject.next(termGain);
		});

		return subject.asObservable();
	}

	public getTotalTermGainLoss(portfolioId:number, portfolio_tax_id:number){
		return this.global.httpGET('get_tax_lot_total',{
			portfolioId: portfolioId,
			calculation_id: portfolio_tax_id
		});
	}

	public getFinancialStatemtData(portfolioId:number, portfolio_tax_id:number , datePickerValues :string = null): Observable<any>{

		let subject = new ReplaySubject(1);

		let isSame = true;
		if(this.cacheFinancialData === null){
			console.log("getFinancialStatemtData | null check : false ")
			isSame = false;
		}else{

			if(this.cacheFinancialData.portfolioId !== portfolioId){
				console.log("getFinancialStatemtData | portfolio id check : false ")
				isSame = false;
			}

			if(this.cacheFinancialData.calculation_id !== portfolio_tax_id){
				console.log("getFinancialStatemtData | tax id check : false ")
				isSame = false;
			}

			if(this.cacheFinancialData.datePickerValues !== datePickerValues){
				console.log("getFinancialStatemtData | from date check : false ")
				isSame = false;
			}

			// if(this.cacheFinancialData.toDate !== toDate){
			// 	console.log("getFinancialStatemtData | to date check : false ")
			// 	isSame = false;
			// }
		}

		if(!isSame){
			let options = {
				portfolioId: portfolioId,
				calculation_id: portfolio_tax_id,
			};

			if(datePickerValues !== null){
				options['dateFilter'] = datePickerValues;
			}

			// if(toDate !== null){
			// 	options['toDate'] = toDate;
			// }

			this.global.httpPOST('get_financial_stament_report',options)
			.subscribe(
				res=>{
					this.cacheFinancialData = {
						portfolioId: portfolioId,
						calculation_id: portfolio_tax_id,
						dateFilter: datePickerValues,
						data: res.data
					};
					subject.next(res);
					//
					// this.global.appSettingsStateChange.emit(true);
					subject.complete();
				},
				error=>{
					subject.error(error);
					subject.complete();
				});

				return subject;
		}else{
			return of({
				code: 200,
				data: this.cacheFinancialData.data,
				status: true
			});
		}

	}

	historicalBalanceFetchTime = null

	public getFinancialFlowData(portfolioId:number, portfolio_tax_id:number ,datePickerValues = null): Observable<any>{

		let subject = new ReplaySubject(1);
		let isSame = true;
		let options = {
			portfolioId: portfolioId,
			calculation_id: portfolio_tax_id,
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		// if(toDate !== null){
		// 	options['toDate'] = toDate;
		// }

		if(this.financialFlowData === null){
			console.log("getFinancialStatemtData | null check : false ")
			isSame = false;
		}else{

			if(this.financialFlowData.portfolioId !== portfolioId){
				console.log("getFinancialStatemtData | portfolio id check : false ")
				isSame = false;
			}

			if(this.financialFlowData.calculation_id !== portfolio_tax_id){
				console.log("getFinancialStatemtData | tax id check : false ")
				isSame = false;
			}

			if(this.financialFlowData.datePickerValues !== datePickerValues){
				console.log("getFinancialStatemtData | from date check : false ")
				isSame = false;
			}

			// if(this.financialFlowData.toDate !== toDate){
			// 	console.log("getFinancialStatemtData | to date check : false ")
			// 	isSame = false;
			// }
		}

		if(isSame){
			return of({
				code: 200,
				data: this.financialFlowData.data,
				status: true
			});
		}else{
			this.global.httpPOST('get_financial_flow_report', options)
			.subscribe( resp=>{
				this.financialFlowData = {
					portfolioId: portfolioId,
					calculation_id: portfolio_tax_id,
					dateFilter: datePickerValues,
					// toDate: toDate,
					data: resp.data
				}

				subject.next(resp);
				subject.complete();
			}, error=>{
				subject.error(error);
				subject.complete();
			});
			return subject;
		}

	}

	public calculateImpairment(portfolioId:number, reportDate:string = null) {
		let options = {
			portfolioId: portfolioId
		};
		if(reportDate !== null){
			options['reportDate'] = reportDate;
		}
		return this.global.httpGET('calc_impairment', options);
	}

	public getImpairmentReport(portfolioId:number, reportDate:string = null) {
		let options = {
			portfolioId: portfolioId
		};
		if(reportDate !== null){
			options['reportDate'] = reportDate;
		}
		return this.global.httpGET('get_impairment_report', options);
	}

	public setImpairmentForTransaction(portfolioId:number, transactionId:number[], toImpair:boolean) {
		let options = {
			portfolioId: portfolioId,
			transactionId: transactionId,
			impairFlag: toImpair,
		};

		console.log('Setting Impairment for transaction!');
		console.log(transactionId);

		return this.global.httpGET('set_impairment_value', options);
	}

	public getErpUglLog(portfolioId, gatewayId, calculationId, datePickerValues= null, page = 1){

		let options = {
			portfolio_id: portfolioId,
			gateway_id: gatewayId,
			calculate_id: calculationId,
			page
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		// if(toDate !== null){
		// 	options['toDate'] = toDate;
		// }

		return this.global.httpPOST('erp_ugl_log',options);
	}

	public getErpUglLogCount(portfolioId, gatewayId, calculationId, fromDate= null, toDate= null, ){

		let options = {
			portfolio_id: portfolioId,
			gateway_id: gatewayId,
			calculate_id: calculationId,
		};

		if(fromDate !== null){
			options['fromDate'] = fromDate;
		}

		if(toDate !== null){
			options['toDate'] = toDate;
		}

		return this.global.httpPOST('erp_ugl_log_count',options);
	}

	public getErpClassificationLog(portfolioId, gatewayId, calculationId, datePickerValues= null,){
		let options = {
			portfolio_id: portfolioId,
			gateway_id: gatewayId,
		};
		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		if(calculationId !== null){
			options['calculate_id'] = calculationId;
		}

		return this.global.httpPOST('erp_log_classification',options);
	}

	public getJournalFormat(gatewayId, txIds=[]){
		//get_journal_map
		let options = {
			'portfolio_id':this.global.currentPortfolioId,
			'gateway_id': gatewayId,
			'txIds': txIds

		}
		return this.global.httpPOST('get_journal_map', options);
	}

	public getErpLog(portfolioId:number, calculationId:number, gatewayid:number, txId=null, datePickerValues = null,page:number= 1, ignorCalculateId=false){
		let options = {
			portfolio_id: portfolioId,
			gateway_id: gatewayid,
			// calculate_id: calculationId,
			page,
			ignorCalculateId
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		if(calculationId !== null){
			options['calculate_id'] = calculationId;
		}

		if(txId !== null){
			options['txId'] = txId;
		}

		return this.global.httpPOST('erp_log', options);
	}

	public getTxPaymentInfo(gateway_id, txId){

		let options = {
			portfolio_id: this.global.currentPortfolioId,
			gateway_id,
			txId
		};

		return this.global.httpPOST('get_tx_payment_info', options);
		//get_tx_payment_info
	}

	public getErpLogCount(portfolioId:number, calculationId:number, gatewayid:number, datePickerValues = null){
		let options = {
			portfolio_id: portfolioId,
			gateway_id: gatewayid,
			// calculate_id: calculationId
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		if(calculationId !== null){
			options['calculate_id'] = calculationId;
		}

		return this.global.httpPOST('erp_log_count', options);
	}

    public getClassificationsByAsset(portfolioId:number, portfolio_tax_id:number ,datePickerValues){

		let options = {
			portfolioId: portfolioId,
			calculation_id: portfolio_tax_id,
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		// if(toDate !== null){
		// 	options['toDate'] = toDate;
		// }

		return this.global.httpPOST('get_classification_by_asset',options)
	}

	public getAssetsByClassification(portfolioId:number, portfolio_tax_id:number ,datePickerValues){

		let options = {
			portfolioId: portfolioId,
			calculation_id: portfolio_tax_id,
		};

		if(datePickerValues !== null){
			options['dateFilter'] = datePickerValues;
		}

		return this.global.httpPOST('get_asset_by_classification',options)
	}

	public getAccountSummary(portfolioId:number, portfolio_tax_id:number ,datePickerValues, force=false): Observable<any>{

		let subject = new ReplaySubject(1);

		let isSame = true;
		if(this.cacheAccountSummary === null){

			isSame = false;
		}else{

			if(this.cacheAccountSummary.portfolioId !== portfolioId){

				isSame = false;
			}

			if(this.cacheAccountSummary.calculation_id !== portfolio_tax_id){

				isSame = false;
			}

			if(this.cacheAccountSummary.datePickerValues !== datePickerValues){

				isSame = false;
			}

		}

		if(!isSame || force){
			let options = {
				portfolioId: portfolioId,
				calculation_id: portfolio_tax_id,
			};

			if(datePickerValues !== null){
				options['dateFilter'] = datePickerValues;
			}

			// if(toDate !== null){
			// 	options['toDate'] = toDate;
			// }

			this.global.httpPOST('get_account_summary',options)
			.subscribe(
				res=>{
					this.cacheAccountSummary = {
						portfolioId: portfolioId,
						calculation_id: portfolio_tax_id,
						dateFilter: datePickerValues,
						data: res.data
					};
					subject.next(res);
					//
					// this.global.appSettingsStateChange.emit(true);
					subject.complete();
				},
				error=>{
					subject.error(error);
					subject.complete();
				});

				return subject;
		}else{
			return of({
				code: 200,
				data: this.cacheAccountSummary.data,
				status: true
			});
		}

	}

	public getClassificationByDate(portfolioId:number, portfolio_tax_id:number , dateFilter, type =2){
		//get_classifications_by_date
		let options = {
			"portfolioId" : portfolioId,
			"calculation_id": portfolio_tax_id,
			"datefilter": dateFilter,
			"type": type
		};
		return this.global.httpPOST('get_classifications_by_date',options)
	}

	public makeReportRequest(portfolioId, calculation_id){
        return this.global.httpPOST('create_classifications_summary_report', {
            'portfolio_id': portfolioId,
            'calculation_id': calculation_id
        });
    }

	public getClassificationSummaryStatus(portfolioId, taxId){
        //report_summary_get_status

        return this.global.httpPOST('classification_summary_get_status', {
            'portfolio_id': portfolioId,
            'tax_id': taxId
        });
    }

	public getAddressReport(dateFilter, page=1, mode=1){
		return this.global.httpPOST('get_address_report', {
            'portfolio_id': this.global.currentPortfolioId,
			"datefilter": dateFilter,
            'page': page,
			'mode': mode
        });
	}

}
