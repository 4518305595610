<form class="form-signin text-center">
	<p class="mb-3">
		<a target="_blank" href="https://cryptoworth.com/legal/terms" target="_blank">Terms</a>  
		&nbsp;&nbsp;
		<a target="_blank" href="https://cryptoworth.com/legal/privacy" target="_blank">Privacy</a> 
		&nbsp;&nbsp;
		<a target="_blank" href="https://cryptoworth.com/legal/disclaimer" target="_blank">Disclaimer</a> 
		<br/>
	</p>
</form>
	