import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GlobalService } from './global.service';

import { StripeService, Elements, Element as StripeElement, StripeInstance, StripeFactoryService } from "ngx-stripe";

@Injectable()
export class StripeLocalService {

	stripe: StripeInstance = null;
	elements: Elements = null;
	card: StripeElement = null;
	
	stripeTest: FormGroup;

	public stripeKey = null
	constructor(
		public stripeFactory: StripeFactoryService,
		public stripeService:StripeService,
		private global: GlobalService,
		private fb: FormBuilder
	) {

	}

	initiateStripe(): Observable<any> {
		
		this.stripe = null
		this.elements = null
		this.card = null

		// let stripe = Stripe('pk_test_dqBqcvGbOBoYDmmcrKvX5eFt00OdwqRTLa');
		if(this.stripeKey==null){
			return this.global.httpGETNoAuth('get_stripe_key', {})
			
		} else {
			let response;
			response['status'] = true
			response['data'] = this.stripeKey;
			return response;
		}
	}
	
	stripeInit(){
		this.initiateStripe().subscribe(response => {
			if(response['status']==true){
					this.stripeTest = this.fb.group({
						name: ['', [Validators.required]]
					});

					this.stripe = this.stripeService.setKey(response['data']);

					this.stripe.elements()
					.subscribe(elements => {
						this.elements = elements;
						// Only mount the element the first time
						if (!this.card) {
						this.card = this.elements.create('card', {	
						hidePostalCode: true,
							style: {
							base: {
								iconColor: '#666EE8',
								color: '#31325F',
								lineHeight: '40px',
								fontWeight: 300,
								fontSize: '14px',
								'::placeholder': {
								color: '#CFD7E0'
								}
							}
							}
						});
						this.card.mount('#card-element');
						}
					});
			} 

		}, error => {
			console.log(error);// Error getting the data
		});
		
	}

	stripeCheck(): Observable<any>{
		return this.stripe.createToken(this.card, { name })
		
	}

	

}
