import { Injectable} from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class XeroService {

    private clientId:string = "B67AC4CCD9F5415986727CB0529E7466";
    private redirect_uri: string = "https://localhost:4200/xero_callback";
    private loginUrl:string = "https://login.xero.com/identity/connect/authorize";
    private scope:string = 'openid email profile offline_access assets projects accounting.settings accounting.transactions accounting.contacts accounting.journals.read accounting.reports.read accounting.attachments';
    private code:string;
    public _selectedUser = null;
    public _selectedOrganization = null;
    public _lastUpdateTime = null;

    public selectedUser = null;
    public selectedOrganization = null;


    public organizations = [];
    public users = [];

    constructor(public global: GlobalService, ){
        this.clientId = environment['xeroClientId'];
        this.redirect_uri = environment['xeroRedirectUrl'];
    }

    setLastUpdateTIme(value){
        this._lastUpdateTime = value;
    }

    getLastUpdateTIme(){
        return this._lastUpdateTime;
    }

    setUsers(selectedUser, users){
        this.selectedUser = selectedUser;
        this.users = users;

        if(selectedUser == null){
            this._selectedUser = null;
        }else{
            for(let i in users){
                if(users[i].UserID == selectedUser){
                    this._selectedUser = users[i];
                    break;
                }
            }
        }

    }

    setOrganizations(selectedOrganization, organizations){
        console.log(selectedOrganization)
        console.log(organizations)
        this.organizations = organizations;
        this.selectedOrganization = selectedOrganization;

        if(selectedOrganization == null){
            this._selectedOrganization = null;
        }else{
            for(let i in organizations ){
                if(organizations[i].tenantId == selectedOrganization){
                    this._selectedOrganization = organizations[i];
                    break;
                }
            }
        }
    }

    getOrganisationName(){
		let name = '--';

        if(this._selectedOrganization ){
            name = this._selectedOrganization.Name;
        }

		return name;
	}

	getUserEmail(){
		let name = '--';
        if(this._selectedUser ){
            name = this._selectedUser.EmailAddress;
        }
		return name;
	}

    getCountryCode(){
        let name = '--';

        if(this._selectedOrganization ){
            name = this._selectedOrganization.CountryCode;
        }

		return name;
    }

    getLastUpdateTime(){

    }

    getLoginUrl(portfolioId:any, type = 1){

        //let query = new URLSearchParams();
        let uniq = 'xero-' + (new Date()).getTime()+':portfolio_id-'+portfolioId+':type-'+type;
        console.log(this.redirect_uri)
        let query = [];
        query.push("scope="+encodeURI(this.scope));
        query.push("response_type=code");
        query.push("client_id="+this.clientId);
        query.push("redirect_uri="+this.redirect_uri);
        query.push("state="+uniq);

        let cookieJSON = {
            'key': uniq,
            'redirect_url': this.redirect_uri,
            'gateway': "xero",
            'portfolio_id': portfolioId
        }

        this.global._cookieService.set('oauth2Data', JSON.stringify(cookieJSON), null,'/')
        return  this.loginUrl +'?' +query.join('&');
    }

    setCode(code:string){
        this.code = code;
    }

    getCode():string{
        return this.code
    }

    getXeroOrganization(gatewayConnectionId, portfolioId){
        this.organizations = null;
        this.selectedOrganization = null;
        this.selectedUser = null;
        this.users = null;
        this.organizations = [];
        this.users = [];
        this._selectedUser = null;
        this._selectedOrganization = null;
        this._lastUpdateTime = null;

        return this.global.httpGET('get_xero_details',{
            gatewayConnectionId,
            portfolioId
        });
    }

    setXeroDetails(gatewayConnectionId,portfolioId, data){

        return this.global.httpPOST('set_xero_details',Object.assign({},{
            gatewayConnectionId,
            portfolioId
        },data))
    }

    getAccounts(gatewayConnectionId,portfolioId){
        return this.global.httpGET('get_xero_accounts',{
            gatewayConnectionId,
            portfolioId
        });
    }

    getTxAccountMap(portfolioId, txId):Observable<any>{
        return this.global.httpGET('get_tx_account_map',{
            gatewayeId:2,
            portfolioId,
            txId
        });
    }

    addTxAccountMap(portfolioId, txId, creditAccountId, debitAccountId, creditAmount, debitAmount){

        let body ={
            gatewayeId:2,
            portfolioId,
            txId
        };

        if(creditAccountId !== null){
            body['creditAccountId'] = creditAccountId;
            body['creditAmount'] = creditAmount;
        }

        if(debitAccountId !== null){
            body['debitAccountId'] = debitAccountId;
            body['debitAmount'] = debitAmount;
        }

        return this.global.httpPOST('add_tx_account_map',body);
    }

  public syncErpAccounts(){
    return this.global.httpPOST('xero_sync_erp_accounts', {
      portfolioId: this.global.currentPortfolioId,
      gatewayId: 2,
    });

  }

  public getAccountsSyncStatus(){
    return this.global.httpPOST('xero_get_accounts_sync_status',{
      'portfolio_id': this.global.currentPortfolioId
    })
  }

}
