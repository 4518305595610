<div>
	<h5 class="mb-3 mt-1">
		Layout  Settings
	</h5>
	<!-- <div class="alert alert-success" role="alert" *ngIf="showMessage == 1">
		Layout changed successfully.
	</div> -->
	
	<p>
		Choose a user interface layout preference.
	</p>
	<div class="row">

		<div class="col-md-4">
			<div class="card flex-md-row box-shadow h-md-250 pointer" [ngClass]="{'card-selected': global._userDetails['layout_mode']==0}" (click)="global._userDetails['layout_mode']=0">
				<div class="card-body pb-1 pt-1 d-flex flex-column align-items-start">
					<div class="form-check-inline text-center pointer">
						<input type="radio" name="exportType" [value]="0" [(ngModel)]="global._userDetails['layout_mode']" (click)="global._userDetails['layout_mode']=0"/>
						<label class="mt-2 pl-2" style="margin-right: 20px">Horizontal</label>
					</div>
					<img src="/assets/img/top-ui.png" class="mr-2 ml-2" height="100" alt=""> 
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card flex-md-row box-shadow h-md-250 pointer" [ngClass]="{'card-selected': global._userDetails['layout_mode']==1}" (click)="global._userDetails['layout_mode']=1">
				<div class="card-body pb-1 pt-1 d-flex flex-column align-items-start">
					<div class="form-check-inline text-center pointer">
						<input type="radio" name="exportType" [value]="1" [(ngModel)]="global._userDetails['layout_mode']" (click)="global._userDetails['layout_mode']=1"/>
						<label class="mt-2 pl-2" style="margin-right: 20px">Vertical</label>
					</div>
					<img src="/assets/img/left-ui.png" class="mr-2 ml-2" height="100" alt="">
				</div>
			</div>
		</div>

	</div>
	
	<p>
		<span class="text-bold">Important Notice</span>: Layout features are temporary and could be changed without notice.
	</p>
	<hr/>
	<input class="ml-1" type="checkbox" name="importFormat" [checked]="global._userDetails['show_qs_bottm_bar']" [(ngModel)]="global._userDetails['show_qs_bottm_bar']" /><label class="pl-2 mr-3 mb-0"> Show QuickStart bar </label>
	<br/>
	<br/>

	<button *ngIf="global.changingLayout == false" type="button" class="btn btn-primary" (click)="global.changeLayout()">Save</button>
	<button *ngIf="global.changingLayout == true" type="button" class="btn btn-primary" disabled>
		<span *ngIf="global.changingLayout == true"><img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Save</span>   
	</button>
	
</div>