import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';

@Component({
  selector: 'app-404-not-found',
  templateUrl: './404-not-found.component.html'
})
export class NotFoundComponent implements OnInit {

	
	constructor(public global: GlobalService, public coinService:CoinService) {
	}

	ngOnInit(): void {
		window.scroll(0, 0);
		this.global.titleService.setTitle(this.global.companyName +" - Not Found")
		
	}

	ngOnDestroy(){
		
	}
	

}
