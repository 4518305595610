
import { Component } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  moduleId: module.id,
  selector: 'app-header-guest',
  templateUrl: './header-guest.component.html',
  styleUrls: ['./header-guest.component.css']
})

export class HeaderGuestComponent { 

	public isNavbarCollapsed = true

	public resendStatus = 0
	public modalRef

	public currentHover = null
	public isMenuCollapsed = true


    constructor(public global: GlobalService, public coinService:CoinService,  public modalService: NgbModal) {
	}

	gotoSearch(text){
		this.global.router.navigate(['search', this.global.searchType, text]);
	}

	openModal(modal){

		this.modalRef = this.modalService.open(modal, {size:'lg'});
	 }

	socketTest(){
	}

	setCurrentHover(hover){
		this.currentHover = hover
	}

	resendActivationMail(){
		this.resendStatus = 1
		this.global.httpAPIGET(this.global.API_URL + 'resend_activation_mail?uId=' + this.global._userDetails['uId'])
		.subscribe(response => {
			console.log(response)
			if(response.resp_status==true){
				this.resendStatus = 2
			} else {
				this.resendStatus = 3
			}

		}, error => {
			console.log(error);// Error getting the data
			this.resendStatus = 3
		});

	
	}
}

