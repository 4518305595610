import { Injectable, Output, EventEmitter   } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable, of } from 'rxjs';


@Injectable()
export class NFTService {

	constructor(private global: GlobalService,
	) {

	}

	resolvingNFT = null
	
    resolveNFT(reviewId){

    }

}
